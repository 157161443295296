<script setup lang="ts">
const href = ref(location.href)
</script>

<template>
  <div class="info">
    <div class="info-container">
      <header>
        <h1>{{ $t('f0hB6QdTrDt13IrQmtPhv') }}</h1>
        <h2>{{ $t('hSm9ztHhlHn2CrL142Mmy') }}</h2>
      </header>
      <div class="introduce">
        <ul>
          <li>
            <h3 class="text-20px leading-1.5em">{{ $t('1ypcO0Ys3v9o2T76y4hi') }}</h3>
            <p>{{ $t('8dgVssFKlhvSWiEjWcWUc') }}</p>
          </li>
          <li>
            <h3 class="text-20px leading-1.5em">{{ $t('ew9Drq36POxPffVifeEcK') }}</h3>
            <I18nT keypath="lxjXr32t0wg1Id7qP_0A" tag="p">
              <template #link>
                <Copy class="text-#266ef1" :content="href" :iconShow="false">
                  <template #content>
                    <span> {{ href }} </span>
                  </template>
                </Copy>
              </template>
            </I18nT>
          </li>
          <li>
            <h3 class="text-20px leading-1.5em">{{ $t('y0RcWjVTmWkWgDmb00Am') }}</h3>
            <p>{{ $t('cxeo3y0bJPdolbbIxZg_0') }}</p>
          </li>
          <li>
            <h3 class="text-20px leading-1.5em">{{ $t('o2LAfsw8FbqgZxs4GjPm') }}</h3>
            <p>{{ $t('cUa7JdfOKdWcXQphIys6') }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.info {
  --uno: 'w-[630px] lt-desktop:w-full lt-desktop:h-full lt-desktop:mb-[30px]';

  .info-container {
    --uno: 'pl-[0] pr-[0] lt-mobilel:hidden';

    header {

      h1 {
        --uno: "mb-[22px] font-800 text-67px lh-7.3rem text-transparent";

        position: relative;
        background: linear-gradient(90deg, rgb(38 128 235 / 100%) 0%, rgb(0 193 165 / 100%) 30%);
        background-clip: text;
      }

      h2 {
        --uno: "text-[#e2e8f2] mt-10px text-26px font-600";
      }
    }

    .introduce {
      --uno: ' mx-[0] my-[23px]';
    }

    ul {
      --uno: 'list-initial inline list-none text-[#b1bdd1] leading-1.5em';

      display: grid;
      gap: 16px;
      list-style-type: none;

      li {
        padding-left: 10px;
        margin-left: 30px;
        font-size: 20px;
        list-style: url("@/assets/imgs/dot.svg") outside;

        p {
          --uno: 'text-[#8098bf] text-16px'
        }
      }
    }
  }
}
</style>
