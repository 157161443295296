import { createGlobalState } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
// import groupBy from 'lodash/groupBy'
// import find from 'lodash/find'
// import BigNumber from 'bignumber.js'
import axios from 'axios'
import { useGlobalState } from './useGlobalState'
import { Wallet } from '../wallet'
// import { tron } from '../wallet/tron'
// import { ethereum } from '../wallet/ethereum'
import { decimals, getStatus, catchMsg } from '../wallet/helpers'
import type * as payApi from '@/api/specs/pay.api'


const updateBalance = ref(1)
const state = useGlobalState()


/** 全局状态 */
export const useGlobalQuery = createGlobalState(() => {
  return {
    /** 币种列表 useQuery */
    chainQuery: chainQuery(),
    /** 询价 */
    fromQuteQuery: fromQuteQuery(),
    /** 获取余额 */
    balanceQuery: balanceQuery(),
    /** 订单列表 */
    orderQuery: orderQuery(),
    /** 转账地址 */
    swapTransferAddress: swapTransferAddress()
  }
})

/**
 * 币种数据
 * @returns {Array} chainQuery
 */
export function chainQuery() {
  return useQuery({
    queryKey: [apisPay.swapTokenList.id],
    staleTime: 5 * 1000 * 60,
    refetchOnWindowFocus: false,
    queryFn: () => apisPay.swapTokenList(undefined),
    select: async (data: any) => {
      state.chainList = data ?? []
      // 1.=======
      {
        let from_symbol = localStorage.getItem('fromCurrency.symbol')
        const fromCurrency = data.find((item: any) => { return item.symbol == from_symbol })
        if (!from_symbol || !fromCurrency) {
          state.fromCurrency = JSON.parse(JSON.stringify(data[0]))
        } else {
          state.fromCurrency = JSON.parse(JSON.stringify(fromCurrency))
        }
        state.state.fromChain = state.fromCurrency.chain
      }

      // 2.=======
      {
        let to_symbol = localStorage.getItem('toCurrency.symbol')
        const toCurrency = data.find((item: any) => { return item.symbol == to_symbol })
        if (!to_symbol || !toCurrency) {
          state.toCurrency = JSON.parse(JSON.stringify(data[1]))
        } else {
          state.toCurrency = JSON.parse(JSON.stringify(toCurrency))
        }
        state.state.toChain = state.toCurrency.chain
      }

      // 设置钱包类标识
      {
        try {
          (Wallet.plugin(state.currentWallet.pluginName) as any).pluginName = await Wallet.pluginName(state.fromCurrency.chain)
          state.currentWallet.address && await Wallet.plugin(state.currentWallet.pluginName).switchNetwork(Number(state.fromCurrency.chainId))
          state.state.isBalanceQuery = true;
          updateBalance.value = Date.now();
        } catch (error) {
        }
      }
      state.state.isBalanceQuery = true

      return data ?? [];
    },
  })
}
/** 转账地址 */
export function swapTransferAddress() {
  return useQuery({
    queryKey: [apisPay.swapTransferAddress.id],
    staleTime: 5 * 1000 * 60,
    refetchOnWindowFocus: false,
    queryFn: () => apisPay.swapTransferAddress(undefined),
  })
}

/** 订单列表 */
export function orderQuery() {
  return useQuery({
    queryKey: [apisPay.swapTransDataList.id, state.currentWallet.address],
    // 首次请求失败后 +{retry}重复次数  =false将禁用重试, =true将无限重试失败的请求。
    retry: true,
    retryDelay: 5000,
    refetchOnWindowFocus: true,
    refetchInterval: 10000,
    queryFn: async () => {
      console.log('useGlobalQuery.ts orderQuery')
      if (!state.currentWallet?.address) {
        return Promise.resolve({ list: [], pagination: {} })
      }
      return await apisPay.swapTransDataList({ page: 1, page_size: 10, from_address: state.currentWallet.address })
    },
    select(data: { list: any[], pagination: Object }) {
      data?.list.forEach((item: payApi.ISwapDetail) => {
        item.handle_status = getStatus(item.status)
      })
      state.orderData = data as any
      return data
    },
  });
}

// 创建取消令牌
let controller: any = null
/** 询价 */
export function fromQuteQuery() {
  return useQuery({
    enabled: false,
    queryKey: [apisPay.swapQute.id, state.is_platform_approve],
    staleTime: 200,
    refetchOnWindowFocus: true,
    queryFn: async () => {
      console.log('useGlobalQuery.ts useQuery')
      controller && controller.cancel('Operation canceled by the user.')
      if (Number(state.fromMoney.amount) === 0) {
        return Promise.resolve(0)
      }
      controller = axios.CancelToken.source()
      const data = {
        fromTokenAddress: state.fromCurrency.address,
        fromTokenChain: state.fromCurrency.chain,
        fromTokenAmount: decimals(state.fromMoney.amount, state.fromCurrency.decimals),
        toTokenAddress: state.toCurrency.address,
        toTokenChain: state.toCurrency.chain,
        // userAddr?: "",
        //转账 = 1   DAPP=0
        is_platform_approve: state.is_platform_approve,
      }
      const res: any = await apisPay.swapQute(
        data,
        {
          // errorMessageMode: 'none',
          cancelToken: controller.token,
        },
      )
      state.fromQuteData = res;
      state.toMoney.amount = res.toTokenAmount
      state.toMoney.precision = res.toTokenDecimal
      return res
    },
  })
}

// 余额
export function balanceQuery() {
  return useQuery({
    // 禁止查询
    enabled: true,
    queryKey: ['getBalance', updateBalance],
    //延迟请求时间默认1000ms
    retryDelay: 3500,
    staleTime: 5 * 1000,
    // 首次请求失败后 +{retry}重复次数  =false将禁用重试, =true将无限重试失败的请求。
    retry: 1,
    refetchOnWindowFocus: true,
    queryFn: async () => {
      console.log(`useGlobalQuery.ts balanceQuery >>`)
      if (state.state.isBalanceQuery == false) {
        return Promise.resolve(0)
      }
      // Bitkeep不支持 Tron 测试网节点
      if (state.currentWallet.name === 'Bitget Wallet' && state.fromCurrency.chain === 'TRON' && Number(state.fromCurrency.chainId) != 728126428) {
        aMessage.warning($t('w5tyxyiQfRvxqzUGkQDvu'))
        return Promise.resolve(0)
      }
      if (!state.currentWallet.address || !state.currentWallet.sign.includes(state.fromCurrency.chain)) {
        return Promise.resolve(0)
      }
      
      try {
        const res = await Wallet.plugin(state.currentWallet.pluginName).getBalance(state.fromCurrency.address)
        state.fromMoney.balance = res
        return res
      } catch (error: any) {
        console.error('useGlobalQuery.ts balanceQuery >> ！', error)
        // catchMsg(error)
        return Promise.resolve(0)
      }
    },
  })
}
