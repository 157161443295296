import { Ethereum } from './dapp/Ethereum'
import { Tron } from './dapp/Tron'
import { Bitkeep } from './dapp/Bitkeep'
import { Walletconnect } from './dapp/Walletconnect'
import { ImToken } from './dapp/ImToken'
import * as config from "./config"
import type * as types from './types'

/** 钱包插件对象 */
export const wallet = {
  Ethereum,
  Tron,
  Bitkeep,
  Walletconnect,
  ImToken
}

export class Wallet {
  /** 返回钱包静态类 */
  static plugin(pluginName: types.type_pluginName | types.type_wallet) {
    if (pluginName.toLocaleLowerCase() === "ethereum".toLocaleLowerCase()) { return Ethereum }
    if (pluginName.toLocaleLowerCase() === "tron".toLocaleLowerCase()) { return Tron }
    if (pluginName.toLocaleLowerCase() === "bitkeep".toLocaleLowerCase()) { return Bitkeep }
    if (pluginName.toLocaleLowerCase() === "walletconnect".toLocaleLowerCase()) { return Walletconnect }
    if (pluginName.toLocaleLowerCase() === "ImToken".toLocaleLowerCase()) { return ImToken }
    throw new Error('[钱包未配置] Wallet not configured')
  }

  /** 获取插件对象 */
  static pluginName(chain: string): Promise<string | types.type_pluginName> {
    let name = "";
    if (config.chain_ethereum.includes(chain)) { name = 'Ethereum' }
    if (config.chain_tron.includes(chain)) { name = 'Tron' }
    if (name == "") { return Promise.reject(`${chain} Error`) }
    return Promise.resolve(name)
  }
  
  /** 返回获取到地址的钱包 */
  static findWallet(pluginName: string, address: string) {
    for (const obj of config.walletList) {
      const pp = obj.children.find((el) => el.pluginName.toLocaleLowerCase() === pluginName.toLocaleLowerCase())
      if (pp) {
        pp.address = address
        return pp as types.type_currentWallet
      }
    }
    const wallet = JSON.parse(JSON.stringify(config.walletList[0].children[0]))
    wallet.address = ""
    return wallet as types.type_currentWallet
  }
}