<script lang="ts">
import CurrencyInput from './DappPay/CurrencyInput.vue'
import ShowAddress from './DappPay/ShowAddress.vue'
import ButtonList from './DappPay/ButtonList.vue'
import WalletAddress from './DappPay/WalletAddress.vue'
import ExchangeBtn from './DappPay/ExchangeBtn.vue'
import TtxsModal2 from './DappPay/ttxsModal/src/TtxsModal2.vue'
import TtxsModal3 from './DappPay/ttxsModal/src/TtxsModal3.vue'
import TtxsModal4 from './DappPay/ttxsModal/src/TtxsModal4.vue'
import TtxsModal6 from './DappPay/ttxsModal/src/TtxsModal6.vue'
import * as helpers from './DappPay/wallet/helpers'
import { useGlobalState } from './DappPay/hooks/useGlobalState'
import { useGlobalQuery } from './DappPay/hooks/useGlobalQuery'
import { Wallet, wallet } from './DappPay/wallet'
</script>

<script setup lang="ts">
const query = useGlobalQuery()
const state = useGlobalState()

/**  兑换弹窗 */
const payModal = reactive({
  /** from选择币种合约地址弹窗 */
  modal2FromShow: false,
  /** to选择币种合约地址弹窗 */
  modal2ToShow: false,
  /** 确认兑换前弹窗 */
  show3: false,
  /** 进度的弹窗 */
  show4: false,
  /** 显示approve 授权 */
  show6: false,
  // /** 提示安装插件弹窗 */
  // show7:false,
  /** 订单id */
  order_id: '',
})

/** 兑换弹窗确确认按钮-提交事件 */
async function payModal3_onClick(item: { key: string }) {
  if (item.key === 'onSubmit')
    onSubmit()
}

/** 4个状态带进度的弹窗-提交事件 */
async function payModl4_onClick(item: { key: string }) {
  payModal.show3 = false
  payModal.show4 = false
}

/** 授权弹窗提交-点击按钮 */
async function payModl6_onClick() {
  console.log('DappPay.vue payModl6_onClick approve', payModal.order_id)
  state.loading.authorization = true
  try {
    await Wallet.plugin(state.currentWallet.pluginName).approve(toRaw(state.currencyOrderData))
    payModal.show6 = false
    await onSubmit()
  }
  catch (error: Error | any) {
    console.error('DappPay.vue payModl6_onClick approve', error)
    helpers.catchMsg(error)
  }
  finally {
    state.loading.authorization = false
  }
}

/** 弹出币种列表弹窗 from to点击组件里面的 data = { key: string , $event: any, $prors: prors} */
function onClickCurrency(data: any) {
  console.log('DappPay.vue onClickCurrency', data)
  state.state.who = data.$prors.who
  if (data.$prors.who === 'from')
    payModal.modal2FromShow = true

  if (data.$prors.who === 'to')
    payModal.modal2ToShow = true
}

/** 付之前确认弹窗 return true 验证不通过  false=通过 */
async function onPayModalShow() {
  console.log('DappPay.vue onPayModalShow')
  if (!state.currentWallet?.address) {
    state.state.openWalletConnect = true
    return true
  }
  if (!state.currentWallet.address) {
    aMessage.error($t('EDGJV8PF5y7GdfNjv4ZTG'))
    return true
  }
  if (!state.currentWallet.sign.includes(state.fromCurrency.chain)) {
    // aMessage.error($t('miv9MlK5jAJLd9ItXvfSk'))
    aMessage.error($t('YrMTkTfJ1fZy391PLmcWd'))
    return true
  }
  if (state.receive.address == '') {
    aMessage.error($t('EIQxvF2aRHrkm79Vmge1h'))
    return true
  }
  state.loading.submit = true
  try {
    await Wallet.plugin(state.currentWallet.pluginName).connect()
    if (state.currentWallet.name === 'ImToken' && !!(window as any).tronWeb === false && (await Wallet.pluginName(state.fromCurrency.chain)) === 'Tron')
      await wallet.ImToken.showAccountSwitch('TRON')

    await Wallet.plugin(state.currentWallet.pluginName).switchNetwork(Number(state.fromCurrency.chainId))
    state.state.isBalanceQuery = true
  }
  catch (error: Error | any) {
    helpers.catchMsg(error)
    return true
  }
  state.loading.submit = false

  await query.balanceQuery.refetch()
  await sleep(200)
  if (state.fromMoney.balance < state.fromMoney.amount) {
    aMessage.error($t('Lv8uWdyHRwC7PqOHJ3ZYW'))
    return true
  }
  payModal.show3 = true
  return false
}

/** 兑换提交  */
async function onSubmit() {
  if (!state.currentWallet.sign.includes(state.fromCurrency.chain)) {
    state.state.connectCurrentWallet = JSON.parse(JSON.stringify(state.currentWallet))
    state.state.openWalletConnect = true
    return false
  }
  state.loading.submit = true
  try {
    // 1.创建兑换订单
    const requestBody = {
      /** 是否平台授权 Dapp 传 0 */
      is_platform_approve: 0,
      /** 支付链 */
      from_token_chain: state.fromCurrency.chain,
      /** 支付币种合约地址 */
      from_token_address: state.fromCurrency.address,
      /** 支付金额 */
      from_token_amount: helpers.decimals(state.fromMoney.amount, state.fromCurrency.decimals),
      /** 兑换链 */
      to_token_chain: state.toCurrency.chain,
      /** 兑换币种合约地址 */
      to_token_address: state.toCurrency.address,
      /** 接收地址 */
      to_address: state.receive.address || state.currentWallet.address,
      /** 支付地址 */
      from_address: state.currentWallet.address,
    } as any
    const res = (await apisPay.swapCreate({ requestBody }, { errorMessageMode: 'none', resultType: 'api' })) as any
    if (res.code != 0)
      throw new Error(res.msg)

    const orderInfo = res.data
    payModal.order_id = orderInfo.order_id
    orderInfo.handle_status = helpers.getStatus(orderInfo.status)
    state.currencyOrderData = orderInfo
    console.log('DappPay.vue swapCreate >> ')
    console.log(orderInfo)

    // 2.是否授权足够token  后端 接口验证 token 【不是原生币才执行】
    if (!(/^0xe+$/.test(state.fromCurrency.address))) {
      await apisPay.swapAllownced({ order_id: payModal.order_id }, { errorMessageMode: 'none' }).catch((res) => {
        // 显示approve
        payModal.show6 = true
        return Promise.reject(undefined) // 必须返回 undefined，  给后面 catch 返回值判断 不让它触发弹窗
      })
    }

    // 3.兑换合约交易数据
    const signData = await apisPay.swapGetSignTx({ order_id: payModal.order_id }, { errorMessageMode: 'none' })
    console.log('3.兑换合约交易数据 :>> ', signData)

    // 4.发起合约转账
    await Wallet.plugin(state.currentWallet.pluginName).swapTokens(signData, orderInfo)
    payModal.show3 = false
    payModal.show4 = true
  }
  catch (error: Error | any) {
    console.log('DappPay.vue onSubmit error >>', error)
    helpers.catchMsg(error)
  }
  finally {
    state.loading.submit = false
  }
}
// 组件销毁时触发
onUnmounted(() => {
  console.log('DappPay.vue onUnmounted :>> ')
  state.state.openModalWalletList = false
  state.loading.submit = false
  state.loading.authorization = false
  state.loading.quote = false
})
</script>

<template>
  <div>
    <AForm class="antd-cover__bubble-help-form text-[14px] mt-[20px]">
      <div v-if="state.currentWallet?.address" class="mb-[20px]">
        <div class="flex flex-between mb-10px">
          <div class="text-white">{{ $t('E1bQiLehwqmyaqSEEEMGC') }}</div>
          <AButton type="link" style="padding-right: 0px;" data-test-id="yojf" @click="state.state.openModalWalletList = true;">
            {{ $t('cTooc1srvaKezHBm54biN') }}
          </AButton>
        </div>
        <WalletAddress :img="state.currentWallet.img" :icon="state.currentWallet.icon">
          {{ state.currentWallet.address }}
        </WalletAddress>
      </div>

      <CurrencyInput
        v-model="state.fromMoney.amount" who="from" :title="$t('_X-tn_iR5wA0xAcbpbKCx')" :showBalance="true"
        :loading="query.balanceQuery.isFetching.value ?? false" :balance="query.balanceQuery.data?.value ?? 0"
        :precision="state.fromMoney.precision" :disabled="state.fromMoney.disabled" :icon="state.fromCurrency.logoURI"
        :symbol="state.fromCurrency.symbol_show" @onClick="onClickCurrency"
      />
      <ButtonList v-if="false" class="py-5" :data="[0.07, 0.1, 10, 20, 50, 200, 500, 5000, 50000]" />

      <ExchangeBtn who="dapp" />

      <CurrencyInput
        v-model="state.toMoney.amount" who="to" :title="$t('wZutznikQLic6i7sOz0J5')" :showBalance="false"
        :min="state.toMoney.min" :max="state.toMoney.max" :precision="state.toMoney.precision"
        :disabled="state.toMoney.disabled" :icon="state.toCurrency.logoURI" :symbol="state.toCurrency.symbol_show"
        @onClick="onClickCurrency"
      />

      <ShowAddress />

      <AButton
        v-if="state.currentWallet.address" class="w-100% comfirm-btn" :loading="state.loading.quote || state.loading.submit"
        :disabled="state.fromMoney.amount <= 0 || state.toMoney.amount <= 0 || state.fromCurrency.chain + state.fromCurrency.address === state.toCurrency.chain + state.toCurrency.address"
        data-test-id="p3zz" @click="onPayModalShow"
      >
        {{ $t("b4Yo6M5iiJxWzJTahYxm") }}
      </AButton>
      <AButton v-else class="w-100% comfirm-btn" data-test-id="jege" @click="state.state.openModalWalletList = true">
        {{
          $t("2QEpLv0zhWEMcJ7HK3XD4") }}
      </AButton>
    </AForm>

    <TtxsModal2
      v-model="payModal.modal2FromShow" who="from" :chain="state.state.fromChain"
      :symbol="state.fromCurrency.symbol"
    />
    <TtxsModal2 v-model="payModal.modal2ToShow" who="to" :chain="state.state.toChain" :symbol="state.toCurrency.symbol" />

    <TtxsModal3 v-model="payModal.show3" who="提交确认框" :loading="state.loading.submit" @onClick="payModal3_onClick" />
    <TtxsModal4 v-model="payModal.show4" who="带进度的弹窗" @onClick="payModl4_onClick" />
    <TtxsModal6
      v-model="payModal.show6" who="授权弹窗" :loading="state.loading.authorization"
      :src="state.fromCurrency.logoURI" @onSubmit="payModl6_onClick"
    >
      {{ $t('kqs8Yqqpzn8DFM3tMaicr') }} {{ state.fromMoney.amount }} {{ state.fromCurrency.symbol }}
    </TtxsModal6>
  </div>
</template>
