// import { $t } from '~/components/app-provider/src/AppProvider.vue';

// 引入 bs58check 库
import bs58check from 'bs58check'
// import cloneDeep from 'lodash/cloneDeep'
import { ethers } from 'ethers'
import chains from './chains.json'
/**
 * 等待时间
 * @param {Number} msec 毫秒 1000 
 * @returns {Promise<void>}
 */
export function sleep(msec = 1000): Promise<void> {
  return new Promise(r => setTimeout(r, msec))
}
/**
 * 清除括号以及内容
 * @param {String} symbol USDT(TRC20)
 * @returns {String} USDT
 */
export function reSymbol(symbol: string) {
  return symbol.replace(/\(.*?\)/g, '').trim()
}
/** 数字转精度 2 转成 2000 */
export function decimals(balance: any, precision = 0) {
  return ethers.parseUnits(String(balance ?? 0), precision ?? 0).toString()
}
/** 精度转数字 2000 转成 2 */
export function formatUnits(balance: any, precision = 0) {
  return ethers.formatUnits(String(balance ?? 0), precision ?? 0).toString()
}
/**
 * 响应式对象数据互换
 * @param {Object} a 数据1 
 * @param {Object} b 数据2
 */
export function exchange(a: any, b: any) {
  let from = JSON.parse(JSON.stringify(a))
  let to = JSON.parse(JSON.stringify(b))
  // 交换两个对象的属性
  const propertiesToSwap = Object.keys(a)
  // 交换属性
  propertiesToSwap.forEach((property: string) => {
    [a[property], b[property]] = [to[property], from[property]]
  })
  // // 打印更新后的对象
  console.log('from:>>', JSON.stringify(toRaw(a), null, 2))
  console.log('to:>>', JSON.stringify(toRaw(b), null, 2))
}

/** 错误消息提示 */
export function catchMsg(error: any) {
  if (typeof error === 'string') {
    error = new Error(error)
  }
  if (error?.message) {
    setMsg(error, 'Another authorization requests are being processed, please do not resubmit', $t('DOSRrmFGpCC4EUK5EunS1'))
    setMsg(error, 'already pending for origin', $t('txjHNF2in48J5CIdxwcfm', { message: error?.message }))
    setMsg(error, 'Connection request reset', $t('yjD2wEJl7hv32WVHHqfFv'))
    setMsg(error, 'cancel', $t('7Coiim2ouDVczYiHnZ54M'))
    setMsg(error, 'user reject', $t('7Coiim2ouDVczYiHnZ54M'))
    setMsg(error, 'declined by user', $t('7Coiim2ouDVczYiHnZ54M'))
    setMsg(error, 'user rejected', $t('7Coiim2ouDVczYiHnZ54M'))
    setMsg(error, 'User denied transaction signature', $t('tSIIyLVbPGmF1k93GuaAC'))
    setMsg(error, 'invalid adress', $t('Ye5XYTTTvCe8LunzektQc'))
    setMsg(error, 'Already processing eth_requestAccounts. Please wait', $t('AHLP4QNPEJKZS4UJyLW8X'))
    setMsg(error, '请安装插件', (client.isAndroid || client.isIos) ? $t('EDGJV8PF5y7GdfNjv4ZTG') : $t('XHlrRqsC6TC4ETegNW5d4'))
    aMessage.error(error.message)
  }

  function setMsg(error: Error, atMsg: string, reStr: string) {
    if (error?.message.toLowerCase().includes(atMsg.toLowerCase())) { error.message = reStr }
  }
}
/** base58 转 hex 编码 */
export function base58checkToHex(base58Str = "TAgv2M2Yirj9WDaYUdXWGJkxZqoLvvPRsq") {
  const decoded = bs58check.decode(base58Str)
  const hexStr = Array.from(decoded).map(byte => byte.toString(16).padStart(2, '0')).join('')
  return hexStr
}

/** 订单状态几个状态 文档 https://docs-bridgers.swft.pro/bridger-api/query-transaction-records*/
export function getStatus(status: string, oldStatus = 0) {
  let num = oldStatus
  switch (status) {
    // Awaiting user deposit
    case "wait_deposit_send": // '等待存币' 
    // Exchange
    case "wait_exchange_push": // '正在兑换中...' 
    case "wait_exchange_return": // '正在兑换中...' 
    case "wait_exchange_return_success": // '正在兑换中...' 
      num = 1
      break;
    // send
    case "wait_receive_send": // '兑换完成，等待发币' 
    case "wait_enough_send": // '正在发币中... 
    case "wait_receive_confirm": // '发币确认中...' 
      num = 2
      break;
    // Success
    case "receive_complete": // '发币完成' 
      num = 3
      break;
    case "timeout":
      num = 4;
      break;
    case "ERROR":
    case "wait_deposit_send_fail":
    case "wait_for_information":
      num = 5;
      break;
  }
  return num
}

/**
 * 格式化时间戳
 * parseTime(1593059173000)              //"2020-06-25 12:26:13"
 * parseTime(1593059173000, "{i}:{s}")   //"26:13"
 * @param time Date.now()
 * @param cFormat '{y}-{m}-{d} {h}:{i}:{s}'
 */
export function format_parseTime(time = 0, cFormat?: String) {
  if (arguments.length === 0) {
    return 0
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  let formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  } as any
  let time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

/** 循环5次 只要成功就退出 继续执行，调用时 await 和不写 await 是有区别的请注意 */
export async function loopUpdateHash(txID: string, order_id: string, num = 5) {
  console.log('helpers.ts loopUpdateHash :>>', num, order_id, txID)
  try {
    let res = await apisPay.updateHash({
      requestBody: {
        hash: txID,
        order_id: order_id
      }
    }, { errorMessageMode: 'none' })
    return Promise.resolve(res)
  } catch (error) {
    if (num > 0) {
      num--;
      await sleep(2000)
      return loopUpdateHash(txID, order_id, num)
    } else {
      throw new Error('更新Hash失败')
    }
  }
}



export const chainObj = {} as any
export const chainList = Object.freeze(Object.values(chains)).map((el) => {
  chainObj[el.id] = el
  return el
})
export function getChain(chainId: number) {
  const obj = chainObj[chainId]
  const hex_chainId = `0x${Number(chainId).toString(16)}`
  return {
    chainId: hex_chainId, // 要添加的网络的链ID
    chainName: obj.name, // 要添加的网络的名称
    nativeCurrency: {
      name: obj.nativeCurrency.name,  // 主代币的名称
      symbol: obj.nativeCurrency.symbol, // 主代币的代号
      decimals: obj.nativeCurrency.decimals // 主代币的小数位数
    },
    rpcUrls: obj.rpcUrls.public.http, // 可选：要添加的网络的图标URL
    blockExplorerUrls: [obj.blockExplorers.default.url], // 可选：要添加的网络的区块浏览器URL
    iconUrls: ['https://transfer.swft.pro/swft-v3/images/coins/USDC(BASE).png'] // 可选：要添加的网络的图标URL
  }
}

/**
 * 金币格式化
 * @example
 * console.log(formatAmount(1000000.0000)); // 输出: "1,000,000"
 * console.log(formatAmount("1000000.00006")); // 输出: "1,000,000.00006"
 * console.log(formatAmount(1000000.26)); // 输出: "1,000,000.26"
 */
export function formatAmount(amount: number | string) {
  // 将输入的金额转换为字符串
  const amountString = String(amount);

  // 使用正则表达式匹配整数部分和小数部分（可选）
  const matches = amountString.match(/^(\d+)(\.\d+)?$/);

  // 如果没有匹配到整数部分，则返回空字符串
  if (!matches) {
    return '';
  }

  // 获取整数部分和小数部分（如果有）
  const integerPart = matches[1];
  const decimalPart = matches[2] || '';

  // 将整数部分添加千位分隔符
  const formattedAmount = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // 拼接整数部分和小数部分并返回结果
  return formattedAmount + decimalPart;
}

