/**
 * 小狐狸APP 扫码连接就能获取余额并且支付 
 * ImToken 不得 不懂是不是没有测试网的原因
 */
import { nextTick } from 'vue';
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { Web3 } from 'web3'
import { ethers } from 'ethers'
// import { message, notification } from 'ant-design-vue';
import * as helpers from '../helpers'
import * as types from '../types'
// import ERC20 from "./ERC20.json"
import { Ethereum as ImportedEthereum } from "../dapp/Ethereum"
export class Walletconnect extends ImportedEthereum {
	static pluginName: types.type_wallet = 'Ethereum'
	static ethereum: any;

	static get address() {
		return this.ethereum?.accounts[0] ?? '';
	}
	static get isPlugin() {
		return !!this.ethereum
	}
	static isProvider() {
		return this.ethereum
	}
	static async connect() {
		await this.disconnect()
		if (this.ethereum.isWalletConnect && this.ethereum.accounts.length > 0) {
			return this.ethereum.accounts as string[]
		}
		await this.ethereum.connect()
		return this.ethereum.accounts as string[]
		// const addressList = await this.ethereum.request({ method: 'eth_requestAccounts' })
		// return addressList
	}
	static async disconnect() {
		await this.ethereum.disconnect()
	}
	/** 切换钱包账户 */
	static async switchConnect() {
		await this.ethereum.disconnect()
		await this.ethereum.connect()
		return this.ethereum.accounts as string[]
	}
	/** 切换网络 */
	static async switchNetwork(chainId: number) {
		const hex_chainId = `0x${Number(chainId).toString(16)}`
		try {
			await this.ethereum.request({ "method": "wallet_switchEthereumChain", "params": [{ "chainId": hex_chainId }] });
			await this.getBalance(undefined)	// 要加这个代码触发 兼容
			return this.getProvider()
		} catch (error: any) {
			if (error.code === 4902) {
				try {
					const obj = helpers.getChain(chainId)
					await this.ethereum.request({ "method": "wallet_addEthereumChain", "params": [obj] }).catch((error: any) => {
						return Promise.reject(error)
					})
					await this.getBalance(undefined)	// 要加这个代码触发 兼容
					return this.getProvider()
				} catch (error: any) {
					return Promise.reject(error)
				}
			}
			return Promise.reject(error)
		}
	}
	/** 转账 */
	static async transfer(toAddress: string, amount: string, tokenAddress?: string) {
		const abi = [
			"function decimals() view returns (uint8)",
			"function transfer(address to, uint amount)"
		]
		const signer = await this.provider.getSigner()
		if (tokenAddress === undefined || /^0xe+$/.test(tokenAddress)) {
			const transferAmount = ethers.parseUnits(amount, 18)
			// 1. 构建交易对象
			const transactionObject = {
				to: toAddress,
				value: ethers.toBeHex(transferAmount),
			};
			// 2.发送交易到以太坊网络 （签名 与广播 是合并的）
			const tx = await signer.sendTransaction(transactionObject);
			// 等待交易确认
			//  const res = await tx.wait()
			return tx
		} else {
			// 代币
			const contract = new ethers.Contract(tokenAddress, abi, signer);
			const decimals = await contract.decimals()  // 18n
			// 转账金额
			const transferAmount = ethers.parseUnits(amount, decimals)
			const tx = await contract.transfer(toAddress, transferAmount) as ethers.TransactionResponse

			// // 构造代币转账交易数据
			// const data = contract.interface.encodeFunctionData('transfer', [toAddress, transferAmount]);

			// 等待交易确认
			// await tx.wait()
			return tx;
		}
	}
}



nextTick(async () => {
	Walletconnect.ethereum = await EthereumProvider.init({
		projectId: "a5d19cad465451fb165833a07e1c0162",
		showQrModal: true, // REQUIRED 设置为“true”才能使用 @walletconnect/modal
		// optionalChains: [97, 11155111, 80001,], // chains - 可选名称空间所必需的
		chains: [1], //  chain ids
		methods: ["personal_sign", "eth_sendTransaction", "eth_accounts", "eth_requestAccounts", "eth_call", "eth_getBalance", "eth_sendRawTransaction", "eth_sign", "eth_signTransaction", "eth_signTypedData", "eth_signTypedData_v3", "eth_signTypedData_v4", "wallet_switchEthereumChain", "wallet_addEthereumChain", "wallet_getPermissions", "wallet_requestPermissions", "wallet_registerOnboarding", "wallet_watchAsset", "wallet_scanQRCode"],
		events: ["accountsChanged", "chainChanged", "message", "disconnect", "connect"],
		qrModalOptions: {
			themeMode: 'light',//light dark
			themeVariables: {
				"--w3m-z-index": "60000",
				// 下面这个才是正确的 真坑
				"--wcm-z-index": "60000",
			}
		}
	})

	Walletconnect.ethereum.on('chainChanged', (chainHex: string) => {
		// 这里会触发三次 可使用代码优化 
		console.log('chainChanged', Number(chainHex));
		// notification.info({ message: `chainChanged：${Number(chainHex)}` })
		Walletconnect.getProvider()
	})
	// accounts changed
	Walletconnect.ethereum.on('accountsChanged', (accounts: string[]) => {
		console.log('accountsChanged', accounts);
		// notification.info({ message: `accountsChanged：${accounts[0]}` })
	})
	// // session established
	Walletconnect.ethereum.on('connect', ({ chainId }: { chainId: string }) => {
		console.log('connect', chainId);
		// notification.info({ message: `connect：${Number(chainId)}` })
	})
	// session event - chainChanged/accountsChanged/custom events
	Walletconnect.ethereum.on('session_event', (e: any) => {
		console.log('session_event', e);
	})
	// // connection uri
	Walletconnect.ethereum.on('display_uri', (uri: string) => {
		// ... 自定义逻辑
		console.log("WalletConnectEthers.vue display_uri", uri)
		// notification.info({ message: `display_uri：${uri}` })
	})
	// // session disconnect
	Walletconnect.ethereum.on('disconnect', (e: any) => {
		console.log('disconnect', e);
	})
	Walletconnect.web3 = new Web3(Walletconnect.ethereum)
	Walletconnect.provider = new ethers.BrowserProvider(Walletconnect.ethereum)
})





