<script setup lang="ts">
import { useGlobalState } from './hooks/useGlobalState'
const state = useGlobalState()

import { useGlobalQuery } from './hooks/useGlobalQuery'
const query = useGlobalQuery()
import { exchange } from './wallet/helpers'


/** 点击币种交换 */
async function onExchangeCurrency() {
    exchange(state.fromCurrency, state.toCurrency)
    //更新金额
    query.balanceQuery.refetch()
    //更新询价
    query.fromQuteQuery.refetch()
}
</script>

<template>
    <div class="w-30px h-30px flex items-center justify-center my-4">
        <AButton v-if="query.fromQuteQuery.fetchStatus.value === 'idle'" type="link" @click="onExchangeCurrency" data-test-id="ekuk">
            <i class="i-local:reverse-currency?mask w-[30px] h-[30px] text-[#266ef1]"></i>
        </AButton>
        <AButton v-else type="link">
            <ASpin class="scale-140" :spinning="query.fromQuteQuery.isFetching.value ?? false"></ASpin>
        </AButton>
    </div>
</template>
