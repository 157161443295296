<script setup lang="ts">
// import axios from 'axios'
// import debounce from 'lodash-es/debounce'
// import * as helpers from './wallet/helpers'
import TtxsSelect from './ttxsModal/src/components/TtxsSelect.vue'
import { useGlobalState } from './hooks/useGlobalState'
import { useGlobalQuery } from './hooks/useGlobalQuery'
const state = useGlobalState()
const query = useGlobalQuery()
const $prors = withDefaults(defineProps<Prors>(), {
  who: '',
  title: '',
  min: null,
  max: Infinity,
  loading: false,
  balance: 0,
  precision: 6,
  disabled: false,
  icon: 'i-local:trx',
  symbol: 'TRX',
  modelValue: null,
  showBalance: true,
})
/** 向父组件触发方法 */
const emit = defineEmits(['onClick', 'onInput', 'update:modelValue'])
interface Prors {
  who?: string | number
  title?: string
  min?: any
  max?: any
  /** 余额加载 */
  loading?: any
  /** 余额 */
  balance?: any
  showBalance?: boolean
  /** 几位小数 */
  precision?: number
  /** 禁止输入 */
  disabled?: boolean
  icon: string
  symbol: string
  /** 兑换金额 */
  modelValue?: any
}

const value = ref($prors.modelValue)
watch(() => $prors.modelValue, (e) => {
  console.log($prors.who, "$prors.modelValue", e)
  value.value = Number(e) === 0 ? null : e
}, { immediate: true })

/**
 * 掉小数部分末尾无意义的零
 * @param val 一个数字
 */function formatNumber(val: any) {
  const p = $prors.precision < 2 ? 2 : $prors.precision
  const x = Number(Number(val).toFixed(p));
  if (Number(x) === 0) { return null }
  return x
}
function onBlur(e?: any) {
  // e.target.value
  console.log($prors.who, "失去焦点", value.value)
  if (value.value === null) {
    return null
  }
  value.value = formatNumber(value.value)
  if (['from'].includes(String($prors.who))) {
    state.fromMoney.amount = value.value
    //询价
    query.fromQuteQuery.refetch()
    // // 查询余额
    query.balanceQuery.refetch()
  }
}
function onEnter() {
  onBlur()
}
function onSelect($event: any) {
  state.state.who = $prors.who as string;
  emit('onClick', { key: 'onClick', $event, $prors })
}
</script>

<template>
  <div class="currency text-[#8098bf] bg-[--bg-one] rounded-[10px] p-4">
    <div class="flex items-center justify-between">
      <p class="p-r-3">
        <span>{{ title }}</span>
      </p>
      <template v-if="showBalance">
        <div class="flex">
          <span>{{ $t("ggM-EPn4zAWeodigP9_8M") }} </span>
          <ASpin :spinning="loading"><span class="px-2 font-bold">{{ Number(balance).toLocaleString() }}</span></ASpin>
        </div>
      </template>
    </div>
    <div class="input flex items-center mt-3">
      <div class="flex-1 h-40px lt-tablet:h-34px relative overflow-hidden">
        <input class="no-spinners text-20px text-white inputBox" type="text" v-model="value" placeholder="0"
          :disabled="disabled" @keyup.enter="onEnter" @blur="onBlur">
      </div>

      <i class="i-ant-design:close-circle-outlined cursor-pointer w-30px" @click="value = (min ? min : null)"
        v-if="value && !disabled" data-test-id="d916" />
      <TtxsSelect :class="{ 'borderErr': state.fromCurrency.symbol === state.toCurrency.symbol }" :icon="icon"
        @click="onSelect($event)" data-test-id="niba">{{ symbol }}</TtxsSelect>
    </div>

    <i v-if="who == 'from' && Number(state.fromQuteData.depositMin) > 0" class="text-13px">范围：{{
      state.fromQuteData.depositMin }} ~ {{ state.fromQuteData.depositMax }}</i>
  </div>
</template>

<style lang="scss" scoped>
.currency {
  .input {
    input::placeholder {
      color: #768cb2;
    }

    :deep(.ant-form-item) {
      flex: 1 0 0;
      margin-bottom: 0 !important;

      .ant-input-number-group-wrapper {
        margin: 0;
      }

      input {
        padding: 0;
        font-size: 20px;
      }

      .ant-form-item-explain-error {
        position: absolute;
        bottom: -10px;
      }
    }
  }
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputBox {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: transparent;
  border: none;
}

.borderErr {
  border: 1px solid #d42828;
}</style>
