<template>
  <i class="w-full h-full dotted-line" />
</template>

<style lang="scss" scoped>
.dotted-line{
  pointer-events: none;
}

/* 连接两个背景的虚线 */
.dotted-line::before {
  position: absolute;
  z-index: 0;
  width: 2px;
  background: repeating-linear-gradient(
      to bottom,
      transparent,
      transparent 4px,
      #778DB3 4px,
      #778DB3 6px
    );
  content: "";
  inset: 33% 50%;
}
</style>
