<!-- 转账支付信息表弹窗 -->
<script setup lang="ts">
import { useQRCode } from '@vueuse/integrations/useQRCode'
import TtxsHead from './components/TtxsHead.vue'
import TtxsCopy from './components/TtxsCopy.vue'
import TtxsTextGreen from './components/TtxsTextGreen.vue'
import TtxsTextWhite from './components/TtxsTextWhite.vue'
import TtxsBorderImg from './components/TtxsBorderImg.vue'
import TtxsLineDottedY from './components/TtxsLineDottedY.vue'
import { useGlobalState } from '../../hooks/useGlobalState'
import * as helpers from "../../wallet/helpers"
import type * as types from "../../wallet/types"
import * as config from "../../wallet/config"
import { debounce } from 'lodash-es';
import { wallet } from "../../wallet"
const state = useGlobalState()

interface Prors {
  who?: String | Number,
  modelValue?: boolean
}
const $prors = withDefaults(defineProps<Prors>(), {
  who: () => "",
  modelValue: true,
})
const emit = defineEmits(['update:modelValue', 'onSubmit'])
const onClickSubmit = debounce(onSubmit, 3000,
  {
    leading: true,
    trailing: false,
  }
);

const text = ref("")
const qrcode = useQRCode(text)

const show = ref($prors.modelValue)
async function setShow(e: boolean) {
  console.log('TtxsModal5.vue setShow')
  show.value = e
  if (e == true) {
    let pluginName = state.currencyOrderData.approve_address as types.type_wallet
    if (config.chain_ethereum.includes(state.fromCurrency.chain)) {
      pluginName = 'Ethereum'
    }
    if (config.chain_tron.includes(state.fromCurrency.chain)) {
      pluginName = 'Tron'
    }
    try {
      const qrCodeData = {
        pluginName: pluginName,
        amount: state.currencyOrderData.transfer_approve_address_amount,
        toAddress: state.currencyOrderData.approve_address,
        tokenAddress: state.currencyOrderData.from_token_address,
        decimal: state.currencyOrderData.from_token_decimal
      }
      text.value = await wallet[pluginName].qrCode(qrCodeData)
      console.log("二维码 >>", text.value)
    } catch (error) {
      console.log('error >>', error)
      helpers.catchMsg(error)
    }
  }
}
watch(() => $prors.modelValue, setShow)

function onSubmit() {
  emit('onSubmit', { key: 'onSubmit', $prors })
}
function onClose() {
  emit('update:modelValue', false)
}

function filterNum(from_token_decimal: any, to_token_amount: any, decimal = 6) {
  return Number(Number(Number(to_token_amount) / Number(from_token_decimal)).toFixed(decimal))
}
//参考汇率
function rate(state: any) {
  let a = helpers.formatUnits(state.currencyOrderData.from_token_amount, state.currencyOrderData.from_token_decimal)
  let b = helpers.formatUnits(state.currencyOrderData.to_token_amount, state.currencyOrderData.to_token_decimal)
  let c = filterNum(a, b)
  return c;
}
</script>

<template>
  <AModal v-model:open="show" :width="580" wrapClassName="ant-cover__basic-modal TTXS" :footer="null" :maskClosable="false"
    @cancel="onClose">
    <div>
      <header class="flex justify-center items-center py-3">
        <TtxsHead />
      </header>
      <div class="border-bottom py-20px section-box">
        <section style="grid-area: left">
          <div class="h-full flex flex-col items-center justify-between relative">
            <TtxsLineDottedY class="absolute" />
            <TtxsBorderImg :src="state.fromCurrency.logoURI" class="translate-y-6px" />
            <TtxsBorderImg :src="state.toCurrency.logoURI" class="translate-y--2px" />
          </div>
        </section>

        <section style="grid-area: top">
          <p v-if="!state.currencyOrderData.from_address" class="h-10px"></p>
          <p>
            <TtxsTextWhite>{{ $t('2fE1Tj6_1XngyFoAicZl') }}</TtxsTextWhite>
            <TtxsTextGreen class="mx-2">{{
              helpers.formatAmount(Number(helpers.formatUnits(state.currencyOrderData.transfer_approve_address_amount,
                state.currencyOrderData.from_token_decimal))) }}</TtxsTextGreen>
            <TtxsTextWhite>{{ state.currencyOrderData.from_token_symbol_show }}</TtxsTextWhite>
          </p>
          <TtxsCopy v-if="state.currencyOrderData.from_address" :content="state.currencyOrderData.from_address">
            {{ desensitization(state.currencyOrderData.from_address, 10, -10) }}</TtxsCopy>
          <p v-else class="h-10px"></p>
        </section>
        <section style="grid-area: bottom">
          <p>
            <TtxsTextWhite>{{ $t('nCsInq5pqOptCsO6vyXPn') }}</TtxsTextWhite>
            <TtxsTextGreen class="mx-2">{{
              helpers.formatAmount(Number(helpers.formatUnits(state.currencyOrderData.to_token_amount,
                state.currencyOrderData.to_token_decimal))) }}</TtxsTextGreen>
            <TtxsTextWhite>{{ state.currencyOrderData.to_token_symbol_show }}</TtxsTextWhite>
          </p>
          <TtxsCopy v-if="state.currencyOrderData.to_address" :content="state.currencyOrderData.to_address">
            {{ desensitization(state.currencyOrderData.to_address, 10, -10)
            }}</TtxsCopy>
          <p v-else class="h-20px"></p>
        </section>
      </div>
      <div class="border-bottom py-20px text-14px lt-tablet:text-12px">
        <p class="flex flex-between">
          <span>{{ $t('wBTcYwtSz97B2Pv0Ha5mG') }}</span>
          <span>1 {{ state.currencyOrderData.from_token_symbol_show }} ≈ {{ rate(state) }} {{
            state.currencyOrderData.to_token_symbol_show }}</span>
        </p>
        <p class="flex flex-between">

          <I18nT keypath="mbBLcV1qbKk7uhYrzjeIq" tag="span" class="flex items-center">
            <template #name>
              <ATooltip>
                <template #title>{{ $t('4TfC0DH3z3e1vbNLDcwZX') }}</template>
                <i class="i-ant-design:question-circle-outlined ml1 text-[1.2em] text-[#8098bf]" />
              </ATooltip>
            </template>
          </I18nT>
          <span>{{ Number(state.currencyOrderData.fee) * 100 }}% + {{ state.currencyOrderData.chainFee }} {{
            state.currencyOrderData.to_token_symbol_show }}</span>
        </p>
        <p class="flex flex-between">
          <span>{{ $t('sTGu7JjiUa9ub6qOHa9hF') }}</span>
          <span>{{ helpers.formatUnits(state.currencyOrderData.to_token_amount, state.currencyOrderData.to_token_decimal)
          }} {{ state.currencyOrderData.to_token_symbol_show }}</span>
        </p>
      </div>

      <div class="py-20px">
        <section class="flex">
          <div class="grow flex flex-col justify-between">
            <p>{{ $t('sF1StTcLgJPtktnxTbJPk') }} {{ state.fromCurrency.chain }}<i v-if="env.dev"> - {{
              state.fromCurrency.chainId }}</i></p>
            <p>{{ $t("87NGtFEMH8ZZ1Oqemg0Fg") }}</p>
            <TtxsCopy :content="state.currencyOrderData.approve_address">
              <TtxsTextGreen style="font-size: 16px;">{{ desensitization(state.currencyOrderData.approve_address, 10, -10)
              }}</TtxsTextGreen>
            </TtxsCopy>
            <p>{{ $t("6gnSJ4H0vSkjBn0M9k0VQ") }}</p>
            <TtxsCopy
              :content="helpers.formatUnits(state.currencyOrderData.transfer_approve_address_amount, state.currencyOrderData.from_token_decimal)">
              <TtxsTextGreen style="font-size: 16px;">{{ helpers.formatAmount(
                helpers.formatUnits(state.currencyOrderData.transfer_approve_address_amount,
                  state.currencyOrderData.from_token_decimal))
              }} {{ state.fromCurrency.symbol_show }}
              </TtxsTextGreen>
            </TtxsCopy>
          </div>
          <img class="w-125px h-125px ml-5 lt-mobilel:hidden" :src="qrcode" :alt="$t('a4SWgMCv77ZY9kUzCdz_v')">
        </section>
      </div>
      <div>
        <p>
          <a href="javascript:;" data-test-id="rcN0">{{ $t('HlROoZcvz5XVLjRPort5j') }}</a>
        </p>
        <I18nT keypath="ct9VOG1TsoCvpyag1WWto" tag="p">
          <template #TRX><a href="javascript:;" data-test-id="lPcz">{{ state.currencyOrderData.from_token_symbol_show }}</a></template>
        </I18nT>
        <p>{{ $t('7wgOqF9eWDY41zk2tBOBE') }}</p>
      </div>

      <footer class="mt-20px">
        <AButton class="w-100% comfirm-btn" @click="onClickSubmit" data-test-id="dqyh">{{ $t("5Dny6DH4KEfk8dFL2Iucs") }}</AButton>
      </footer>
    </div>
  </AModal>
</template>
<style lang="scss" scoped>
@import "../../../../style/deep-ant.scss";
</style>
<style lang="scss" scoped>
.section-box {
  display: grid;
  grid-gap: 25px 15px;
  /* 列的间隙为10px */
  grid-template-areas:
    'left top'
    'left bottom';
  grid-template-columns: auto 1fr;
  /* 设置两列，第一列宽度根据内容自适应，第二列填充剩余空间 */
  grid-template-rows: auto 1fr;
}

.border-bottom {
  border-bottom: 1.5px dashed #35426C;
}
</style>
