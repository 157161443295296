// import axios from 'axios'state

import { ethers } from 'ethers'
import { Web3 } from 'web3'
import { abi } from '../abi'
import type * as payApi from '@/api/specs/pay.api'
import * as helpers from "../../wallet/helpers"
import * as types from '../types'
import { useGlobalState } from '../../hooks/useGlobalState'
const state = useGlobalState()

let ethereum = (window as any)?.ethereum
/** https://docs.metamask.io/wallet/reference/provider-api/#windowethereumismetamask */
export class Ethereum {
  static pluginName: types.type_wallet = 'Ethereum'
  static ethereum = ethereum;

  static provider = new ethers.BrowserProvider(ethereum)
  static web3 = new Web3(ethereum);
  static get address() {
    return this.ethereum?.selectedAddress ?? ''
  }
  static get isPlugin(){
    this.ethereum = (window as any)?.ethereum
    return this.ethereum
  }
  static get deepLink() {
    return `https://metamask.app.link/dapp/${location.href}`
  }
  /** 转账二维码地址 */
  static async qrCode(params: {
    pluginName: types.type_wallet,
    amount: string,
    toAddress: string,
    tokenAddress: string,
    decimal?: number
  }) {
    console.log('Ethereum.ts qrCode', params.toAddress)
    const amount = params.amount
    // return `ethereum:${params.toAddress}?value=${amount.toString()}`
    // const amount = ethers.parseUnits(String(params.amount), params.decimal)
    if (/^0xe+$/.test(params.tokenAddress)) {
      // 原生币
      return `ethereum:${params.toAddress}?value=${amount.toString()}`
    } else {
      // 代币
      return `ethereum:${params.tokenAddress}/transfer?address=${params.toAddress}&uint256=${amount.toString()}`
    }
  }
  // ===================================================
  /** 获取提供者 */
  static getProvider() {
    this.web3 = new Web3(this.ethereum)
    this.provider = new ethers.BrowserProvider(this.ethereum)
    return this.provider
  }
  /** 连接钱包 以太网钱包连接 */
  static async connect() {
    console.log('Ethereum.ts connect');
    if (!this.ethereum) {
      throw new Error('请安装插件')
    }
    // let addressList = this.provider.send('eth_requestAccounts',[])
    let addressList = await this.ethereum.request({ method: 'eth_requestAccounts' })
    return addressList
  }
  /** 断开连接 */
  static async disconnect() {
    await this.ethereum.disconnect()
  }
  /** 切换钱包账户 */
  static async switchConnect() {
    await this.ethereum.request({ "method": "wallet_requestPermissions", "params": [{ eth_accounts: {} }] });
    return await this.connect()
  }
  /** 切换网络 */
  static async switchNetwork(chainId: number) {
    const hex_chainId = `0x${Number(chainId).toString(16)}`
    try {
      await this.ethereum.request({ "method": "wallet_switchEthereumChain", "params": [{ "chainId": hex_chainId }] });
      return this.getProvider()
    } catch (error: any) {
      //锋2024-1-22走坑： 4902 是pc端弹出的   -32603 是小狐狸app移动端弹出！ 居然不统一！
      if (error.code === 4902 || error.code === -32603) {
        try {
          const obj = helpers.getChain(chainId)
          await this.ethereum.request({ "method": "wallet_addEthereumChain", "params": [obj] }).catch((error: types.ProviderRpcError) => {
            return Promise.reject(error)
          })
          return this.getProvider()
        } catch (error: any) {
          return Promise.reject(error)
        }
      }
      return Promise.reject(error)
    }
  }
  /** 获取金额*/
  static async getBalance(tokenAddress: string | undefined): Promise<any> {
    console.log('Ethereum.ts getBalance')
    // if (!this.ethereum.isConnected()) {
    //   return Promise.resolve(0)
    // }
    // if (!chain_ethereum.includes(chain)) {
    //   return Promise.reject(new Error('当前链不支持该钱包兑换，请切换对应钱包'))
    // }
    // 原生 
    if (tokenAddress === undefined || /^0xe+$/.test(tokenAddress)) {
      const balance = await this.provider.getBalance(this.address).then((s) => { return Number(Number(ethers.formatEther(s).toString()).toFixed(6)) })
      return Promise.resolve(balance)
    }
    // 代币
    else {
      const contract = await new ethers.Contract(tokenAddress, abi.erc20aABI, this.provider)
      const tokens = await contract.balanceOf(this.address)
      const decimals = await contract.decimals()  // 18n
      const money = Number(Number(ethers.formatUnits(tokens.toString(), decimals).toString()).toFixed(6))
      console.log('tokens :>> ', money)
      return Promise.resolve(money)
    }
  }
  /** 转账 */
  static async transfer(toAddress: string, amount: string, tokenAddress?: string) {
    const abi = [
      "function decimals() view returns (uint8)",
      "function transfer(address to, uint amount)"
    ]
    const signer = await this.provider.getSigner()
    if (tokenAddress === undefined || /^0xe+$/.test(tokenAddress)) {
      const transferAmount = ethers.parseUnits(amount, 18)
      // 1. 构建交易对象
      const transactionObject = {
        to: toAddress,
        value: ethers.toBeHex(transferAmount),
      };
      // 2.发送交易到以太坊网络 （签名 与广播 是合并的）
      const tx = await signer.sendTransaction(transactionObject);
      // 等待交易确认
      //  const res = await tx.wait()
      return tx
    } else {
      // 代币
      const contract = new ethers.Contract(tokenAddress, abi, signer);
      const decimals = await contract.decimals()  // 18n
      // 转账金额
      const transferAmount = ethers.parseUnits(amount, decimals)
      const tx = await contract.transfer(toAddress, transferAmount) as ethers.TransactionResponse
      // // 构造代币转账交易数据
      // const data = contract.interface.encodeFunctionData('transfer', [toAddress, transferAmount]);

      // 等待交易确认
      // await tx.wait()
      return tx;
    }
  }
  /** swap 跨连桥合约 */
  static async swapTokens(params: payApi.ISginTx, orderInfo: payApi.ISwapDetail) {
    // 
    // BNB(BSC) -> USDT(BSC)  0.07 兑换得 15.703577  原生币-签名数据
    // USDT(BSC) -> WBNB(BSC)  20 兑换得 0.085.98  代币-签名数据
    // params =  {
    //   // 交易数据
    //   "data": "0x16b3b4c2000000000000*********000000000000000000000000000",
    //   // bsc主网合约地址
    //   "to": "0x1ed5685f345b2fa564ea4a670de1fde39e484751",
    //   // 兑换金额
    //   "value": "0xf8b0a10e470000",
    //   "tronRouterAddrees": "",
    //   "tronRouterAddress": "",
    //   "functionName": "",
    //   "options": {
    //     "feeLimit": 0,
    //     "callValue": null
    //   },
    //   "parameter": null,
    //   "fromAddress": "",
    //   "order_id": "4b8f7db464a24fa1a86c0f2fbd519c5f"
    // } 

    // 1. 构建交易对象
    const transactionObject = {
      from: orderInfo.from_address,
      to: params.to,
      data: params.data,
      value: params.value,
      // value:ethers.toBeHex(orderInfo.from_token_amount),
    };

    const signer = await this.provider.getSigner();
    // 2.发送交易到以太坊网络 （签名 与广播 是合并的）
    const signTx = await signer.sendTransaction(transactionObject);

    console.log('2.signTx :>> ', signTx.hash);

    helpers.loopUpdateHash(signTx.hash, orderInfo.order_id)

    return signTx
  }
  /** 检查授权额度 */
  static async allowance(params: {
    /** 代币合约地址 */
    from_token_address?: any;
    /** 以太网 跨链桥合约地址 https://docs-bridgers.swft.pro/ */
    contract_address?: any;
    /** 钱包地址 */
    from_address?: any;
  }): Promise<any> {
    console.log("Ethereum.ts allowance")
    const contract = new this.web3.eth.Contract(abi.ERC20, params.from_token_address);
    const _allowance = await contract.methods.allowance(params.from_address, params.contract_address).call().then((n: any) => n.toString());
    const decimals = await contract.methods.decimals().call().then((n) => Number(n))
    const money = ethers.formatUnits(_allowance, decimals)
    return Number(money);
  }
  /** 币种额度授权 */
  static async approve(params: {
    /** 代币合约地址 */
    from_token_address: string;
    /** 以太网 跨链桥合约地址 https://docs-bridgers.swft.pro/ */
    contract_address: string;
    /** 钱包地址 */
    from_address: string;
    /** 授权的代币数量 带精度 字符串数字 */
    from_token_amount: string;
    /** 精度 */
    from_token_decimal?: any
  }): Promise<any> {
    console.log('Ethereum.ts approve')

    let approve_amount = await this.allowance(params)
    let from_token_amount = Number(ethers.formatUnits(params.from_token_amount, params.from_token_decimal))
    if (approve_amount >= from_token_amount) {
      return approve_amount
    }

    const contract = new this.web3.eth.Contract(abi.ERC20, params.from_token_address);// 创建代币合约实例
    const amount = params.from_token_amount; // 授权的代币数量 "1 000 000" 不需要使用 toHex
    const approveTransaction = contract.methods.approve(params.contract_address, amount);
    const gasLimit = await approveTransaction.estimateGas({ from: params.from_address });
    let gasPrice = await this.web3.eth.getGasPrice();
    // gas价格： 3000000000n >>最大： 26294n
    console.log(">> gas价格：", gasPrice, '>>最大：', gasLimit)
    const tx = await approveTransaction.send({ from: params.from_address, gasPrice: gasPrice.toString(), gas: gasLimit.toString() });
    console.log('tx: >>', tx);
    let result = await this.web3.eth.getTransactionReceipt(tx.transactionHash)
    return result
  }
  /** 校验地址 */
  static async resolveAddress(address: string) {
    try {
      await ethers.resolveAddress(address)
      return Promise.resolve(null)
    } catch (error) {
      return Promise.reject(new Error('jsU3cNdNe6bQm0ZtysdQd'))
    }
  }
  /** 添加代币 */
  static async addToken(record: {
    address: any,
    symbol: any,
    decimals: any,
    logoURI: any,
  }
  ) {
    return this.ethereum.request({
      "method": "wallet_watchAsset",
      "params": {
        "type": "ERC20",
        "options": {
          "address": record.address,
          "symbol": helpers.reSymbol(record.symbol),
          "decimals": record.decimals,
          "image": record.logoURI
        }
      }
    });
  }
}


function handleAccountsChanged(accounts: any) {
  console.log('当用户暴露的帐户地址发生变化时 :>> ', accounts);
  // window.location.reload()
  if (state.currentWallet.pluginName === "ethereum") {
    state.currentWallet.address = accounts[0] ?? false
  }
}
function handlerChainChanged(chainId: string) {
  console.log('当前连接的链发生变化时 :>> ', chainId);
  // window.location.reload()
}
function handlerConnect(connectInfo: types.ConnectInfo) {
  console.log('当连接到以太坊时 :>> ', connectInfo);
}
function handlerDisconnect(error: types.ProviderRpcError) {
  console.log('当与以太坊断开连接时 :>> ', error);
  // sessionStorage.clear()
  // window.location.reload()
}
if (Ethereum.ethereum) {
  Ethereum.ethereum.on('accountsChanged', handleAccountsChanged);
  Ethereum.ethereum.on('chainChanged', handlerChainChanged)
  Ethereum.ethereum.on('connect', handlerConnect);
  Ethereum.ethereum.on('disconnect', handlerDisconnect);
}
