<!-- 连接钱包弹窗 -->
<script setup lang="ts">
import { theme } from 'ant-design-vue';
const { token } = theme.useToken()

import { $t } from '~/components/app-provider/src/AppProvider.vue';
import flatMap from 'lodash-es/flatMap'
import uniqBy from 'lodash-es/uniqBy'
import TtxsPlugInIcon from './components/TtxsPlugInIcon.vue'
import TtxsCopy from './components/TtxsCopy.vue'
import TtxsTextGreen from './components/TtxsTextGreen.vue'
import TtxsModalWalletConnect from './TtxsModalWalletConnect.vue'
import type * as types from "../../wallet/types"
// import * as helpers from "../../wallet/helpers"
import { Wallet } from '../../wallet';
import { useGlobalQuery } from '../../hooks/useGlobalQuery'
import { useGlobalState } from '../../hooks/useGlobalState'

const query = useGlobalQuery()
const state = useGlobalState()



const list = state.walletData
const all = { id: '0', name: 'ALL', key: 'ALL', children: [] } as any
all.children = flatMap(list, 'children')
all.children = uniqBy(all.children, 'name')
const activeKey = ref<number>(all.id)
const navList = computed(() => {
  return [all, ...list]
})

async function onConnectWallet(wallet: types.type_currentWallet) {
  console.log('TtxsModalWalletList.vue - onConnectWallet')
  // if( client.isMobile ){}
  // if( client.isPc ){}
  if (wallet.name === 'ImToken' && client.isPc) {
    openDeepLink.value = true
    state.currentWallet = JSON.parse(JSON.stringify(wallet))
    query.balanceQuery.refetch()
    onClose()
    return
  } else {
    // [☆] 记录连接钱包弹窗的对象展示
    state.state.connectCurrentWallet = JSON.parse(JSON.stringify(wallet))
    try {
      const address = Wallet.plugin(wallet.pluginName).address
      if (!address || !wallet.pluginName) {
        state.state.openWalletConnect = true
        return
      }
      if (address) {
        state.state.openModalWalletList = false
        const addressList = await Wallet.plugin(wallet.pluginName).connect()
        wallet.address = addressList[0]
        state.currentWallet = JSON.parse(JSON.stringify(wallet))
        state.state.isBalanceQuery = true
        query.balanceQuery.refetch()
      }
    } catch (error: Error | any) {
      aMessage.error(error.message)
    }

    if (state.currentWallet?.pluginName === state.state.connectCurrentWallet?.pluginName) {
      query.balanceQuery.refetch()
    }
  }
  query.orderQuery.refetch()
}

/** 弹窗关闭 */
function onClose() {
  state.state.openModalWalletList = false
}

const openDeepLink = ref(false)
</script>

<template>
  <div>
    <AModal v-model:open="state.state.openModalWalletList" :width="540" wrapClassName="ant-cover__basic-modal TtxsModalWalletList"
      :footer="null" :maskClosable="true" @cancel="onClose">
      <div>
        <header class="flex justify-between items-center pr-20px lt-tabletl:pr-35px">
          <div class="text-white text-20px">{{ $t('oagmK3DaMczfn_3nNcVsh') }}</div>
          <div>{{ $t('qqXW7wAYpRwxQtl6aKo3') }}</div>
        </header>
        <ATabs v-model:activeKey="activeKey" class="ttxs-tab" tabPosition="top">
          <ATabPane v-for="(item, i) in navList" :key="item.id" :tab="item.name">
            <div class="scroll-container overflow-y-scroll">
              <div class="grid gap-4">
                <template v-for="(wallet, j) in item.children" :key="`${i}-${j}`">
                  <TtxsPlugInIcon :data="wallet" @click="onConnectWallet(wallet)" data-test-id="58cs">
                    <span style="color: #8098BF">{{ wallet.name }}</span>
                  </TtxsPlugInIcon>
                </template>
              </div>
            </div>
          </ATabPane>
        </ATabs>
      </div>
    </AModal>

    <TtxsModalWalletConnect></TtxsModalWalletConnect>

    <AModal v-model:open="openDeepLink" :title="$t('0uq1ea3uXnGnWkgIgGEdS', { name: state.currentWallet.name })"
      wrapClassName="ant-cover__basic-modal" :footer="null" :maskClosable="true" @cancel="() => { }">
      <div class="flex justify-center relative mt-6">
        <a-qrcode :size="320" error-level="H" :color="token.Space" :bg-color="token.colorBgLayout"
          value="https://www.antdv.com" />
        <svg class="abs-center w-20%" aria-hidden="true">
          <use :xlink:href="`#${state.currentWallet.icon}`"></use>
        </svg>
      </div>
      <p class="mt-6">{{ $t('qxxvR0sfx8inif7JD4fGu', { name: state.currentWallet.name }) }}</p>
      <TtxsCopy class="my-1" :content="(state.currentWallet?.deepLink ?? '')">
        <TtxsTextGreen style="font-size: 16px;">{{ state.currentWallet.deepLink }}</TtxsTextGreen>
      </TtxsCopy>
      <p>{{ $t('Wa1kBPuEFNRdfCQF0AhxO', { name: state.currentWallet.name }) }}<a target="_blank"
          :href="state.currentWallet.pluginUrl" data-test-id="DaSR">{{
            $t('7o2umKh13QF-uUIzjptzu') }}</a></p>
    </AModal>
  </div>
</template>

<style lang="scss">
/** 样式重置 */
.ant-cover__basic-modal.TtxsModalWalletList .ant-modal-content .ant-modal-close {
  top: 35px !important;
}
</style>
<style lang="scss" scoped>
:deep(.ant-tabs).ttxs-tab {
  color: #8098BF;

  .ant-tabs-tab-btn {
    overflow: hidden;
    border-radius: 10px;
    background: #2E3F73;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    background: #266ef1;
    color: #fff;
  }

  .ant-tabs-nav-more {
    display: none !important;
  }

  &.ant-tabs-top {
    .ant-tabs-tab-btn {
      padding: 5px 20px;
    }

    .ant-tabs-tab+.ant-tabs-tab {
      margin: 0 0 0 10px;
    }
  }

  /* 滚动条样式 背景 */
  *::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }

  /* 滚动条样式 滑块 */
  *::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #256DF0;
  }
}

.scroll-container {
  min-height: 400px;
  max-height: 500px;
  -webkit-overflow-scrolling: touch;

  /* 启用iOS设备上的流畅滚动 */
  &>div {
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  }
}

.ttxs-AModal :deep(.ant-modal){
  .ant-modal-close{
    top:35px
  }
}
</style>
