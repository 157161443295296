<!-- 提交确认框  -->
<script setup lang="ts">
import debounce from 'lodash-es/debounce'
import TtxsHead from './components/TtxsHead.vue'
import TtxsCopy from './components/TtxsCopy.vue'
import TtxsTextGreen from './components/TtxsTextGreen.vue'
import TtxsTextWhite from './components/TtxsTextWhite.vue'
import TtxsBorderImg from './components/TtxsBorderImg.vue'
import TtxsLineDottedY from './components/TtxsLineDottedY.vue'
import { useGlobalState } from '../../hooks/useGlobalState'
import { useGlobalQuery } from '../../hooks/useGlobalQuery'
import * as helpers from "../../wallet/helpers"
const query = useGlobalQuery()
const state = useGlobalState()

type Currency = {
  amount?: string | number | 0
  symbol?: string,
  address?: string,
  logoURI?: string
}
interface Prors {
  who: string
  loading: boolean
  modelValue?: boolean

  from?: Currency
  to?: Currency
}
const $prors = withDefaults(defineProps<Prors>(), {
  who: '',
  loading: false,
  modelValue: true,
})
let to_address = ""
watch(() => $prors.modelValue, () => {
  if (!!$prors.modelValue) {
    to_address = (state.receive.address || state.currentWallet.address) as string;
  }
})


const emit = defineEmits(['onClick', 'update:modelValue', 'onSubmit'])

const debounced = debounce((item) => {
  emit('onClick', item)
}, 1000, { leading: true, trailing: false })
/**
 * 所有点击事件集合
 * @param item { key: string , $event: PointerEvent, $prors}
 */
function onClick(item: any) {
  item.key === 'onClose' && onClose()
  item.key === 'onSubmit' && debounced(item)
}

const show = ref($prors.modelValue)
function setShow(e: boolean) {
  show.value = e
}
watch(() => $prors.modelValue, setShow)

function onClose() {
  emit('update:modelValue', false)
}

function filterNum(from_token_decimal: any, to_token_amount: any, decimal = 6) {
  return Number(Number(Number(to_token_amount) / Number(from_token_decimal)).toFixed(decimal))
}
</script>

<template>
  <AModal v-model:open="show" :width="540" wrapClassName="ant-cover__basic-modal TTXS" :footer="null" :maskClosable="false"
    @cancel="onClick({ key: 'onClose', $event, $prors })">
    <div>
      <header class="flex justify-center items-center py-3">
        <TtxsHead :rotate="loading" :state="loading?'load':'none'" />
      </header>

      <div class="border-bottom py-20px section-box">
        <section style="grid-area: left">
          <div class="h-full flex flex-col items-center justify-between relative">
            <TtxsLineDottedY class="absolute" />
            <TtxsBorderImg :src="state.fromCurrency.logoURI" class="translate-y-6px" />
            <TtxsBorderImg :src="state.toCurrency.logoURI" class="translate-y--2px" />
          </div>
        </section>

        <section style="grid-area: top">
          <p>
            <TtxsTextWhite>{{ $t('2fE1Tj6_1XngyFoAicZl') }}</TtxsTextWhite>
            <TtxsTextGreen class="mx-2">{{ helpers.formatAmount(state.fromMoney.amount) }}</TtxsTextGreen>
            <TtxsTextGreen style="font-size: 14px;">{{ state.fromCurrency.symbol_show }}</TtxsTextGreen>
          </p>
          <TtxsCopy :content="state.currentWallet.address">{{ $bp.tabletl ? state.currentWallet.address :
            desensitization(state.currentWallet?.address ?? '', 10, -10) }}</TtxsCopy>
        </section>
        <section style="grid-area: bottom">
          <p>
            <TtxsTextWhite>{{ $t('nCsInq5pqOptCsO6vyXPn') }}</TtxsTextWhite>
            <TtxsTextGreen class="mx-2">{{ helpers.formatAmount(state.toMoney.amount) }}</TtxsTextGreen>
            <TtxsTextGreen style="font-size: 14px;">{{ state.toCurrency.symbol_show }}</TtxsTextGreen>
          </p>
          <TtxsCopy :content="to_address">{{ $bp.tabletl ? to_address : desensitization(to_address, 10, -10) }}</TtxsCopy>
        </section>
      </div>

      <div class="py-20px text-14px lt-tablet:text-12px">
        <p class="flex flex-between">
          <span>{{ $t('wBTcYwtSz97B2Pv0Ha5mG') }}</span>
          <span>1 {{ state.fromCurrency.symbol }} ≈ {{ filterNum(state.fromMoney.amount, state.toMoney.amount) }} {{
            state.toCurrency.symbol_show }}</span>
        </p>
        <p class="flex flex-between">
          <I18nT keypath="mbBLcV1qbKk7uhYrzjeIq" tag="span" class="flex items-center">
            <template #name>
              <ATooltip>
                <template #title>{{ $t('4TfC0DH3z3e1vbNLDcwZX') }}</template>
                <i class="i-ant-design:question-circle-outlined ml1 text-[1.2em] text-[#8098bf]" />
              </ATooltip>
            </template>
          </I18nT>
          <span>{{ query.fromQuteQuery.data.value?.fee * 100 }}% + {{ query.fromQuteQuery.data.value?.chainFee }} {{
            state.toCurrency.symbol_show }}</span>
        </p>
        <p class="flex flex-between">
          <span>{{ $t('sTGu7JjiUa9ub6qOHa9hF') }}</span>
          <span>{{ state.toMoney.amount }} {{ state.toCurrency.symbol_show }}</span>
        </p>
      </div>
    </div>
    <footer class="mt-3">
      <AButton :loading="loading" class="comfirm-btn" block @click="onClick({ key: 'onSubmit', $event, $prors })" data-test-id="srln">{{
        $t('ajBDyzBpaexlUe2HsiUo') }}</AButton>
    </footer>
  </AModal>
</template>

<style lang="scss" scoped>
@import "../../../../style/deep-ant.scss";

.section-box {
  display: grid;
  grid-gap: 25px 15px;
  /* 列的间隙为10px */
  grid-template-areas:
    'left top'
    'left bottom';
  grid-template-columns: auto 1fr;
  /* 设置两列，第一列宽度根据内容自适应，第二列填充剩余空间 */
  grid-template-rows: auto 1fr;
}

.border-bottom {
  border-bottom: 1.5px dashed #35426C;
}
</style>
