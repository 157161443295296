/**
 * 只有扫码访问
 */
import type * as payApi from '@/api/specs/pay.api'
import * as types from '../types'
import { Ethereum } from './Ethereum'
import { Tron } from './Tron'

export const wallet = { Ethereum, Tron }

export class ImToken {
	static pluginName: types.type_wallet = 'Ethereum'
	static get deepLink() {
		// return `imtokenv2://navigate/openURL?url=${location.href}`
		return `imtokenv2://navigate?screen=DappView&url=${location.href}`
	}
	static get imToken() {
		return  (window as any).imToken
	}
	static get ethereum() {
		return (window as any).ethereum
	}
	static get tronWeb() {
		return (window as any).tronWeb
	}
	static get isPlugin() {
		return !!this.imToken
	}
	static get address() {
		return wallet.Ethereum
	}
	/** 转账二维码地址 */
	static async qrCode(params: {
		pluginName: types.type_wallet,
		amount: string,
		toAddress: string,
		tokenAddress: string,
		decimal: number
	}) {
		return wallet[params.pluginName].qrCode(params)
	}
	static isProvider() {
		if (!!this.getProvider()) {
			alert('请安装插件：https://token.im/')
			throw new Error(`https://token.im/`)
		}
		return this.getProvider()
	}
	static getProvider() {
		return !!this.imToken || this.ethereum?.isImToken
	}
	/** 连接 */
	static async connect() {
		
		return wallet[this.pluginName].connect()
	}
	/** 断开连接 */
	static async disconnect() {
		return wallet[this.pluginName].disconnect()
	}
	/** 切换钱包账户 */
	static async switchConnect() {
		return wallet[this.pluginName].switchConnect()
	}
	/** 切换网络 */
	static async switchNetwork(chainId: number) {
		return wallet[this.pluginName].switchNetwork(chainId)
	}
	/** 获取余额 */
	static async getBalance(tokenAddress?: string | undefined) {
		return wallet[this.pluginName].getBalance(tokenAddress)
	}
	/** 跨链桥 */
	static async swapTokens(params: payApi.ISginTx, orderInfo: payApi.ISwapDetail) {
		return wallet[this.pluginName].swapTokens(params, orderInfo)
	}
	/** 转账 */
	static async transfer(toAddress: string, amount: string, tokenAddress?: string) {
		return wallet[this.pluginName].transfer(toAddress, amount, tokenAddress)
	}
	/** 授权 */
	static async approve(params: any) {
		return wallet[this.pluginName].approve(params)
	}
	/** 检查授权额度 */
	static async allowance(params: any) {
		return wallet[this.pluginName].allowance(params)
	}
	/** 检测地址 */
	static async resolveAddress(address: string) {
		return wallet[this.pluginName].resolveAddress(address)
	}
	/** 添加代币 */
	static async addToken(record: {
		address: any,
		symbol: any,
		decimals: any,
		logoURI: any,
	}
	) {
		return wallet[this.pluginName].resolveAddress(record)
	}
	/** 唤起钱包切换钱包 */
	static async showAccountSwitch(chainType: null | "TRON" = null) {
		return await this.imToken.callPromisifyAPI('user.showAccountSwitch', { chainType }) as Promise<{ err: any, address: string }>
	}
}

