<script setup lang="ts">
import TransferPay from './TransferPay.vue'
import DappPay from './DappPay.vue'

import { useGlobalState } from './DappPay/hooks/useGlobalState'
const state = useGlobalState()
import { useGlobalQuery } from './DappPay/hooks/useGlobalQuery'
const query = useGlobalQuery()

const exchangeType = ref(state.is_platform_approve)
const list = reactive([
  { value: 1, title: $t('gjGrWPgmALS4ClV9eoh1U'), comp: shallowRef(TransferPay) },
  { value: 0, title: $t('cQW2HsJAToBq4ktEX6H6K'), comp: shallowRef(DappPay) },
])
watch(exchangeType, (e) => {
  state.is_platform_approve = e
  query.fromQuteQuery.refetch()
  console.log("FunctionArea.vue exchangeType state.is_platform_approve：", state.is_platform_approve)
})

function test(){
  if( env.dev ){
    console.log(`imToken：${!!(window as any).ethereum?.isImToken}`, 2)
    console.log(`ethereum：${!!(window as any).ethereum}`, 2)
    console.log(`tronWeb:  ${!!(window as any).tronWeb}`, 2)
    console.log(`name：${state.currentWallet.name}`, 2)
    // aMessage.info(`chain：${(await Wallet.pluginName(item.chain))}`, 2)
  }
}
</script>
<!--  -->
<template>
  <div class="function-area">
    <div class="function-area-container gradient-border-box">
      <header class="mobile:flex mb-5px lt-mobile:mb-5px tabletl:mb-15px">
        <div class="flex-1">
          <template v-for="item in list">
            <h1 class="lt-mobile:text-center lt-mobile:pb-[10px]" v-if="item.value === exchangeType" @click="test" data-test-id="bz3u">{{ item.title }}</h1>
          </template>
        </div>

        <ARadioGroup v-model:value="exchangeType" buttonStyle="solid" size="small" class="ant-cover__basic-radio ttxs-ARadioGroup">
          <ARadioButton class="lt-mobile:flex-1" v-for="item in list" :value="item.value">{{ item.title }}</ARadioButton>
        </ARadioGroup>

      </header>
      <component class="lt-tabletl:px-15px" :is="item.comp" v-for="item in list" :key="item.value" v-show="item.value === exchangeType" />
    </div>
  </div>
</template>

<style scoped lang="scss">

.function-area {
  @media bp-lt-laptop {
    width: 100%;
  }

  .function-area-container {
    padding: 48px;
    border: 3px solid transparent;
    border-radius: 25px;
    background-color: #222e56;

    @media bp-lt-tabletl {
      padding: 16px 0 32px;
      border-radius: 16px;

      & header {
        padding: 16px;
        border-radius: 16px 16px 0 0;
      }
    }

    &>.switch-currency-button {
      width: 1.875rem;
      height: 1.875rem;
      margin: 1.25rem .625rem;
      cursor: pointer;
    }
  }
}
</style>
