<script setup lang="ts">

import { useGlobalState } from './hooks/useGlobalState'
const state = useGlobalState()

import { useStorage } from '@vueuse/core'
let historyAddressList = localStorage.getItem('historyAddressList') as any;
if (historyAddressList == null) {
  historyAddressList = []
} else {
  historyAddressList = JSON.parse(historyAddressList)
}
const store = useStorage('historyAddressList', historyAddressList, localStorage)


function myRef(defaultValue: number) {
  let value = defaultValue
  return customRef((track, trigger) => ({
    get() {
      track()
      return value
    },
    set(newValue: number) {
      let minValue = 0;
      let maxValue = store.value.length - 1
      if (newValue < minValue) {
        value = minValue
      } else if (newValue > maxValue) {
        value = maxValue
      } else {
        value = newValue
      }
      trigger()
    },
  }))
}
const i = myRef(-1)


function onPaste(event: any) {
  // 处理粘贴事件的代码
  const text = event.clipboardData.getData('text').trim()
  console.log('粘贴内容:', text);
  if (store.value.length > 20) {
    store.value.pop()
  }
  if (!store.value.includes(text) && /^[a-zA-Z0-9]{30,}$/i.test(text) || /^0x[0-9a-f]{30,}$/i.test(text)) {
    store.value.unshift(text)
  }
}
function onUpDownKey(num: number) {
  i.value += num
  state.receive.address = store.value[i.value] ?? ""
}

function filterNum(from_token_decimal: any, to_token_amount: any, decimal = 6) {
  return Number(Number(Number(to_token_amount) / Number(from_token_decimal)).toFixed(decimal))
}
</script>

<template>
  <div class="pay-info mt-4">
    <div class="flex justify-between items-center ">
      <span class="lt-mobilel(text-12px)">{{ $t("OGo0ZWBvZ_RXU-OGCJrLt") }}</span>
    </div>
    <AForm class="address antd-cover__bubble-help-form bg-[--bg-one] rounded-[10px] px-4" d-v-bind="swapForm.formProps">
      <AFormItem class="address-form-item" d-v-bind="swapForm.formItemProps.address">
        <AInput v-model:value.trim="state.receive.address" :bordered="false" :placeholder="$t('zuSWHwZcHrG8_0n1I3vpr')"
          autocomplete="off" @paste="onPaste" @keydown.up="onUpDownKey(1)" @keydown.down="onUpDownKey(-1)"
          data-test-id="CjIc"
        >
        </AInput>
      </AFormItem>
    </AForm>

    <div v-if="Number(state.fromQuteData.fee) > 0" class="ttxs-box-shadow text-[#8098bf]">
      <div class="text-14px lt-tablet:text-12px">
        <p class="flex flex-between">
          <span>{{ $t('wBTcYwtSz97B2Pv0Ha5mG') }}</span>
          <span>1 {{ state.fromCurrency.symbol }} ≈ {{ filterNum(state.fromMoney.amount, state.toMoney.amount) }} {{
            state.toCurrency.symbol_show }}</span>
        </p>
        <p class="flex flex-between">
          <I18nT keypath="mbBLcV1qbKk7uhYrzjeIq" tag="span" class="flex items-center">
            <template #name>
              <ATooltip>
                <template #title>{{ $t('4TfC0DH3z3e1vbNLDcwZX') }}</template>
                <i class="i-ant-design:question-circle-outlined ml1 text-[1.2em] text-[#8098bf]" />
              </ATooltip>
            </template>
          </I18nT>
          <span>{{ Number(state.fromQuteData.fee) * 100 }}% + {{ state.fromQuteData.chainFee }} {{ state.toCurrency.symbol_show
          }}</span>
        </p>
        <p class="flex flex-between">
          <span>{{ $t('sTGu7JjiUa9ub6qOHa9hF') }}</span>
          <span>{{ state.toMoney.amount }} {{ state.toCurrency.symbol_show }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pay-info {
  :deep(.ant-form-item) {
    margin-bottom: 20px;
  }

  :deep(.address) {
    color: #9cb8ff;
    margin-top: 10px;

    .address-form-item {
      input {
        margin-top: 0;
      }
    }

    & .ant-form-item-control-input-content {
      padding: 0 15px;
    }

    & .ant-input {
      margin-top: 0px;
    }
  }
}

.ttxs-box-shadow {
  border-radius: 8px;
  padding: 15px;
  background: #23315f;
  box-shadow: inset 0px 0px 5px #354888;
  margin-bottom: 20px;
}
</style>
