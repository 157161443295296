<script lang="ts" setup>
import axios from 'axios'

import { message } from 'ant-design-vue'
import { useIntervalFn } from '@vueuse/core'
import TtxsTextGreen from './ttxsModal/src/components/TtxsTextGreen.vue'
import TtxsTextWhite from './ttxsModal/src/components/TtxsTextWhite.vue'
import TtxsModal4 from './ttxsModal/src/TtxsModal4.vue'
import { useGlobalState } from './hooks/useGlobalState'
import { useGlobalQuery } from './hooks/useGlobalQuery'
import { Wallet } from './wallet'
// import { useModal1 } from './ttxsModal/useModal'
import imgTo from '@/assets/imgs/swap/to.png'
import emptyImg from '@/assets/imgs/empty.png'
import type * as payApi from '@/api/specs/pay.api'
import * as helpers from './wallet/helpers'
import * as config from "./wallet/config"

const { pause, resume, isActive } = useIntervalFn(loop, 5000)
pause()
const query = useGlobalQuery()
const state = useGlobalState()
// 切换钱包
// const walletModal = useModal1(false)


const columns: any = [
  {
    title: $t('nhEMgPI3T9AeCoCH4Q4Cc'),
    dataIndex: 'create_time',
    defaultSortOrder: 'descend',
  },
  {
    title: $t('6L9WIQGOeWCOHz7yZpk52'),
    dataIndex: 'Exchange',
  },
  {
    title: $t('5KLpW8jcvu5BlACRi2BxJ'),
    dataIndex: 'status',
  },
  {
    title: $t('Rw8dxXTwaKUGFc2QPXFEc'),
    dataIndex: 'Option',
    align: 'center',
  },
]

const open = ref(false)
// 创建取消令牌
let controller: any = null

watch(() => open.value, (e) => {
  e ? resume() : pause()
}, { immediate: true })
/** 根据币种显示对应图片 */
function findImg(symbol: string): string {
  return config.coinList.find((t: payApi.ITokenDetail) => t.symbol === symbol)?.logoURI ?? ''
}
/* 查看详情 */
function onDetail(params: payApi.ISwapDetail | any) {
  console.log('TableOrder.vue onDetail', params.order_id)
  open.value = true

  const orderInfo = JSON.parse(JSON.stringify(params))
  state.currencyOrderData = orderInfo
}
/* 轮询 */
function loop() {
  if (open.value == true && state.currencyOrderData?.handle_status < 3)
    swapTransDataDetail()
}
/* 查询订单 */
function swapTransDataDetail() {
  controller && controller.cancel('Operation canceled by the user.')
  controller = axios.CancelToken.source()
  apisPay.swapTransDataDetail({ order_id: state.currencyOrderData.order_id }, { errorMessageMode: 'none', cancelToken: controller.token }).then((orderInfo: any) => {
    const handle_status = helpers.getStatus(orderInfo.status, state.currencyOrderData.handle_status)
    orderInfo.handle_status = handle_status
    state.currencyOrderData = orderInfo
    console.log(orderInfo);
    if (orderInfo.handle_status === 3) {
      pause()
    }
  }).catch((e: any) => {
    message.error(e.msg)
  })
}

/* 状态样式方法 */
function findStatus(status: string): string {
  let name = ''
  switch (status) {
    case "receive_complete": //"兑换成功",
      name = 'primary-status'
      break
    case "wait_deposit_send": //"等待存币",
    case "wait_exchange_push": //"兑换中",
    case "wait_exchange_return": //"兑换中",
    case "wait_exchange_return_success": //"兑换中",
    case "wait_receive_send": //"兑换中",
    case "wait_enough_send": //"兑换中",
    case "wait_receive_confirm": //"兑换中",
      name = 'warn-status'
      break
    case "wait_refund_send": //"退币中",
    case "wait_refund_confirm": //"退币中",
    case "refund_complete": //"已退币",
    case "timeout": //"兑换超时",
      name = 'danger-status'
      break
    case "ERROR": //"兑换失败",
    case "wait_deposit_send_fail": //"兑换失败",
    case "wait_for_information": //"兑换失败",
      name = 'gray-status'
      break
  }
  return name
}
/* vue组件销毁时执行 */
onUnmounted(() => {
  controller && controller.cancel('Operation canceled by the user.')
  state.state.openModalWalletList = false
})

function toFixed(num: any) {
  return Number(Number(num).toFixed(6))
}
</script>

<template>
  <div class="text-white w-full">

    <ATable class="w-full block lt-tablet:hidden rounded-[20px] overflow-hidden" :columns="columns"
      :data-source="state.orderData.list" :pagination="false">
      <template #emptyText>
        <div class="mt-20px">
          <img :src="emptyImg" class="lt-tablet:h-80px h-150px opacity-60%">
          <div class="text-16px mt-8px text-[#8098bf] lt-tablet:text-14px">
            <p v-if="state.currentWallet.address">{{ $t("Q8smHu0l0nMmaFpE7_DHE") }}</p>
            <div v-else>
              {{ $t("s-g1lDjln7jTc4kzSCohl") }}
              <a @click="state.state.openModalWalletList = true" data-test-id="v9i3">{{
                $t("FVtv_iILMub0tqoKdx_Oq")
              }}</a>
            </div>
          </div>
        </div>
      </template>

      <template #title>
        <TtxsTextWhite class="text-20px">{{ $t('wPaz1HDcKRhe2A5fgvP1D') }}</TtxsTextWhite>
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'create_time'">
          <div class="text-16px text-[#8098bf]">
            {{ helpers.format_parseTime(record.create_time, '{y}-{m}-{d} {h}:{i}:{s}') }}
          </div>
        </template>
        <template v-if="column.dataIndex === 'Exchange'">
          <div class="flex items-center relative top-[5px]">
            <div class="flex items-center min-w-200px">
              <img class="mr-2 h-25px w-25px" :src="findImg(record.from_token_symbol)">
              <TtxsTextGreen class="mx-2">{{ helpers.formatAmount(toFixed(helpers.formatUnits(record.is_platform_approve == 0 ? record.from_token_amount : record.transfer_approve_address_amount,
                record.from_token_decimal))) }}
              </TtxsTextGreen>
              <TtxsTextWhite>{{ record.from_token_symbol_show }}</TtxsTextWhite>
            </div>

            <img class="mx-6 h-25px w-25px" :src="imgTo">

            <div class="flex items-center min-w-200px pl-40px">
              <img class="mr-3 h-25px w-25px" :src="findImg(record.to_token_symbol)">
              <TtxsTextGreen class="mx-2">{{ helpers.formatAmount(toFixed(helpers.formatUnits(record.to_token_amount,
                record.to_token_decimal)))
              }}
              </TtxsTextGreen>
              <TtxsTextWhite>{{ record.to_token_symbol_show }}</TtxsTextWhite>
            </div>
          </div>
        </template>
        <template v-if="column.dataIndex === 'status'">
          <span class="btn-status" :class="findStatus(record.status)">
            {{ $t(record.status) }}
          </span>
        </template>
        <template v-if="column.dataIndex === 'Option'">
          <AButton type="link" @click="onDetail(record)" data-test-id="x7nt">{{ $t("lGe7oxNrQTgnxe8QOzME5") }}</AButton>
        </template>
      </template>
    </ATable>

    <div class="mb-containar hidden lt-tablet:block rounded-[10px] overflow-hidden">
      <header class="py-23px px-15px text-16px">{{ $t('wPaz1HDcKRhe2A5fgvP1D') }}</header>
      <div v-if="state.orderData.list?.length > 0" class="mb-panel">
        <div v-for="(record, index) in state.orderData.list" :key="index" class="historyPanel" @click="onDetail(record)" data-test-id="fpd6">
          <div class="header">
            <span class="text-14px text-[#8098bf]">
              {{ helpers.format_parseTime(record.create_time, '{y}-{m}-{d} {h}:{i}:{s}') }}
            </span>
            <span class="scale-75 btn-status" :class="findStatus(record.status)">
              {{ $t(record.status) }}
            </span>
          </div>
          <div class="body">
            <div class="flex items-center">
              <div class="flex-1 flex justify-start mr-[30px]">
                <div class="flex items-center">
                  <img class="h-25px w-25px mr-2" :src="findImg(record.from_token_symbol)">
                  <div class="flex flex-col">
                    <TtxsTextGreen class="text-[12px]">{{ helpers.formatAmount(toFixed(helpers.formatUnits(record.is_platform_approve == 0 ? record.from_token_amount : record.transfer_approve_address_amount,
                      record.from_token_decimal))) }}</TtxsTextGreen>
                    <TtxsTextWhite class="text-12px">{{ record.from_token_symbol_show }}</TtxsTextWhite>
                  </div>
                </div>
              </div>
              <img class="h-25px w-25px" :src="imgTo">
              <div class="flex-1 flex justify-start ml-[30px]">
                <div class="flex items-center">
                  <img class="h-25px w-25px mr-2" :src="findImg(record.to_token_symbol)">
                  <div class="flex flex-col">
                    <TtxsTextGreen class="text-[12px]">{{ helpers.formatAmount(toFixed(helpers.formatUnits(record.to_token_amount,
                      record.to_token_decimal))) }} </TtxsTextGreen>
                    <TtxsTextWhite class="text-12px">{{ record.to_token_symbol_show }}</TtxsTextWhite>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="text-center py-20px">
        <img :src="emptyImg" class="h-80px opacity-60%">
        <div class="text-14px mt-8px text-[#8098bf]">
          <p v-if="state.currentWallet.address">{{ $t("Q8smHu0l0nMmaFpE7_DHE") }}</p>
          <div v-else>
            {{ $t("s-g1lDjln7jTc4kzSCohl") }}
            <AButton type="link" @click="state.state.openModalWalletList = true" data-test-id="dw25">{{
              $t("FVtv_iILMub0tqoKdx_Oq")
            }}</AButton>
          </div>
        </div>
      </div>
    </div>
    <TtxsModal4 v-model="open" who="带进度的弹窗" />
  </div>
</template>

<style lang="scss" scoped>
:deep(.ant-table-title) {
  padding: 27px 32px;
  font-size: 22px;
  color: var(--first-text);
  text-align: left;
  background: linear-gradient(180deg, #26335f, #192242);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.btn-status {
  height: 40px;
  width: 86px;
  border-radius: 7px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.primary-status {
  border: 1.5px solid #00c1a5;
  color: #00c1a5;
}

.warn-status {
  border: 1.5px solid #fdb754;
  color: #fdb754;
}

.danger-status {
  border: 1.5px solid #db3e3e;
  color: #db3e3e;
}

.gray-status {
  border: 1.5px solid #9b9b9b;
  color: #9b9b9b;
}

.mb-containar {
  background: var(--app-gradient-top-light-d);

  .historyPanel {
    padding: 0 12px 10px;
    background-color: #1a2342;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 8px 8px 15px;
      margin: 0 -12px;
      background-color: #202c52;

      .left {
        --uno: "text-primary";

        display: flex;
        align-items: center;
      }

    }

    .body {
      --uno: "text-text py-15px text-base";
    }
  }
}</style>
