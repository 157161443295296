<!-- 授权弹窗 -->
<script setup lang="ts">
import TtxsTextWhite from './components/TtxsTextWhite.vue'
import { useGlobalState } from '../../hooks/useGlobalState'
import * as helpers from '../../wallet/helpers'
const state = useGlobalState()

interface Prors {
  who?:String|Number
  modelValue?: boolean
  loading?: boolean
  src?:string
}
const $prors = withDefaults(defineProps<Prors>(), {
  who:()=>'',
  modelValue: true,
  loading: false,
  src:'https://transfer.swft.pro/swft-v3/images/coins/TRX.png'
})
const emit = defineEmits(['update:modelValue','onSubmit'])

function onClose() {
  emit('update:modelValue', false)
}
const show = ref($prors.modelValue)
function setShow(e: boolean) {
  show.value = e
}
watch(() => $prors.modelValue, setShow)
</script>

<template>
  <AModal v-model:open="show" :width="420" wrapClassName="ant-cover__basic-modal TTXS" :footer="null" :maskClosable="false" @cancel="onClose">
    <div>
      <header class="flex justify-center items-center py-3">
        <img class="w-80px h-80px" :src="src">
      </header>
      <div class="my-2 text-center">
        <p><TtxsTextWhite><slot>Approve {{ helpers.formatAmount(state.fromMoney.amount) }} {{ state.fromCurrency.symbol_show }}</slot></TtxsTextWhite></p>
        <p class="my-2">{{$t('z5vFuPsz0FbaUtw3uPuWs')}}</p>
      </div>
      <footer class="mt-8">
        <AButton :loading="loading" class="w-100% comfirm-btn" @click="emit('onSubmit',{ key: 'onSubmit', $event, $prors })" data-test-id="qzr4">{{ $t('3fo1ys1Sb3Fx2Eaj4aCwA') }}</AButton>
      </footer>
    </div>
  </AModal>
</template>

<style lang="scss" scoped>
@import "../../../../style/deep-ant.scss";
</style>
