<!-- 钱包提示弹出插件 （2024-1-11废弃 已改用 TtxsModalWalletConnect.vue,TtxsModalWalletLoading.vue） -->
<script setup lang="ts">
import TtxsModalWalletLoading from './TtxsModalWalletLoading.vue'
import TtxsWalletIcon from "./components/TtxsWalletIcon.vue"
import { Wallet } from '../../wallet';
import * as helpers from '../../wallet/helpers'
import { $t } from '~/components/app-provider/src/AppProvider.vue';
import { useGlobalState, } from '../../hooks/useGlobalState'
const state = useGlobalState()
import { useGlobalQuery } from '../../hooks/useGlobalQuery'
const query = useGlobalQuery()

function close() {
  state.state.openWalletConnect = false
}
let a = document.createElement('a')
a.target = "_blank"
async function connect() {
  console.log('TtxsModalWalletConnect.vue connect')
  // 移动端 === 插件不存在 并且是移动端 使用depp跳转
  if (!state.state.connectCurrentWallet?.isPlugin && client.isMobile) {
    a.href = state.state.connectCurrentWallet.deepLink as string
    // a.href = Wallet.plugin(state.state.connectCurrentWallet.pluginName)?.deepLink
    a.click()
    return
  }
  // PC端
  if (state.state.connectCurrentWallet?.pluginName !== 'walletconnect') {
    state.state.openWalletLoading = true
  }
  state.state.connectionStatus = !!state.state.connectCurrentWallet?.isPlugin

  // 弹出未找到钱包
  if (state.state.connectCurrentWallet?.isPlugin === false) {
    return
  }
  try {
    // 1. 连接钱包
    const addressList = await Wallet.plugin(state.state.connectCurrentWallet?.pluginName).connect()
    state.state.connectCurrentWallet.address = addressList[0]
    state.currentWallet = JSON.parse(JSON.stringify(state.state.connectCurrentWallet))
    state.state.openModalWalletList = false
    state.state.openWalletConnect = false

    // 2.切换网络   catch 方法不能去掉 ，用来屏蔽触发 err的消息弹窗 (流程上这里不需要提示)
    await Wallet.plugin(state.currentWallet.pluginName).switchNetwork(Number(state.fromCurrency.chainId)).catch(() => { })
    query.balanceQuery.refetch()
    query.fromQuteQuery.refetch()
    query.orderQuery.refetch()
  }
  catch (error: Error | any) {
    console.log('TtxsModalWalletConnect.vue error >>', error)
    helpers.catchMsg(error)
    state.state.openModalWalletList = false
    state.state.openWalletConnect = false
  }
  finally {
    state.state.connectionStatus = true
    state.state.openWalletLoading = false
  }
}
</script>

<template>
  <div>
    <AModal wrapClassName="ant-cover__basic-modal" :title="$t('wuWqn9c4GPKjbVAkKcBVu')" :maskClosable="true"
      v-model:visible="state.state.openWalletConnect" :footer="false" :width="540" :zIndex="2000" @cancel="close">
      <div>
        <div class="text-left choice px-40px lt-mobile:px-10px">
          <p class="text-text text-xl my-30px text-center">{{ $t('Le4ODqDs5QTWLM2qUb6zp') }}</p>

          <div class="main">
            <div class="item tron" @click="connect()" data-test-id="ifhb">
              <TtxsWalletIcon class="icon" :data="state.state.connectCurrentWallet"></TtxsWalletIcon>
              <span>{{ state.state.connectCurrentWallet.name }}</span>
            </div>
          </div>

          <div class="prompt">{{ $t('PuRuEJfD9QmcdNLacFy4i', { name: state.state.connectCurrentWallet.name }) }}<a
              target="_blank" :href="state.state.connectCurrentWallet.pluginUrl" data-test-id="ySBC">{{
                $t('7o2umKh13QF-uUIzjptzu') }}</a></div>
        </div>
      </div>
    </AModal>
    <TtxsModalWalletLoading />
  </div>
</template>

<style scoped lang="scss">
.main {
  --uno: 'px-30px mb-60px';

  .item {
    --uno: 'rounded-lg bg-bg-info px-30px py-15px text-left flex justify-start items-center text-18px text-text cursor-pointer transition duration-700 ease-in-out';
    --uno: 'hover:scale-105';

    .icon {
      width: 52px;
      --uno: 'text-40px mr-20px';
    }
  }
}
</style>
