import { createGlobalState } from '@vueuse/core'
import { flatMap } from 'lodash-es'

// import uniqBy from 'lodash-es/uniqBy'
import type { type_Currency, type_currentWallet } from '../wallet/types'
import * as config from '../wallet/config'
import type * as payApi from '@/api/specs/pay.api'

/** 全局状态 */
export const useGlobalState = createGlobalState(() => {
  let currentWallet = {} as type_currentWallet
  currentWallet = JSON.parse(sessionStorage.getItem('currentWallet') as string)
  if (!currentWallet)
    currentWallet = flatMap(walletData(), 'children').find((el) => { return !!el.address }) ?? {}

  const state = reactive({
    state: {
      debug: false,
      /** 限制余额展示 */
      isBalanceQuery: false,
      /** from | to */
      who: '',
      /** 币种弹窗链tab */
      fromChain: 'TRON',
      toChain: 'TRON',
      /* 记录连接钱包弹窗的对象展示 */
      connectCurrentWallet: {} as type_currentWallet,
      /** 上次选中的币种 */
      lastFromCurrency: {} as type_Currency,
      /** 钱包列表弹窗 */
      openModalWalletList: false,
      /** 点击连接钱包 弹出确认连接组件显示 */
      openWalletConnect: false,
      /** 点击弹出确认连接 组件显示 */
      openWalletLoading: false,
      /** true=等待连接 false=找不到插件请安装 */
      connectionStatus: true,
    },
    /** 转账兑换=1  DAPP兑换0 */
    is_platform_approve: 0,
    /** 询价对象 */
    fromQuteData: fromQuteData(),
    /** 订单列表数据 */
    orderData: {
      list: [] as payApi.ISwapDetail[],
      pagination: {
        page: 1,
        page_size: 10,
        has_more: true,
        total: 10,
      },
    },
    /** 币种列表数据 */
    chainList: [] as payApi.ITokenDetail[],
    /** 钱包连接列表 响应式 */
    walletData: walletData(),
    /** 当前选中的连接钱包 响应式 */
    currentWallet,
    /** 选中订单的数据 全局公用一个组件吧 */
    currencyOrderData: currencyOrderData(),
    /** from选中的币种 响应式 */
    fromCurrency: fromCurrencyData(),
    /** to选中的币种 响应式 */
    toCurrency: toCurrencyData(),
    /** from兑换金额 响应式 */
    fromMoney: fromMoneyData(),
    /** to兑换金额 响应式 */
    toMoney: toMoneyData(),
    /** 自定义接收方地址 */
    receive: {
      address: '',
      // address: 'TAgv2M2Yirj9WDaYUdXWGJkxZqoLvvPRsq',
      // address: 'TXR4hiTCniVHKhWbe6ps9wNaGRxw28isPo',
    },
    /** 加载 */
    loading: {
      /** 提交 */
      submit: false,
      /** 授权 */
      authorization: false,
      /** 询价 */
      quote: false,
    },
  })

  watch(() => state.currentWallet, (newValue) => {
    console.log('useGlobalState.ts watch state.currentWallet')
    if (newValue) {
      const currentWallet = toRaw(newValue)
      sessionStorage.setItem('currentWallet', JSON.stringify(currentWallet))
    }
  }, { deep: true })

  return state
})

/**
 * 连接钱包 钱包数据 钱包插件
 * @returns {Array} walletData
 */
export function walletData() {
  return JSON.parse(JSON.stringify(config.walletList))
}
/** from选中的币种 初始值 */
export function fromCurrencyData() {
  const data = {
    symbol: 'ETH',
    symbol_show: 'ETH(ERC20)',
    name: 'ETH',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    decimals: 18,
    logoURI: 'https://transfer.swft.pro/swft-v3/images/coins/ETH.png',
    isCrossEnable: 0,
    withdrawGas: 1000,
    chainId: '1',
    chain: 'ETH',
  }
  return data
}
/** to选中的币种 初始值 */
export function toCurrencyData() {
  const data = {
    symbol: 'TRX',
    symbol_show: 'TRX(TRON)',
    name: 'TRX',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    decimals: 6,
    logoURI: 'https://transfer.swft.pro/swft-v3/images/coins/TRX.png',
    isCrossEnable: 0,
    withdrawGas: 1000,
    chainId: '3448148188',
    chain: 'TRON',
  }
  return data
}
/** from币种兑换数据  */
export function fromMoneyData() {
  return {
    /** 余额 */
    balance: 0,
    /** 兑换金额 */
    amount: 0,
    min: 0,
    max: Number.MAX_SAFE_INTEGER,
    precision: 6,
    disabled: false,
  }
}
/** to币种兑换数据 */
export function toMoneyData() {
  return {
    /** 余额 */
    balance: 0,
    /** 兑换金额 */
    amount: 0,
    min: 0,
    max: Number.MAX_SAFE_INTEGER,
    precision: 6,
    disabled: true,
  }
}

/** 创建订单的数据   // 状态  handle_status 0-3 ["Awaiting user deposit",'Exchange','Send','Success'] */
export function currencyOrderData() {
  return {
    id: -1,
    merchant_id: 0,
    order_id: '',
    bridgers_order_id: '0',
    agent_id: 0,
    /** 1=转账  0==跨链 */
    is_platform_approve: 0,
    approve_address: '',
    from_address: '',
    to_address: '',
    is_approve: 0,
    from_token_chain: '',
    from_token_address: '',
    from_token_symbol: '',
    from_token_symbol_show: '',
    to_token_symbol_show: '',
    to_token_symbol: '',
    from_token_amount: '0',
    from_token_decimal: 0,
    to_token_chain: '',
    to_token_address: '',
    to_token_amount: '0',
    to_token_decimal: 0,
    contract_address: '',
    trigger_contract_amount: '0',
    trigger_contract_fee: '0',
    dex: 'bridgers1',
    fee: 0,
    fee_token: '0',
    chainFee: '0',
    deposit_min: '0',
    deposit_max: '200000000000',
    estimated_time: 1,

    /** 转账到平台授权地址hash */
    transfer_hash: '',
    transfer_hash_explore: '',

    /** 触发跨链桥合约hash */
    swap_hash: '',
    /** 触发跨链桥合约hash查看地址 */
    deposit_hash_explore: '',

    /** 到账hash */
    to_hash: '',
    /** 到账hash查看地址 */
    receive_hash_explore: '',

    /** 退款hash查看地址 */
    refund_hash_explore: '',

    equipment_no: '',
    source_flag: '',
    refund_coin_amount: 0,
    refund_hash: '',
    refund_reason: '',
    platform_source: '',
    create_time: 0,
    arrival_time: 0,
    locale: 'zh_cn',
    handle_status: 0,
    status: 'receive_complete',
    transfer_approve_address_amount: '0',
    reserve_address: '',
    profit: '0',
  } as payApi.ISwapDetail
}
/** 询价数据初始化 */
export function fromQuteData() {
  return {
    fromTokenAmount: '',
    fromTokenDecimal: '',
    toTokenAmount: '',
    toTokenDecimal: '',
    dex: '',
    path: [],
    fee: 0,
    feeToken: '',
    contractAddress: '',
    logoUrl: '',
    chainFee: '0',
    depositMin: '0',
    depositMax: '',
    amountOutMin: '0',
    estimatedTime: 0,
  }
}

// // 判断是否为移动端
// function isMobile() {
//   return navigator?.userAgent?.match(/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i);
// }
// function isPC() {
//   var userAgentInfo = navigator?.userAgent;
//   var Agents = ["Android", "iPhone",
//                 "SymbianOS", "Windows Phone",
//                 "iPad", "iPod"];
//   var flag = true;
//   for (var i = 0; i < Agents.length; i++) {
//       if (userAgentInfo.indexOf(Agents[i]) > 0) {
//           flag = false;
//           break;
//       }
//   }
//   return flag;
// }
