<script lang="ts" setup>
import { $t } from '~/components/app-provider/src/AppProvider.vue';
import { useGlobalState } from '../../hooks/useGlobalState'
import { Wallet, wallet } from "../../wallet"
const state = useGlobalState()
function close() {
  state.state.openWalletLoading = false
}
function onRefresh() {
  location.reload()
}


</script>

<template>
  <AModal wrapClassName="ant-cover__basic-modal" v-model:visible="state.state.openWalletLoading" :maskClosable="false"
    :title="$t('qwbCUNQDvpFZwgSo9lm1R', { name: state.state.connectCurrentWallet.name })" :footer="null" :width="540"
    :zIndex="2001" @cancel="close()">
    <div class="connecting text-center">
      <template v-if="Wallet.plugin(state.state.connectCurrentWallet.pluginName).isPlugin === false">
        <i class="icon i-local:warning mt-24px" />
        <p class="title">{{ $t('Scs1BtJFiQfC2n2eQ2AiC') }}</p>
        <p class="sub-title">{{ $t('7gVmuAgRbY3q3O2eMAyU') }}</p>
      </template>

      <template v-else-if="!!state.state.connectionStatus === true">
        <div class="mt-20px"></div>
        <i class="icon i-local:loding animate-spin" />
        <p class="title">{{ $t('dmDO4qpxLqsJOzhU1teK8') }}</p>
        <p class="sub-title">{{ $t('O2Zj6U5svVIpQj2tUMSmV') }}</p>
      </template>

      <template v-else-if="!!state.state.connectionStatus === false">
        <i class="icon i-local:warning" />
        <p class="title">{{ $t('HX6rE1Ogq8SLPMApNvdEp') }}</p>
        <p class="sub-title">{{ $t('qKGvQDSgkL86jRLTDvyID') }}</p>
      </template>

      <div class="bg-bg-info p-30px rounded-lg lt-mobile:py-10px lt-mobile:px-5px">
        <p class="text-text">{{ $t('qMPUnHayPIE4547SQfQYF') }} <a @click="onRefresh" data-test-id="jzur">{{ $t('MbTztQaCSAvsfjCCmOJXY') }}</a>
        </p>
        <p class="text-text">
          {{ $t('pKo4VWMoaHS1r0ZigOwLq', { name: state.state.connectCurrentWallet.name }) }}
          <a target="_blank" :href="state.state.connectCurrentWallet.pluginUrl" data-test-id="mQTS">{{ $t('29a5tvaGc-ZY_qwKhIR6n') }}</a>
        </p>
      </div>
    </div>
  </AModal>
</template>

<style scoped lang="scss">
.connecting {
  .icon {
    --uno: "text-70px mb-37px ";
  }

  .title {
    --uno: "text-24px text-text";
  }

  .sub-title {
    --uno: "text-md mt-13px mb-30px text-primary";
  }
}
</style>
