<!-- 带进度的弹窗 -->
<script setup lang="ts">
import TtxsHead from './components/TtxsHead.vue'
import TtxsCopy from './components/TtxsCopy.vue'
import TtxsTextGreen from './components/TtxsTextGreen.vue'
import TtxsTextWhite from './components/TtxsTextWhite.vue'
import TtxsBorderImg from './components/TtxsBorderImg.vue'
import TtxsLineDottedY from './components/TtxsLineDottedY.vue'
import TtxsProgress from './components/TtxsProgress.vue'
import type * as payApi from '@/api/specs/pay.api'
import * as helpers from '../../wallet/helpers'
import * as config from "../../wallet/config"
import axios from "axios"
import { useIntervalFn } from '@vueuse/core'
import { useGlobalState } from '../../hooks/useGlobalState'
const state = useGlobalState()

const emit = defineEmits(['onClick', 'update:modelValue', 'update:status'])
const { resume, pause } = useIntervalFn(loop, 5000)
interface Prors {
  who: string
  modelValue?: boolean
}
const $prors = withDefaults(defineProps<Prors>(), {
  who: '',
  modelValue: true,
})
const show = ref($prors.modelValue)


/**
 * 所有点击事件集合
 * @param item { key: string , $event: PointerEvent, $prors}
 */
function onClick(item: any) {
  item.key === 'onClose' && onClose()
}
function onClose() {
  emit('update:modelValue', false)
}


async function setShow(e: boolean) {
  console.log('TtxsModal4.vue setShow', e)
  show.value = e
  e ? resume() : pause()
}
watch(() => $prors.modelValue, setShow)


/** 轮询订单状态 */
function loop() {
  if (show.value) {
    swapTransDataDetail()
  }
}
let controller: any = null;

/** 订单详细 */
function swapTransDataDetail() {
  let order = toRaw(state.currencyOrderData)
  console.log('TransferPay.vue swapTransDataDetail', order.order_id)
  console.log(order)
  controller && controller.cancel('Operation canceled by the user.')
  controller = axios.CancelToken.source()
  apisPay.swapTransDataDetail({ order_id: order.order_id }, { errorMessageMode: 'none', cancelToken: controller.token }).then((orderinfo: any) => {
    const handle_status = helpers.getStatus(orderinfo.status, order.handle_status)
    orderinfo.handle_status = handle_status
    state.currencyOrderData = orderinfo
    if (orderinfo.handle_status === 3) {
      pause()
    }
    console.log('TtxsModal4.vue swapTransDataDetail', orderinfo.handle_status, orderinfo.status)
  })
}

/** 根据币种显示对应图片 */
function findImg(symbol: string): string {
  return config.coinList.find((t: payApi.ITokenDetail) => t.symbol === symbol)?.logoURI ?? 'https://file.ttpay.io/crypto/ETH.png'
}
function isErrStatus(status: any) {
  return ['timeout', 'ERROR', 'wait_deposit_send_fail', 'wait_for_information'].includes(status)
}
function isLoadStatus(status: any) {
  return status >= 0 && status < 3
}
</script>

<template>
  <AModal v-model:open="show" :width="540" wrapClassName="ant-cover__basic-modal TTXS" :footer="null"
    :maskClosable="false" @cancel="onClick({ key: 'onClose', $event, $prors })">
    <div>
      <header class="flex justify-center items-center py-3">
        <TtxsHead v-if="isErrStatus(state.currencyOrderData.status)" state="err" />
        <TtxsHead v-else-if="isLoadStatus(state.currencyOrderData.handle_status)" :rotate="true" state="load" />
        <TtxsHead v-else state="exchange" />
      </header>
      <TtxsProgress class="mt-5" :status="state.currencyOrderData.handle_status"></TtxsProgress>
      <div class="border-bottom py-20px section-box">
        <section style="grid-area: left">
          <div class="h-full flex flex-col items-center justify-between relative">
            <TtxsLineDottedY class="absolute" />
            <TtxsBorderImg :src="findImg(state.currencyOrderData.from_token_symbol)" class="translate-y-6px" />
            <TtxsBorderImg :src="findImg(state.currencyOrderData.to_token_symbol)" class="translate-y--2px" />
          </div>
        </section>
        <section style="grid-area: top">
          <p class="text-[#fff]">
            <TtxsTextWhite>{{ $t('2fE1Tj6_1XngyFoAicZl') }}</TtxsTextWhite>
            <TtxsTextGreen class="mx-2">{{
              helpers.formatAmount(Number(helpers.formatUnits(state.currencyOrderData.is_platform_approve == 0 ? state.currencyOrderData.from_token_amount : state.currencyOrderData.transfer_approve_address_amount,
                state.currencyOrderData.from_token_decimal))) }}</TtxsTextGreen>
            <TtxsTextWhite>{{ state.currencyOrderData.from_token_symbol_show }}</TtxsTextWhite>
          </p>
          <TtxsCopy v-if="state.currencyOrderData.from_address" :content="state.currencyOrderData.from_address">
            {{ desensitization(state.currencyOrderData.from_address as
              string, 10, -10) }}</TtxsCopy>
          <p v-else class="h-20px"></p>
        </section>
        <section style="grid-area: bottom">
          <p>
            <TtxsTextWhite>{{ $t('nCsInq5pqOptCsO6vyXPn') }}</TtxsTextWhite>
            <TtxsTextGreen class="mx-2">{{
              helpers.formatAmount(Number(helpers.formatUnits(state.currencyOrderData.to_token_amount,
                state.currencyOrderData.to_token_decimal))) }}</TtxsTextGreen>
            <TtxsTextWhite>{{ state.currencyOrderData.to_token_symbol_show }}</TtxsTextWhite>
          </p>
          <TtxsCopy v-if="state.currencyOrderData.to_address" :content="state.currencyOrderData.to_address">{{
            desensitization(state.currencyOrderData.to_address as string, 10, -10)
          }}</TtxsCopy>
          <p v-else class="h-20px"></p>
        </section>
      </div>

      <div class="border-bottom py-20px">
        <p class="flex flex-between">
          <span>{{ $t('vHKonO6OpOe_6lqPxT3W') }}</span>
          <span v-if="state.currencyOrderData.create_time">{{
            helpers.format_parseTime(state.currencyOrderData.create_time)
          }}</span>
        </p>
        <p class="flex flex-between">
          <span>{{ $t('liAoXbmHoxnMn390i0bz') }}</span>

          <Copy v-if="state.currencyOrderData.order_id" :iconShow="true" :content="state.currencyOrderData.order_id">{{
            desensitization(state.currencyOrderData.order_id, 10, -10) }}</Copy>
        </p>
        <p class="flex flex-between">
          <I18nT keypath="mbBLcV1qbKk7uhYrzjeIq" tag="span" class="flex items-center">
            <template #name>
              <ATooltip>
                <template #title>{{ $t('4TfC0DH3z3e1vbNLDcwZX') }}</template>
                <i class="i-ant-design:question-circle-outlined ml1 text-[1.2em] text-[#8098bf]" />
              </ATooltip>
            </template>
          </I18nT>
          <span v-if="state.currencyOrderData.fee">{{ Number(state.currencyOrderData.fee) * 100 }}% +
            {{ state.currencyOrderData.chainFee }} {{ state.currencyOrderData.to_token_symbol_show }}</span>
        </p>

        <p class="flex flex-between">
          <span>{{ $t('n4hD0SYdhrHbwTGjCtEaA') }}</span>
          <template v-if="state.currencyOrderData.is_platform_approve == 1" remark="转账">
            <Copy v-if="state.currencyOrderData.transfer_hash" :iconShow="false"
              :content="state.currencyOrderData.transfer_hash">
              <a target="_blank" :href="state.currencyOrderData.transfer_hash_explore" data-test-id="NIaM">{{
                desensitization(state.currencyOrderData.transfer_hash,
                  10, -10) }}</a>
            </Copy>
          </template>
          <template v-if="state.currencyOrderData.is_platform_approve == 0" remark="跨链">
            <Copy v-if="state.currencyOrderData.swap_hash" :iconShow="false" :content="state.currencyOrderData.swap_hash">
              <a target="_blank" :href="state.currencyOrderData.deposit_hash_explore" data-test-id="mQTS">{{
                desensitization(state.currencyOrderData.swap_hash,
                  10, -10) }}</a>
            </Copy>
          </template>
        </p>

        <p class="flex flex-between">
          <span>{{ $t('j4jltqSJaYyyrZm85INkN') }}</span>
          <Copy v-if="state.currencyOrderData.to_hash" :iconShow="false" :content="state.currencyOrderData.to_hash">
            <a target="_blank" :href="state.currencyOrderData.receive_hash_explore" data-test-id="Xqck">{{
              desensitization(state.currencyOrderData.to_hash, 10,
                -10) }}</a>
          </Copy>
        </p>
      </div>
      <div class="py-20px">
        <I18nT keypath="a2ew4niYNeaa2WsSDq17T" tag="p">
          <template #name>
            <a target="_blank" href="https://t.me/trongascom" data-test-id="7o76">@trongascom</a>
          </template>
        </I18nT>
      </div>
    </div>
  </AModal>
</template>

<style lang="scss" scoped>
.section-box {
  display: grid;
  grid-gap: 25px 15px;
  /* 列的间隙为10px */
  grid-template-areas:
    'left top'
    'left bottom';
  grid-template-columns: auto 1fr;
  /* 设置两列，第一列宽度根据内容自适应，第二列填充剩余空间 */
  grid-template-rows: auto 1fr;
}

.border-bottom {
  border-bottom: 1.5px dashed #35426C;
}
</style>
