<script setup lang="ts">
import VConsole from 'vconsole'
import Info from './components/Info.vue'
import FunctionArea from './components/FunctionArea.vue'
// import TableOrder from './components/TableOrder.vue'
import TableOrder from './components/DappPay/TableOrder.vue'
import TtxsModalWalletList from './components/DappPay/ttxsModal/src/TtxsModalWalletList.vue'
if (client.isMobile && env.dev) {
  const vConsole = new VConsole()
}
</script>

<template>
  <div class="bridge-swap text-[#B1BDD1]">
    <div class="container page-content-width">
      <Info />
      <FunctionArea />
    </div>
    <div class="container page-content-width" style=" padding-top: 0;">
      <TableOrder />
    </div>
    <TtxsModalWalletList></TtxsModalWalletList>
  </div>
</template>

<style scoped lang="scss">
@import "./style/deep-ant";

.input-clear {
  font-size: 18px;
}

.bridge-swap {
  --uno: " bg-repeat bg-cover  relative w-full ";

  // 解决手机端底部不吸底问题
  @media bp-lt-tabletl {
    --uno: "min-h-[calc(100vh_-_150px)]";
  }

  @media bp-lt-mobilel {
    --uno: "min-h-[calc(100vh_-_110px)]";
  }

  :deep(.container) {
    --uno: "mx-auto my-0 px-0 py-80px flex justify-between";

    width: calc(100% - 30px);
    max-width: 1440px;
    margin: 0 auto;

    @media bp-lt-laptop {
      --uno: "justify-center flex-col p-30px";
    }

    @media bp-lt-tablet {
      padding: 0 0 20px;
    }

    & h1 {
      --uno: "text-white m-0 text-26px font-bold";
    }

    &> :nth-child(1) {
      --uno: "relative";
    }
  }

  :deep(.info) {
    --uno: "w-600px";

    @media bp-lt-laptop {
      --uno: "w-[100%] h-[100%] mb-30px";
    }

    .info-container {
      --uno: " pb-0 pl-0 pr-0";

      @media bp-lt-tablet {
        --uno: "hidden";
      }

      .introduce {
        --uno: "relative mx-0 my-33px";

        &>img {
          --uno: "w-40px h-40px absolute right-0 bottom-0";

          @media bp-lt-laptop {
            --uno: "hidden";
          }
        }
      }
    }
  }

  :deep(.function-area) {
    --uno: "w-[603px]";

    @media bp-lt-laptop {
      --uno: "w-full";
    }

    .mobile-title {
      --uno: "block text-xl text-[#121c41] text-center mb-[30px]";

      @media all and (width >=600px) {
        --uno: "hidden";
      }
    }

    :deep(.function-area-container) {
      --uno: "rounded-[25px] bg-[#1a2342] border-1 border-solid  p-[30px]";

      &>.switch-currency-button {
        --uno: "w-[30px] h-[30px] mx-[10px] my-[20px] cursor-pointer";
      }
    }
  }
}
</style>
<style lang="scss">
/** 重置样式  */
.ant-modal-wrap.ant-cover__basic-modal.TTXS .ant-modal-content .ant-modal-body{
  color: #B1BDD1 !important;
}
</style>