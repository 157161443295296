
import { ethers } from 'ethers'
import type * as payApi from '@/api/specs/pay.api'
import { message, Modal } from "ant-design-vue"
import * as helpers from "../../wallet/helpers"
import * as types from '../types'
import { useGlobalState } from '../../hooks/useGlobalState'
const state = useGlobalState()

// 定义调试模式
// const debug = state.state.debug
const TronChainId: any = {
  '0x2b6653dc': "Main",
  '0x94a9059e': "Shasta",
  '0xcd8690dc': "Nile",


  // '0x2b6653dc': "Main",
  // '0x94a9059e': "Shasta",
  // '0xcd8690dc': "Nile",
}


/**
 * https://docs-zh.tronlink.org/cha-jian-qian-bao/zhu-dong-qing-qiu-tronlink-cha-jian-gong-neng/tian-jia-token 
 * https://developers.tron.network/reference/getaccount 
 */
export class Tron {
  /** 类标识 */
  static pluginName: types.type_wallet = 'Tron'
  static tron = (window as any)?.tron
  static tronWeb = (window as any)?.tronWeb
  static tronLink = (window as any)?.tronLink

  static get deepLink() {
    const param = encodeURIComponent(JSON.stringify({
      "url": location.href, //目标DApp
      "action": "open",
      "protocol": "tronlink",
      "version": "1.0"
    }))
    return `tronlinkoutside://pull.activity?param=${param}`
  }
  static get address() {
    return this.tronWeb?.defaultAddress?.base58 ?? ''
  }
  static get isPlugin(){
    this.tron = (window as any)?.tron
    this.tronWeb = (window as any)?.tronWeb
    this.tronLink = (window as any)?.tronLink
    return this.tronWeb
  }
  /** 转账二维码地址 tron只支持手动输入转账金额 */
  static async qrCode(params: {
    pluginName: types.type_wallet,
    amount?: string,
    toAddress: string,
    tokenAddress?: string,
    decimal?: number
  }) {
    console.log('Tron.ts qrCode', params.toAddress)
    return params.toAddress
  }
  static isProvider() {
    if (!this.tronWeb) {
      alert('请安装插件：https://www.tronlink.org/')
      throw new Error(`https://www.tronlink.org/`)
    }
    return this.tronWeb
  }
  static getProvider() {
    return this.tronWeb
  }
  /** 连接钱包 波场钱包连接 */
  static async connect() {
    console.log('Tron.ts connect')
    if (!this.tronWeb) {
      throw new Error('请安装插件')
    }
    if (this.tronWeb.ready) {
      return Promise.resolve([this.address])
    }
    await this.tronWeb.request({ method: 'tron_requestAccounts' }).then((res: any) => {
      if (res?.code == 200 || res == '') {
        return Promise.resolve([this.address])
      } else {
        return Promise.reject(res)
      }
    })
    await this.tron.request({ method: 'eth_requestAccounts' })
    return Promise.resolve([this.address])
  }
  /** 断开连接 */
  static async disconnect() { }
  /** 切换钱包账户 */
  static async switchConnect() {
    await this.tron.request({ method: 'eth_requestAccounts' })
    return Promise.resolve([this.address])
  }
  /** 切换网络 */
  static async switchNetwork(chainId: string | number) {
    console.log("Tron.ts - switchNetwork", chainId)
    const hex_chainId = `0x${Number(chainId).toString(16)}`
    if (client.isMobile) {
      // const { blockID = '' } = await this.tronWeb.trx.getBlockByNumber(0);
      // const _chainId = `0x${blockID.slice(-8)}`;
      // const url = new URL(location.href);
      // const searchParams = new URLSearchParams(url.search);
      // const pluginName = searchParams.get('pluginName') as string;
      // if (Number(hex_chainId) !== Number(_chainId) && pluginName != 'ethereum') {
      //   // Modal.error({
      //   //   title: `${$t('ajcvpPUBiVqweuJbbE4cL')}${TronChainId[_chainId]}`,
      //   //   content: `${$t('SYTrvK45Y3uMYvAEljNUK')}${TronChainId[hex_chainId]}_${hex_chainId}_${chainId}`,
      //   // });
      // }
      return Promise.resolve('ok')
    }
    return this.tron.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: hex_chainId }],
    })
  }
  /** 获取金额 */
  static async getBalance(tokenAddress: string | undefined): Promise<any> {
    console.log('Tron.ts getBalance')
    // if (!this.tronWeb.ready) {
    //   return Promise.resolve(0)
    // }
    // if (!chain_tron.includes(chain)) {
    //   return Promise.reject(new Error('当前链不支持该钱包兑换，请切换对应钱包'))
    // }
    // 原生
    if (tokenAddress === undefined || /^0xe+$/.test(tokenAddress)) {
      return this.tronWeb?.trx.getBalance(this.address).then((balance: any) => {
        return Number(Number(ethers.formatUnits(balance.toString(), 6)).toFixed(6))
      })
    }
    // 代币
    else {
      const contract = await this.tronWeb?.contract().at(tokenAddress)
      const balance = await contract.balanceOf(this.address).call()
      const decimals = await contract.decimals().call()
      const money = Number(Number(ethers.formatUnits(balance.toString(), decimals)).toFixed(6))
      return Promise.resolve(money)
    }
  }
  /**
   * swap 跨连桥合约
   * @param {Object} params 
   * @example
   * 后端已经兼容 Tron 所有币种不管原生比还是代币都是用同一个接口
   */
  static async swapTokens(params: payApi.ISginTx, orderInfo: payApi.ISwapDetail) {
    try {
      let transaction = await this.tronWeb.transactionBuilder.triggerSmartContract(
        params.tronRouterAddrees, // 从【获取callData数据】模块中的swap接口获取tronRouterAddrees字段
        params.functionName,      // 从【获取callData数据】模块中的swap接口获取functionName字段
        params.options,     // 从【获取callData数据】模块中的swap接口获取options字段
        params.parameter,   //从【获取callData数据】模块中的swap接口获取parameter字段
        params.fromAddress  //从【获取callData数据】模块中的swap接口获取fromAddress字段
      )
      console.log('transaction :>>', JSON.stringify(transaction.transaction, null, 2))

      let signedTx: any = await this.tronWeb.trx.sign(transaction.transaction)
      console.log('signedTx :>>', JSON.stringify(signedTx, null, 2))

      await helpers.loopUpdateHash(signedTx.txID, orderInfo.order_id)

      let broastTx: any = await this.tronWeb.trx.sendRawTransaction(signedTx)
      console.log('broastTx :>>', broastTx)

      if (broastTx?.code && broastTx?.code.includes('ERROR')) {
        return Promise.reject(broastTx)
      }
      return Promise.resolve(broastTx)
    } catch (error: any) {
      console.log("发送失败", error)
      return Promise.reject(error)
    }
  }
  /** 转账 */
  static async transfer(toAddress: string, amount: string, tokenAddress?: string) {
    if (tokenAddress === undefined || /^0xe+$/.test(tokenAddress)) {
      const transferAmount = this.tronWeb.toSun(amount); // 转换为 SUN 格式的金额
      //1.构建交易数据
      const tradeData = await this.tronWeb.transactionBuilder.sendTrx(toAddress, transferAmount, this.address)
      // 3.签名
      const signedTx = await this.tronWeb.trx.sign(tradeData)
      //4.广播交易
      let tx: any = await this.tronWeb.trx.sendRawTransaction(signedTx)

      if (tx?.result) {
        return tx.txid
      } else {
        return Promise.reject(tx)
      }
    } else {
      // 获取 TRC20 合约实例
      const contract = await this.tronWeb.contract().at(tokenAddress);
      const decimals = await contract.decimals().call()
      const transferAmount = ethers.parseUnits(amount, decimals).toString()
      message.success(transferAmount)

      // 调用合约的转账方法 ethers.toBeHex()
      const txID = await contract.transfer(toAddress, transferAmount).send();
      // 处理结果
      console.log(txID);
      return txID
    }
  }
  /**
   * 检查授权额度  暂时不要使用  开发中
   */
  static async allowance(params: {
    /** 代币合约地址 */
    from_token_address: string;
    /**  跨链桥合约地址 https://docs-bridgers.swft.pro/ */
    contract_address: string;
    /** 代币所有者地址 */
    from_address: string;
  } | any): Promise<any> {
    console.log(`Tron.ts allowance`, JSON.stringify(params));
    const contract = await this.tronWeb.contract().at(params.from_token_address);
    const _allowance = await contract.allowance(params.from_address, params.contract_address).call().then((n: any) => n.toString());
    const decimals = await contract.decimals().call()
    const money = ethers.formatUnits(_allowance, decimals)
    return Number(money);
  }

  /** 授权 */
  static async approve(params: {
    /** tron主网合约地址  https://docs-bridgers.swft.pro/ */
    contract_address: string;
    /** 代币所有者地址 */
    from_address: string;
    /** 授权的代币数量 带精度 转 toHex 16进制*/
    from_token_amount?: any;

    from_token_address?: string;

    from_token_decimal?: any
  }): Promise<any> {
    console.log(`Tron.ts approve`, params);
    let approve_amount = await this.allowance(params)
    let from_token_amount = Number(ethers.formatUnits(params.from_token_amount, params.from_token_decimal))
    if (approve_amount >= from_token_amount) {
      return approve_amount
    }
    // // 2023-12-19 18:00:08 之前成功
    // const contract = await this.tronWeb.contract().at(params.contract_address);
    // const value = this.tronWeb.toHex(params.from_token_amount);
    // return contract.approve(params.from_address, value).send();

    //2023-12-19 17:59:47 测试改
    const value = this.tronWeb.toHex(params.from_token_amount);
    const contract = await this.tronWeb.contract().at(params.from_token_address);
    //  return contract.approve(params.contract_address, value).send()
    const hash = await contract.approve(params.contract_address, value).send()
    const result = await this.tronWeb.trx.getTransaction(hash)
    return result
    // approve_amount = await this.allowance(params)
    // while (approve_amount <= params.from_token_amount) {
    //   await sleep(5 * 1000)
    //   approve_amount = await this.allowance(params)
    //   if (approve_amount > params.from_token_amount) {
    //     return approve_amount
    //   }
    // }
  }

  /** 校验地址 */
  static async resolveAddress(address: string) {
    if (this.tronWeb.isAddress(address)) {
      return Promise.resolve(null)
    } else {
      return Promise.reject(new Error('jsU3cNdNe6bQm0ZtysdQd'))
    }
  }

  /** 添加Token https://docs-zh.tronlink.org/cha-jian-qian-bao/zhu-dong-qing-qiu-tronlink-cha-jian-gong-neng/tian-jia-token */
  static async addToken(params: { address: any }): Promise<any> {
    // params?.symbol = helpers.reSymbol(params.options.symbol)
    return this.tronWeb.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'trc20',
        options: {
          address: params.address
        }
      },
    });
  }

  static async getChainId() {
    const { blockID = '' } = await this.tronWeb?.trx?.getBlockByNumber(0);
    return `0x${blockID.slice(-8)}`
  }
}






function handleAccountsChanged(data: types.type_message) {
  console.log('当用户暴露的帐户地址发生变化时 :>> ', data);
  // window.location.reload()
  if (state.currentWallet.pluginName === "tron") {
    state.currentWallet.address = data.data.address as string
  }
}
function handlerChainChanged(data: types.type_message) {
  console.log('当前连接的链发生变化时 :>> ', data);
  // window.location.reload()
}
function handlerConnect(data: types.type_message) {
  console.log('当连接到波场时 :>> ', data);
}
function handlerDisconnect(data: types.type_message) {
  console.log('当与波场断开连接时 :>> ', data);
}
if (Tron.tronWeb) {
  window.addEventListener('message', (e: { data: types.MessageEventAccountsChangedData }) => {
    const msg = e.data?.message
    // 用户登陆
    // 用户切换账号
    // 用户锁定账号
    // 钱包超时自动锁定
    // 文档：https://docs-zh.tronlink.org/cha-jian-qian-bao/bei-dong-jie-shou-tronlink-cha-jian-de-xiao-xi/zhang-hu-gai-bian-xiao-xi
    if (msg?.action == "accountsChanged") { handleAccountsChanged(msg) }
    //  DApp 请求连接，用户在弹窗中确定连接后
    // 用户主动连接网站
    if (msg?.action == "connect") { handlerConnect(msg) }
    //DApp 请求连接，用户在弹窗中拒绝连接后
    //用户主动断开连接网站
    if (msg?.action === 'disconnect') { handlerDisconnect(msg) }
    // 用户改变网络的时候
    if (msg?.action === 'setNode') { handlerChainChanged(msg) }
  })
}
