<!-- 币种列表弹窗 -->
<script setup lang="ts">
import imgSelect from '@/assets/imgs/swap/select.png'
import TtxsInputBg from './components/TtxsInputBg.vue'
import filter from 'lodash-es/filter'
import groupBy from 'lodash-es/groupBy'
import debounce from 'lodash-es/debounce'
import { useGlobalQuery } from '../../hooks/useGlobalQuery'
import { useGlobalState } from '../../hooks/useGlobalState'
import { Wallet, wallet } from '../../wallet'
import type * as types from '../../wallet/types'
import * as config from "../../wallet/config"
import * as helpers from "../../wallet/helpers"

const state = useGlobalState()

interface Prors {
  who: string
  modelValue?: boolean
  chain: string,
  symbol: string,
}
const $prors = withDefaults(defineProps<Prors>(), {
  who: '',
  modelValue: false,
  chain: 'TRON',
  symbol: 'TRX',
})
/** 向父组件触发方法 */
const emit = defineEmits(['update:modelValue'])
const query = useGlobalQuery()
/** 默认显示 */
const show = ref($prors.modelValue)
/** 搜索内容 */
const search = ref('')

watch(() => $prors.modelValue, (e) => {
  show.value = e
})
watch(show, (e) => {
  emit('update:modelValue', e)
})


// /** 自定义排序 */
// function customSort(arr: any, rank: any) {
//   arr.sort((a: any, b: any) => {
//     const rankA = rank.indexOf(a.chain.toUpperCase());
//     const rankB = rank.indexOf(b.chain.toUpperCase());
//     if (rankA === -1 && rankB === -1) {
//       return 0; // 若两个元素都不在 rank 中，保持原有顺序
//     } else if (rankA === -1) {
//       return 1; // a 不在 rank 中，b 在 rank 中，将 b 排在前面
//     } else if (rankB === -1) {
//       return -1; // b 不在 rank 中，a 在 rank 中，将 a 排在前面
//     } else {
//       return rankA - rankB; // a 和 b 都在 rank 中，根据 rank 中的顺序进行排序
//     }
//   });
//   return arr;
// }
// const rank = ['TRON', 'ETH', 'BTC'];
// const arr = [{ chain: 'btc' }, { chain: 'ETH' }, { chain: 'TRON' }];
// const sortedArr = customSort(arr, rank);
// console.log(sortedArr);


/** 渲染列表 useGlobalState().chainQuery.data */
const navList = computed(() => {
  // 原数据[]
  let list = toRaw(state.chainList) ?? []

  // 排序后[]
  // list = customSort(list, ['TRON', "ETH", "BSC", "BASE"])
  // 二维[[],[]]
  list = Object.values(groupBy(list, 'chain')) as []
  const arrList = list.map((group: any) => {
    // const logoURI = (group.find((item:any)=>{return /^0xe+$/.test(item.address)})).logoURI ?? ''
    return {
      chain: group[0].chain,
      // logoURI,
      children: group.map((item: any) => {
        return {
          symbol: item.symbol,
          symbol_show:item.symbol_show,
          name: item.name,
          address: item.address,
          decimals: item.decimals,
          logoURI: item.logoURI,
          isCrossEnable: item.isCrossEnable,
          withdrawGas: item.withdrawGas,
          chainId: item.chainId,
          chain: group[0].chain,
        }
      }),
    }
  })


  return Object.freeze(arrList)
})



/** 下拉币种选择 */
async function onChange(item: types.type_Currency) {
  console.log('content-wallet-modal.vue onChange', $prors.who)

  // ==========标识钱包插件类标识
  if ($prors.who === "from" && !!state.currentWallet.pluginName) {
    (Wallet.plugin(state.currentWallet.pluginName) as any).pluginName = await Wallet.pluginName(item.chain)
  }

  // 定义是否切换网络标记 用于下面的判断
  let isSwitchingNetwork = false
  if (state.state.lastFromCurrency?.chain != item.chain) {
    isSwitchingNetwork = true
    state.state.lastFromCurrency = JSON.parse(JSON.stringify(item))
  }


  // ======下拉选择时 设置默认值
  if ($prors.who.includes("from") && state.toCurrency.symbol != item.symbol) {
    state.fromCurrency = JSON.parse(JSON.stringify(item))
  }
  if ($prors.who.includes("to") && state.fromCurrency.symbol != item.symbol) {
    state.toCurrency = JSON.parse(JSON.stringify(item))
  }

  // =============================================================================
  // 当下拉选择时 判断另一个是否相同，如果相同 就互换一下位置
  // =============================================================================
  // 记录交互值
  if ($prors.who.includes("from") && state.toCurrency.symbol === item.symbol) {
    const fromCurrency = JSON.parse(JSON.stringify(state.fromCurrency))
    state.fromCurrency = JSON.parse(JSON.stringify(item))
    state.toCurrency = fromCurrency
  }
  if ($prors.who.includes("to") && state.fromCurrency.symbol === item.symbol) {
    const toCurrency = JSON.parse(JSON.stringify(state.toCurrency))
    state.toCurrency = JSON.parse(JSON.stringify(item))
    state.fromCurrency = toCurrency
    query.balanceQuery.refetch()
  }
  state.fromMoney.precision = state.fromCurrency.decimals
  state.toMoney.precision = state.toCurrency.decimals
  localStorage.setItem(`fromCurrency.symbol`, state.fromCurrency.symbol)
  localStorage.setItem(`toCurrency.symbol`, state.toCurrency.symbol)
  // =============================================================================

  onClose()

  // 当两选择 两个币种相同时提示
  if (state.fromCurrency.symbol === state.toCurrency.symbol) {
    aMessage.error($t('ysVotDHhIuLSsnDHVoPgF'))
    return
  }

  const switchNetwork = async () => {
    state.state.connectionStatus = true

    if (isSwitchingNetwork && !!state.currentWallet?.address && state.currentWallet.sign.includes(item.chain) && !!state.currentWallet?.pluginName) {

      await Wallet.plugin(state.currentWallet.pluginName).switchNetwork(Number(item.chainId)).catch((error) => {
        console.log('content-wallet-modal.vue onChange', error)
        if (state.currentWallet.sign.includes(item.chain) && state.currentWallet.name !== 'ImToken') {
          helpers.catchMsg(error)
        }
        state.state.lastFromCurrency = {} as types.type_Currency
      })
    }
    await query.balanceQuery.refetch()
  }

  // 切换网络流程
  if ($prors.who === 'from' && !!state.currentWallet?.pluginName) {
    if (client.isPc) {
      await switchNetwork()
    }

    if (client.isMobile) {
      const chain = await Wallet.pluginName(item.chain)
      if (state.currentWallet.name === 'ImToken' && !(window as any).tronWeb && chain === 'Tron') {
        await wallet.ImToken.showAccountSwitch('TRON')
        location.reload()
      } else {
        if (state.currentWallet.name === 'ImToken' && !!(window as any).tronWeb && chain === 'Tron') {
          isSwitchingNetwork = true
        }
        await switchNetwork()
      }
    }
  }

  // 询价
  state.fromMoney.amount > 0 && query.fromQuteQuery.refetch()
}

/** 弹窗关闭 */
function onClose() {
  show.value = false
  emit('update:modelValue', false)
}

function onTab(item: any) {
  console.log('content-wallet-modal.vue onTab', state.state.who)
  if (state.state.who.includes('from')) {
    state.state.fromChain = item.chain
  }
  if (state.state.who.includes('to')) {
    state.state.toChain = item.chain
  }
}



/**
 * 搜索过滤
 * @param list
 */
function filterAr(list: types.type_Currency[]) {
  return filter(list, (currency) => {
    return currency.symbol.toLocaleUpperCase().includes(search.value.toLocaleUpperCase()) || search.value === ''
  })
}
function performSearch(e: any) {
  // 在这里执行搜索逻辑
  console.log('Performing search:', search.value, e.target.value);
  search.value = e.target.value
}
const debouncedSearch = debounce(performSearch, 300, {
  leading: true,
  trailing: true,
});

function addToken(currency: {
  address: any,
  symbol: any,
  decimals: any,
  logoURI: any,
}) {
  console.log(currency)
  // Wallet.plugin(state.currentWallet.pluginName).addToken(currency)
}
</script>

<template>
  <AModal v-model:open="show" :width="540" wrapClassName="ant-cover__basic-modal content-wallet-modal" :footer="null"
    :maskClosable="true" @cancel="onClose">
    <div>
      <header class="pr-30px mb-20px lt-tabletl:pr-35px">
        <TtxsInputBg>
          <AInput v-model="search" @input="debouncedSearch" :bordered="false" placeholder="Search"
            class="h-40px py-3 text-20px flex items-center" data-test-id="EgnB"
          >
            <template #prefix>
              <i class="i-ant-design:search-outlined text-24px" />
            </template>
          </AInput>
        </TtxsInputBg>
      </header>

      <div class="max-h-450px h-auto flex">
        <ul class="h-auto overflow-y-scroll">
          <li :class="{ activeKey: chain === item.chain }"
            class="w-110px text-center bg-[#2E3F73] rounded-8px py-8px mt-11px mr-8px cursor-pointer li-icon"
            v-for="(item, i) in navList" @click="onTab(item)" data-test-id="jje2">{{ item.chain }}</li>
        </ul>
        <div class="flex-1 overflow-y-scroll">
          <div v-for="(item, i) in navList">
            <template v-for="(currency, j) in filterAr(item.children)">
              <div class="w-full flex items-center cursor-poi000000000000000nter mt-20px px-10px pl-20px lt-tablet:pl-10px li-icon-box"
                v-if="chain === currency.chain" @click="onChange(currency)" data-test-id="o458">
                <img class="w-40px h-40px" :src="currency.logoURI">
                <div class="flex-1 px-2 flex flex-col justify-between">
                  <div class="text-16px"><span class="text-white">{{ currency.symbol_show }}</span></div>
                  <div class="text-14px flex items-center">
                    <span>{{ currency.chain }}</span>
                    <template v-if="env.dev">
                      <i class="ml-4"> {{ currency.chainId }}</i>
                      <Copy class="ml-2" :content="currency.address"></Copy>
                      <i class="icon i-local:plus-icon ml-2 text-18px" @click.stop="addToken(currency)" />
                    </template>

                  </div>
                  <p class="text-8px" v-if="env.dev">{{ currency.address }}</p>
                </div>
                <img v-if="currency.symbol === symbol" :src="imgSelect" class="w-20px h-20px">
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </AModal>
</template>

<style lang="scss">
.ant-cover__basic-modal.content-wallet-modal .ant-modal-content .ant-modal-close {
  top: 38px;
  right: 27px;

  @media bp-lt-tabletl {
    right: 17px;
  }
}
</style>
<style lang="scss" scoped>
li.li-icon:first-child {
  margin-top: 0;
}

div.li-icon-box:first-child {
  margin-top: 0;
}


.scroll-container {
  -webkit-overflow-scrolling: touch;

  /* 启用iOS设备上的流畅滚动 */
  &>*:not(:first-child) {
    margin-top: 30px;
  }
}


.activeKey {
  background: #266ef1;
  color: #fff;
}

// * {
//   scrollbar-color: #256DF0 transparent;
//   scrollbar-width: thin;
// }

/* 滚动条样式 背景 */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

/* 滚动条样式 滑块 */
*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #256DF0;
}

@media (pointer: fine) {
  .overflow-y-scroll {
    scrollbar-color: #256DF0 transparent;
    scrollbar-width: 5px;
  }
}
</style>
