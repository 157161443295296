



// import type * as payApi from '@/api/specs/pay.api'
import { ethers } from 'ethers'
import { Web3 } from 'web3'
import * as types from '../types'
import { Ethereum as ImportedEthereum } from "../dapp/Ethereum"
import { Tron as ImportedTron } from "../dapp/Tron"
// // return window.open('https://web3.bitget.com');

const bitkeep = (window as any)?.bitkeep
const ethereum = bitkeep?.ethereum
class Ethereum extends ImportedEthereum {
  static ethereum = ethereum;
  static provider = new ethers.BrowserProvider(ethereum)
  static web3 = new Web3(ethereum);
}
class Tron extends ImportedTron {
  static tron = bitkeep?.tron
  static tronWeb = bitkeep?.tronWeb
  static tronLink = bitkeep?.tronLink
}
export const wallet = { Ethereum, Tron }

/** https://web3.bitget.com/zh-CN/docs/guide/ */
export class Bitkeep {
  /** 类标识 Ethereum | 'Tron' */
  static pluginName: types.type_wallet = 'Ethereum'

  static get deepLink() {
    // return `bitkeep://bkconnect?{params}`
    return `https://bkcode.vip?action=dapp&url=${location.href}`
  }
  /** 当前钱包地址 */
  static get address() {
    return wallet[this.pluginName]?.address ?? ""
  }
  static get isPlugin() {
		return !!bitkeep
	}
  /** 转账二维码地址 */
  static async qrCode(params: {
    pluginName: types.type_wallet
    amount: string,
    toAddress: string,
    tokenAddress: string,
    decimal?: number
  }, chain: string) {
    console.log('Bitkeep.ts qrCode', params.toAddress)
    if (/^0xe+$/.test(params.tokenAddress)) {
      params.tokenAddress = '0x'
    }
    return `https://bkcode.vip?chain=${getChain(chain)}&contract=${params.tokenAddress}&to=${params.toAddress}&amount=${params.amount}&memo=&action=addAsset`
  }
  static getProvider() {
    return wallet[this.pluginName]?.getProvider()
  }
  /** 连接授权 */
  static async connect() {
    console.log('Bitkeep.ts connect')
    return wallet[this.pluginName]?.connect()
  }
  /** 断开连接 */
  static async disconnect() {
    return wallet[this.pluginName]?.disconnect()
  }
  /** 切换钱包账户 */
  static async switchConnect() {
    return wallet[this.pluginName]?.switchConnect()
  }
  /** 切换网络 */
  static async switchNetwork(chainId: string | number): Promise<any> {
    return wallet[this.pluginName]?.switchNetwork(Number(chainId))
  }
  /** 获取余额 */
  static async getBalance(tokenAddress: string | undefined) {
    return wallet[this.pluginName]?.getBalance(tokenAddress)
  }
  /** 跨链桥 https://web3.bitget.com/zh-CN/docs/guide/wallet/ethereum.html#sendtransaction-transfer */
  static async swapTokens(params: payApi.ISginTx, orderInfo: payApi.ISwapDetail) {
    return wallet[this.pluginName]?.swapTokens(params, orderInfo)
  }
  /** 转账 */
  static async transfer(toAddress: string, amount: string, tokenAddress?: string) {
    return wallet[this.pluginName].transfer(toAddress, amount, tokenAddress)
  }
  /** 检索额度 */
  static async allowance(params: any) {
    return wallet[this.pluginName]?.allowance(params)
  }
  /** 授权 */
  static async approve(params: any) {
    return wallet[this.pluginName]?.approve(params)
  }
  /** 校验地址 */
  static async resolveAddress(address: string) {
    return wallet[this.pluginName]?.resolveAddress(address)
  }
  /** 添加代币 */
  static async addToken(record: {
    address: any,
    symbol: any,
    decimals: any,
    logoURI: any,
  }
  ) {
    return wallet[this.pluginName].addToken(record)
  }
  /** 唤起钱包切换钱包 */
	static async showAccountSwitch(chainType: null | "TRON" = null) {}
}


function getChain(chain: string) {
  const data: any = {
    "HECO": "ht",
    "BSC": "bnb",
    "OPTIMISM": "optimism",
    "ETH": "eth",
    "POLYGON": "matic",
    "AVALANCHE": "avax_c",
    "OKEXCHAIN": "okt",
    "BASE": "eth",
    "ARBITRUM": "arbitrum",
    "ZKSYNC": "eth",
    "FANTOM": "ftm",
    "SCROLL": "eth",
    "opBNB": "bnb",
    "zkEVM": "eth",
    "TRON": "trx",
    "SUI": "sui",
    "APT": "apt",
  }
  return data[chain]
}

