<script lang="ts">
import CurrencyInput from './DappPay/CurrencyInput.vue'
import ShowAddress from './DappPay/ShowAddress.vue'
import ExchangeBtn from './DappPay/ExchangeBtn.vue'
import TtxsModal2 from './DappPay/ttxsModal/src/TtxsModal2.vue'
import TtxsModal4 from './DappPay/ttxsModal/src/TtxsModal4.vue'
import TtxsModal5 from './DappPay/ttxsModal/src/TtxsModal5.vue'
import * as helpers from './DappPay/wallet/helpers'
import { useGlobalState } from './DappPay/hooks/useGlobalState'
import { useGlobalQuery } from './DappPay/hooks/useGlobalQuery'
</script>

<script setup lang="ts">
const query = useGlobalQuery()
const state = useGlobalState()

/**  兑换弹窗 */
const payModal = reactive({
  /** from选择币种合约地址弹窗 */
  modal2FromShow: false,
  /** to选择币种合约地址弹窗 */
  modal2ToShow: false,
  /** 带进度的弹窗 */
  show4: false,
  /** 转账扫码的弹出 */
  show5: false,
})


/** 4个状态带进度的弹窗-提交事件 */
async function payModl4_onClick(item: { key: string }) {
  console.log('TransferPay.vue payModl4_onClick', item)
  payModal.show5 = false
  payModal.show4 = false
}

/** 弹出币种列表弹窗 from to点击组件里面的 data = { key: string , $event: any, $prors: prors} */
function onClickCurrency(data: any) {
  console.log('TransferPay.vue onClickCurrency', data)
  state.state.who = data.$prors.who;
  if (data.$prors.who === 'from') {
    payModal.modal2FromShow = true
  }
  if (data.$prors.who === 'to') {
    payModal.modal2ToShow = true
  }
}

/** 下订单后 就弹出确认框 扫码 */
async function onPayModalShow() {
  console.log("onPayModalShow >> 提交")
  if (state.receive.address == '') {
    aMessage.error($t('EIQxvF2aRHrkm79Vmge1h'))
    return
  }
  state.loading.quote = true
  try {
    // 1.创建兑换订单
    let requestBody = {
      /** 是否平台授权 转账填写1  */
      is_platform_approve: 1,
      /** 支付链 */
      from_token_chain: state.fromCurrency.chain,
      /** 支付币种合约地址 */
      from_token_address: state.fromCurrency.address,
      /** 支付金额 */
      from_token_amount: helpers.decimals(state.fromMoney.amount, state.fromCurrency.decimals),
      /** 兑换链 */
      to_token_chain: state.toCurrency.chain,
      /** 兑换币种合约地址 */
      to_token_address: state.toCurrency.address,
      /** 接收地址 */
      to_address: state.receive.address,
      /** 支付地址 必须是空*/
      from_address: "",
    }
    const res = await apisPay.swapCreate({ requestBody }, { errorMessageMode: 'none', resultType: 'api' })
    // if (res.code == 0 && !!res.msg && res.msg != 'ok') {
    //   aMessage.info(res.msg, 2.5)
    // }
    if (res.code != 0) {
      throw new Error(res.msg)
    }
    let orderInfo = res.data
    orderInfo.handle_status = helpers.getStatus(orderInfo.status)
    state.currencyOrderData = orderInfo
    console.log("TransferPay.vue swapCreate >> ")
    console.log(orderInfo)
    payModal.show5 = true;
  } catch (error) {
    console.log('error >>', { error })
    helpers.catchMsg(error)
  }
  finally {
    state.loading.quote = false
  }
}

function onSubmit_payModal_show5() {
  console.log('TransferPay.vue onSubmit_payModal_show5')
  payModal.show4 = true
}

// 组件销毁时触发
onUnmounted(() => {
  console.log('>> onUnmounted')
  state.loading.submit = false
  state.loading.authorization = false
  state.loading.quote = false
})
</script>

<template>
  <div>
    <AForm class="antd-cover__bubble-help-form text-[14px] mt-[20px]">

      <CurrencyInput v-model="state.fromMoney.amount" :title="$t('_X-tn_iR5wA0xAcbpbKCx')" who="from" :showBalance="false"
        :loading="query.balanceQuery.isFetching.value ?? false" :balance="query.balanceQuery.data?.value ?? 0"
        :precision="state.fromMoney.precision" :disabled="state.loading.quote" :icon="state.fromCurrency.logoURI"
        :symbol="state.fromCurrency.symbol_show" @onClick="onClickCurrency" />

      <ExchangeBtn who="transfer"></ExchangeBtn>

      <CurrencyInput v-model="state.toMoney.amount" :title="$t('wZutznikQLic6i7sOz0J5')" who="to" :showBalance="false"
        :min="state.toMoney.min" :max="state.toMoney.max" :precision="state.toMoney.precision"
        :disabled="state.toMoney.disabled" :icon="state.toCurrency.logoURI" :symbol="state.toCurrency.symbol_show"
        @onClick="onClickCurrency" />

      <ShowAddress />

      <AButton class="w-100% comfirm-btn" :loading="state.loading.quote"
        :disabled="state.fromMoney.amount <= 0 || state.toMoney.amount <= 0 || state.fromCurrency.chain + state.fromCurrency.address === state.toCurrency.chain + state.toCurrency.address"
        @click="onPayModalShow" data-test-id="h2qs">{{ $t("b4Yo6M5iiJxWzJTahYxm") }}</AButton>
    </AForm>


    <TtxsModal2 who="from-transfer" v-model="payModal.modal2FromShow" :chain="state.state.fromChain"
      :symbol="state.fromCurrency.symbol" />
    <TtxsModal2 who="to-transfer" v-model="payModal.modal2ToShow" :chain="state.state.toChain"
      :symbol="state.toCurrency.symbol" />

    <TtxsModal4 who="带进度的弹窗" v-model="payModal.show4" @onClick="payModl4_onClick" />
    <TtxsModal5 who="转账扫的弹窗" v-model="payModal.show5" @onSubmit="onSubmit_payModal_show5"></TtxsModal5>
  </div>
</template>

