// 钱包图片
import imgBitgetWallet from '@/assets/imgs/swap/BitgetWallet.png'

/** 添加 URL 查询参数 */
export function tronLink() {
	let param = encodeURIComponent(JSON.stringify({
		"url": location.href,
		"action": "open",
		"protocol": "tronlink",
		"version": "1.0"
	}))
	return `tronlinkoutside://pull.activity?param=${param}`
}
export function ethereumLink() {
	return `https://metamask.app.link/dapp/${location.href}`
}
export function bitkeepLink() {
	return `https://bkcode.vip?action=dapp&url=${location.href}`
}
export function imTokenLink() {
	return `imtokenv2://navigate?screen=DappView&url=${location.href}`
}

export const EMV = ["HECO", "BSC", "OPTIMISM", "ETH", "POLYGON", "AVALANCHE", "OKEXCHAIN", "BASE", "ARBITRUM", "ZKSYNC", "FANTOM", "SCROLL", "opBNB", "zkEVM"]
export const TRON = ["TRON"]
export const SUI = ["SUI"]
export const APT = ["APT"]

/** 配置链标识 调用ethereum */
export const chain_ethereum = [...EMV]
export const chain_tron = [...TRON]
export const chain_bitkeep = [...EMV, ...TRON]
export const chain_imToken = [...EMV, ...TRON]

// ===========================钱包===============================
// pluginName 字段不能随意更改 谨慎更改 已被js绑定 
export const MetaMask = {
	id: '9ed98d6f9b62fa0e',
	network: 'EVM',
	name: 'MetaMask',
	pluginName: 'ethereum',
	sign: chain_ethereum,
	address: (window as any)?.ethereum?.selectedAddress ?? '',
	isPlugin: !!(window as any)?.ethereum,
	isConnect: !!(window as any)?.ethereum?.selectedAddress,
	pluginUrl: 'https://metamask.io/',
	img: "",
	icon: 'i-local:wallets--metamask',
	deepLink: ethereumLink()
}
export const Bitget = {
	id: 'e39bcfa9abd4cad9',
	network: 'EVM|TRON|SUI',
	name: 'Bitget Wallet',
	pluginName: 'bitkeep',
	sign: [...chain_ethereum, ...TRON],
	address: (window as any)?.bitkeep?.ethereum?.selectedAddress ?? '',
	isPlugin: !!(window as any)?.bitkeep?.ethereum,
	isConnect: !!(window as any)?.bitkeep?.ethereum?.selectedAddress,
	pluginUrl: 'https://web3.bitget.com/',
	img: imgBitgetWallet,
	icon: "",
	deepLink: bitkeepLink()
}
export const TronLink = {
	id: '9c46c960a66eb33c',
	network: 'TRON',
	name: 'TronLink',
	pluginName: 'tron',
	sign: chain_tron,
	address: (window as any)?.tronWeb?.defaultAddress?.base58 ?? '',
	isPlugin: !!(window as any)?.tronWeb,
	isConnect: !!(window as any)?.tronWeb?.defaultAddress?.base58,
	pluginUrl: 'https://www.tronlink.org/dlDetails/',
	img: "",
	icon: 'i-local:wallets--tronlink',
	deepLink: tronLink()
}
export const WalletConnect = {
	id: 'xvgYH5TeVi4PpSD',
	network: 'EVM',
	name: 'WalletConnect',
	pluginName: 'walletconnect',
	sign: chain_ethereum,
	address: "",
	isPlugin: true,
	isConnect: false,
	pluginUrl: 'https://walletconnect.com/',
	img: "https://docs.walletconnect.com/img/walletconnect-logo-black.svg#light-mode-only",
	icon: 'icon-walletConnect',
	deepLink: ""
}
export const ImToken = {
	id: 'lxGesa9fbzXWgwv',
	network: 'EVM|TRON',
	name: 'ImToken',
	pluginName: 'imToken',
	sign: [...EMV, ...TRON],
	address: "",
	isPlugin: !!(window as any).imToken,
	isConnect: false,
	pluginUrl: "https://token.im/",
	img: "",
	icon: 'i-local:wallets--imtoken',
	deepLink: imTokenLink()
}
// ===========================钱包===============================

/** 钱包列表 */
export const walletList = [
	{ id: '1', name: 'EVM', key: 'EVM', children: [MetaMask,  /*Bitget, WalletConnect, ImToken*/] },
	{ id: '2', name: 'TRON', key: 'TRON', children: [TronLink, /*Bitget, ImToken*/] },
]

/** 这里是全部币种列表(这里主要做币种图片显示缓存，{symbol:logoURI} ) 通过后端接口获取的 http://swap.tron-gas.net/v1/swap/token_list */
export const coinList = [
	{
		"symbol": "KUNGFU(HECO)",
		"name": "KUNGFU(HECO)",
		"address": "0x1b625dd82aeb3aac21398ea9933fbd56e9652383",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/KUNGFU(HECO).png",
		"chain": "HECO",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "128"
	},
	{
		"symbol": "TKO(BSC)",
		"name": "TKO(BSC)",
		"address": "0x9f589e3eabe42ebc94a44727b3f3531c0c877809",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/TKO(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "ETH(Optimism)",
		"name": "ETH(Optimism)",
		"address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ETH(Optimism).png",
		"chain": "OPTIMISM",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "10"
	},
	{
		"symbol": "MONSTA(BSC)",
		"name": "MONSTA(BSC)",
		"address": "0x8A5d7FCD4c90421d21d30fCC4435948aC3618B2f",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/MONSTA(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "WVPN(BSC)",
		"name": "WVPN(BSC)",
		"address": "0x9d58bE3a935a269D7423D8C027532b7F44dCEE2a",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/WVPN(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "WBNB(BSC)",
		"name": "WBNB(BSC)",
		"address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/WBNB(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "HEPA(BSC)",
		"name": "HEPA(BSC)",
		"address": "0x9159f30f1c3f0317b0a2d6bc176f29266be790ee",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/HEPA(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "DF(Optimism)",
		"name": "DF(Optimism)",
		"address": "0x9e5AAC1Ba1a2e6aEd6b32689DFcF62A509Ca96f3",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/DF(Optimism).png",
		"chain": "OPTIMISM",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "10"
	},
	{
		"symbol": "CRU(ERC20)",
		"name": "CRU(ERC20)",
		"address": "0x32a7c02e79c4ea1008dd6564b35f131428673c41",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/CRU(ERC20).png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "CLV",
		"name": "CLV",
		"address": "0x80c62fe4487e1351b47ba49809ebd60ed085bf52",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/CLV.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "SBANK(BSC)",
		"name": "SBANK(BSC)",
		"address": "0x43acedd39ba4b0bfccd92897fce617fb90a971d8",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/SBANK(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "USDC",
		"name": "USDC",
		"address": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDC.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "KOM(MATIC)",
		"name": "KOM(MATIC)",
		"address": "0xc004e2318722ea2b15499d6375905d75ee5390b8",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/KOM(MATIC).png",
		"chain": "POLYGON",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "137"
	},
	{
		"symbol": "AQT",
		"name": "AQT",
		"address": "0x2a9bdcff37ab68b95a53435adfd8892e86084f93",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/AQT.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "USDT(C-Chain)",
		"name": "USDT(C-Chain)",
		"address": "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDT(C-Chain).png",
		"chain": "AVALANCHE",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "43114"
	},
	{
		"symbol": "QI(C-Chain)",
		"name": "QI(C-Chain)",
		"address": "0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/QI(C-Chain).png",
		"chain": "AVALANCHE",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "43114"
	},
	{
		"symbol": "COCOS",
		"name": "COCOS",
		"address": "0xc4c7ea4fab34bd9fb9a5e1b1a98df76e26e6407c",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/COCOS.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "MIRAI(BSC)",
		"name": "MIRAI(BSC)",
		"address": "0xe74c273ab62169656df1dd146f40e26baef5b057",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/MIRAI(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "RACA(BSC)",
		"name": "RACA(BSC)",
		"address": "0x12bb890508c125661e03b09ec06e404bc9289040",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/RACA(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "FRZSSCOIN(BSC)",
		"name": "FRZSSCOIN(BSC)",
		"address": "0xFbDB1Fa163a0e8d90DD88104F44C093882A71914",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/FRZSSCOIN(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "HT",
		"name": "HT",
		"address": "0x6f259637dcd74c767781e37bc6133cd6a68aa161",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/HT.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "USDD",
		"name": "USDD",
		"address": "0x0c10bf8fcb7bf5412187a595ab97a3609160b5c6",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/USDD.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "COMP",
		"name": "COMP",
		"address": "0xc00e94cb662c3520282e6f5717214004a7f26888",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/COMP.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "DYP(BSC)",
		"name": "DYP(BSC)",
		"address": "0x961C8c0B1aaD0c0b10a51FeF6a867E3091BCef17",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/DYP(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "OKT",
		"name": "OKT",
		"address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/OKT.png",
		"chain": "OKEXCHAIN",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "66"
	},
	{
		"symbol": "BLUR",
		"name": "BLUR",
		"address": "0x5283D291DBCF85356A21bA090E6db59121208b44",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/BLUR.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "ALPHA",
		"name": "ALPHA",
		"address": "0xa1faa113cbe53436df28ff0aee54275c13b40975",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ALPHA.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "TITAN(MATIC)",
		"name": "TITAN(MATIC)",
		"address": "0xaaa5b9e6c589642f98a1cda99b9d024b8407285a",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/TITAN(MATIC).png",
		"chain": "POLYGON",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "137"
	},
	{
		"symbol": "ETH(BSC)",
		"name": "ETH(BSC)",
		"address": "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ETH(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "USDC.E(C-Chain)",
		"name": "USDC.E(C-Chain)",
		"address": "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDC.E(C-Chain).png",
		"chain": "AVALANCHE",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "43114"
	},
	{
		"symbol": "SHIB",
		"name": "SHIB",
		"address": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/SHIB.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "USDC(C-Chain)",
		"name": "USDC(C-Chain)",
		"address": "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDC(C-Chain).png",
		"chain": "AVALANCHE",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "43114"
	},
	{
		"symbol": "MDT(BSC)",
		"name": "MDT(BSC)",
		"address": "0x668db7aa38eac6b40c9d13dbe61361dc4c4611d1",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/MDT(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "TPT(BSC)",
		"name": "TPT(BSC)",
		"address": "0xeca41281c24451168a37211f0bc2b8645af45092",
		"decimals": 4,
		"logoURI": "https://file.ttpay.io/crypto/TPT(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "USDT.E(C-Chain)",
		"name": "USDT.E(C-Chain)",
		"address": "0xc7198437980c041c805a1edcba50c1ce5db95118",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDT.E(C-Chain).png",
		"chain": "AVALANCHE",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "43114"
	},
	{
		"symbol": "CUBY(BSC)",
		"name": "CUBY(BSC)",
		"address": "0x488907d99cE2ac919A47AcFac896e825Fd23598f",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/CUBY(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "HT(HECO)",
		"name": "HT(HECO)",
		"address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/HT(HECO).png",
		"chain": "HECO",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "128"
	},
	{
		"symbol": "USDD(TRON)",
		"name": "USDD(TRON)",
		"address": "TPYmHEhy5n8TCEfYGqW2rPxsghSfzghPDn",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/USDD(TRON).png",
		"chain": "TRON",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "195"
	},
	{
		"symbol": "MATIC(ERC20)",
		"name": "MATIC(ERC20)",
		"address": "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/MATIC(ERC20).png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "MARVIN(BSC)",
		"name": "MARVIN(BSC)",
		"address": "0x54017fda0ff8f380ccef600147a66d2e262d6b17",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/MARVIN(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "DOT(BSC)",
		"name": "DOT(BSC)",
		"address": "0x7083609fce4d1d8dc0c979aab8c869ea2c873402",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/DOT(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "HIGH(BSC)",
		"name": "HIGH(BSC)",
		"address": "0x5f4bde007dc06b867f86ebfe4802e34a1ffeed63",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/HIGH(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "PYUSD(ERC20)",
		"name": "PYUSD(ERC20)",
		"address": "0x6c3ea9036406852006290770BEdFcAbA0e23A0e8",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/PYUSD(ERC20).png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "GHST",
		"name": "GHST",
		"address": "0x3F382DbD960E3a9bbCeaE22651E88158d2791550",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/GHST.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "RNDR",
		"name": "RNDR",
		"address": "0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/RNDR.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "ELV(BSC)",
		"name": "ELV(BSC)",
		"address": "0xE942C48044FB1C7f4C9eB456f6097fa4A1A17B8f",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ELV(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "WETH(MATIC)",
		"name": "WETH(MATIC)",
		"address": "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/WETH(MATIC).png",
		"chain": "POLYGON",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "137"
	},
	{
		"symbol": "LOOKS",
		"name": "LOOKS",
		"address": "0xf4d2888d29D722226FafA5d9B24F9164c092421E",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/LOOKS.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "VIRAL(ERC20)",
		"name": "VIRAL(ERC20)",
		"address": "0x5D6310984776583f58d29E45340f14AD3E8BBb98",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/VIRAL(ERC20).png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "USDC.E(MATIC)",
		"name": "USDC.E(MATIC)",
		"address": "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDC.E(MATIC).png",
		"chain": "POLYGON",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "137"
	},
	{
		"symbol": "FARM",
		"name": "FARM",
		"address": "0xa0246c9032bC3A600820415aE600c6388619A14D",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/FARM.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "ETH(BASE)",
		"name": "ETH(BASE)",
		"address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ETH(BASE).png",
		"chain": "BASE",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "8453"
	},
	{
		"symbol": "DOGE(HECO)",
		"name": "DOGE(HECO)",
		"address": "0x40280e26a572745b1152a54d1d44f365daa51618",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/DOGE(HECO).png",
		"chain": "HECO",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "128"
	},
	{
		"symbol": "USDC.E(ARB)",
		"name": "USDC.E(ARB)",
		"address": "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDC.E(ARB).png",
		"chain": "ARBITRUM",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "42161"
	},
	{
		"symbol": "STG(BSC)",
		"name": "STG(BSC)",
		"address": "0xb0d502e938ed5f4df2e681fe6e419ff29631d62b",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/STG(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "NEXO",
		"name": "NEXO",
		"address": "0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/NEXO.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "BET(HECO)",
		"name": "BET(HECO)",
		"address": "0xe55b3fb96bb83fbd483170eaecc39a8159cb253a",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/BET(HECO).png",
		"chain": "HECO",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "128"
	},
	{
		"symbol": "PMON",
		"name": "PMON",
		"address": "0x1796ae0b0fa4862485106a0de9b654efe301d0b2",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/PMON.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "FWC(BSC)",
		"name": "FWC(BSC)",
		"address": "0x6d3a160b86edcd46d8f9bba25c2f88cccade19fc",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/FWC(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "DAI(C-Chain)",
		"name": "DAI(C-Chain)",
		"address": "0xd586e7f844cea2f87f50152665bcbc2c279d8d70",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/DAI(C-Chain).png",
		"chain": "AVALANCHE",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "43114"
	},
	{
		"symbol": "PNG(C-Chain)",
		"name": "PNG(C-Chain)",
		"address": "0x60781c2586d68229fde47564546784ab3faca982",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/PNG(C-Chain).png",
		"chain": "AVALANCHE",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "43114"
	},
	{
		"symbol": "GEM(BSC)",
		"name": "GEM(BSC)",
		"address": "0xe3d478fe8e8f55f7e9b2b55cf25868edc9f924d8",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/GEM(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "USDC(ZKSYNC)",
		"name": "USDC(ZKSYNC)",
		"address": "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDC(ZKSYNC).png",
		"chain": "ZKSYNC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "324"
	},
	{
		"symbol": "MIKAWA",
		"name": "MIKAWA",
		"address": "0x0888Fd2960Ddf57Cfb561B59d73Dd2b9524F6A26",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/MIKAWA.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "BUSD",
		"name": "BUSD",
		"address": "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/BUSD.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "BSWAP(BASE)",
		"name": "BSWAP(BASE)",
		"address": "0x78a087d713Be963Bf307b18F2Ff8122EF9A63ae9",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/BSWAP(BASE).png",
		"chain": "BASE",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "8453"
	},
	{
		"symbol": "BPEG",
		"name": "BPEG",
		"address": "0x02AD335DD3ca11c18cEBBbB583b9613b6289d75F",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/BPEG.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "LUNAPAD(BSC)",
		"name": "LUNAPAD(BSC)",
		"address": "0xD1FdF57241df3C36dad469060caC9f1ea2eE7585",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/LUNAPAD(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "HBTC",
		"name": "HBTC",
		"address": "0x0316EB71485b0Ab14103307bf65a021042c6d380",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/HBTC.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "LMCSWAP(BSC)",
		"name": "LMCSWAP(BSC)",
		"address": "0x383094a91Ef2767Eed2B063ea40465670bf1C83f",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/LMCSWAP(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "WOO",
		"name": "WOO",
		"address": "0x4691937a7508860f876c9c0a2a617e7d9e945d4b",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/WOO.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "ETH(MATIC)",
		"name": "ETH(MATIC)",
		"address": "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ETH(MATIC).png",
		"chain": "POLYGON",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "137"
	},
	{
		"symbol": "DAI",
		"name": "DAI",
		"address": "0x6B175474E89094C44Da98b954EedeAC495271d0F",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/DAI.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "WETH",
		"name": "WETH",
		"address": "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/WETH.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "ARV(BSC)",
		"name": "ARV(BSC)",
		"address": "0x6679eb24f59dfe111864aec72b443d1da666b360",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/ARV(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "UNI(HECO)",
		"name": "UNI(HECO)",
		"address": "0x22c54ce8321a4015740ee1109d9cbc25815c46e6",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/UNI(HECO).png",
		"chain": "HECO",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "128"
	},
	{
		"symbol": "ETH(HECO)",
		"name": "ETH(HECO)",
		"address": "0x64ff637fb478863b7468bc97d30a5bf3a428a1fd",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ETH(HECO).png",
		"chain": "HECO",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "128"
	},
	{
		"symbol": "RESET",
		"name": "RESET",
		"address": "0x30df7d7ee52c1b03cd009e656f00ab875adceed2",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/RESET.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "SOS",
		"name": "SOS",
		"address": "0x3b484b82567a09e2588a13d54d032153f0c0aee0",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/SOS.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "THC(BSC)",
		"name": "THC(BSC)",
		"address": "0x56083560594E314b5cDd1680eC6a493bb851BBd8",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/THC(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "USDT(HECO)",
		"name": "USDT(HECO)",
		"address": "0xa71edc38d189767582c38a3145b5873052c3e47a",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/USDT(HECO).png",
		"chain": "HECO",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "128"
	},
	{
		"symbol": "ENJ",
		"name": "ENJ",
		"address": "0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ENJ.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "ILV(BSC)",
		"name": "ILV(BSC)",
		"address": "0x4d5ac5cc4f8abdf2ec2cb986c00c382369f787d4",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ILV(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "MEPE",
		"name": "MEPE",
		"address": "0xC80285cbE1B73aa6B7f984AbDc7EE85CCed6dF43",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/MEPE.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "MATIC(MATIC)",
		"name": "MATIC(MATIC)",
		"address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/MATIC(MATIC).png",
		"chain": "POLYGON",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "137"
	},
	{
		"symbol": "IMX",
		"name": "IMX",
		"address": "0xf57e7e7c23978c3caec3c3548e3d615c346e79ff",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/IMX.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "BBTC",
		"name": "BBTC",
		"address": "0x9be89d2a4cd102d8fecc6bf9da793be995c22541",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/BBTC.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "PENDLE",
		"name": "PENDLE",
		"address": "0x808507121B80c02388fAd14726482e061B8da827",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/PENDLE.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "AXLUSDC(BASE)",
		"name": "AXLUSDC(BASE)",
		"address": "0xEB466342C4d449BC9f53A865D5Cb90586f405215",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/AXLUSDC(BASE).png",
		"chain": "BASE",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "8453"
	},
	{
		"symbol": "WETH(BASE)",
		"name": "WETH(BASE)",
		"address": "0x4200000000000000000000000000000000000006",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/WETH(BASE).png",
		"chain": "BASE",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "8453"
	},
	{
		"symbol": "WLD(ERC20)",
		"name": "WLD(ERC20)",
		"address": "0x163f8C2467924be0ae7B5347228CABF260318753",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/WLD(ERC20).png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "PLZ(BSC)",
		"name": "PLZ(BSC)",
		"address": "0xb69016AFdED339c99829AC1340b050E04C08E722",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/PLZ(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "WSPP(BSC)",
		"name": "WSPP(BSC)",
		"address": "0x46d502fac9aea7c5bc7b13c8ec9d02378c33d36f",
		"decimals": 0,
		"logoURI": "https://file.ttpay.io/crypto/WSPP(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "PLZ(TRON)",
		"name": "PLZ(TRON)",
		"address": "TYK71t3eD1pTxpkDp7gbqXM5DYfaVdfKjV",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/PLZ(TRON).png",
		"chain": "TRON",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "195"
	},
	{
		"symbol": "KGO(BSC)",
		"name": "KGO(BSC)",
		"address": "0x5d3AfBA1924aD748776E4Ca62213BF7acf39d773",
		"decimals": 5,
		"logoURI": "https://file.ttpay.io/crypto/KGO(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "GRLC(BSC)",
		"name": "GRLC(BSC)",
		"address": "0x7283dfa2d8d7e277b148cc263b5d8ae02f1076d3",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/GRLC(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "FLUX(BSC)",
		"name": "FLUX(BSC)",
		"address": "0xaff9084f2374585879e8b434c399e29e80cce635",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/FLUX(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "FROG(BSC)",
		"name": "FROG(BSC)",
		"address": "0xDCD103Bc6D14829C39Afc9c10c9c373CE385D2C5",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/FROG(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "FIS",
		"name": "FIS",
		"address": "0xef3a930e1ffffacd2fc13434ac81bd278b0ecc8d",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/FIS.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "BTCB",
		"name": "BTCB",
		"address": "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/BTCB.png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "PRQ",
		"name": "PRQ",
		"address": "0x362bc847A3a9637d3af6624EeC853618a43ed7D2",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/PRQ.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "FTM",
		"name": "FTM",
		"address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/FTM.png",
		"chain": "FANTOM",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "250"
	},
	{
		"symbol": "LINK",
		"name": "LINK",
		"address": "0x514910771af9ca656af840dff83e8264ecf986ca",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/LINK.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "ZKP",
		"name": "ZKP",
		"address": "0x909E34d3f6124C324ac83DccA84b74398a6fa173",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ZKP.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "APE",
		"name": "APE",
		"address": "0x4d224452801aced8b2f0aebe155379bb5d594381",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/APE.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "WLD(Optimism)",
		"name": "WLD(Optimism)",
		"address": "0xdC6fF44d5d932Cbd77B52E5612Ba0529DC6226F1",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/WLD(Optimism).png",
		"chain": "OPTIMISM",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "10"
	},
	{
		"symbol": "YFO(BSC)",
		"name": "YFO(BSC)",
		"address": "0xac0c8da4a4748d8d821a0973d00b157aa78c473d",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/YFO(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "USDD(BSC)",
		"name": "USDD(BSC)",
		"address": "0xd17479997f34dd9156deef8f95a52d81d265be9c",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/USDD(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "USDC(OKExChain)",
		"name": "USDC(OKExChain)",
		"address": "0xc946daf81b08146b1c7a8da2a851ddf2b3eaaf85",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/USDC(OKExChain).png",
		"chain": "OKEXCHAIN",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "66"
	},
	{
		"symbol": "APT",
		"name": "APT",
		"address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/APT.png",
		"chain": "APT",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "0"
	},
	{
		"symbol": "GALA",
		"name": "GALA",
		"address": "0xd1d2Eb1B1e90B638588728b4130137D262C87cae",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/GALA.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "C98(BSC)",
		"name": "C98(BSC)",
		"address": "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/C98(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "MRXB(BSC)",
		"name": "MRXB(BSC)",
		"address": "0x767b28a30e3a15dcece7bff7a020adfde9d19cf8",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/MRXB(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "TXS(BSC)",
		"name": "TXS(BSC)",
		"address": "0x8b298c5fbf0a8d4cd65ae524c8792b8cdfd9fbe9",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/TXS(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "USDC(BASE)",
		"name": "USDC(BASE)",
		"address": "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDC(BASE).png",
		"chain": "BASE",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "8453"
	},
	{
		"symbol": "KAKI(BSC)",
		"name": "KAKI(BSC)",
		"address": "0x42414624C55A9CBa80789f47c8F9828A7974e40f",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/KAKI(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "LIFE(BSC)",
		"name": "LIFE(BSC)",
		"address": "0x82190d28E710ea9C029D009FaD951c6F1d803BB3",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/LIFE(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "DAR",
		"name": "DAR",
		"address": "0x081131434f93063751813c619ecca9c4dc7862a3",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/DAR.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "KNC",
		"name": "KNC",
		"address": "0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/KNC.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "MTRG(BSC)",
		"name": "MTRG(BSC)",
		"address": "0xbd2949f67dcdc549c6ebe98696449fa79d988a9f",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/MTRG(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "LINK(BSC)",
		"name": "LINK(BSC)",
		"address": "0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/LINK(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "DYP(C-Chain)",
		"name": "DYP(C-Chain)",
		"address": "0x961C8c0B1aaD0c0b10a51FeF6a867E3091BCef17",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/DYP(C-Chain).png",
		"chain": "AVALANCHE",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "43114"
	},
	{
		"symbol": "SSWP(SUI)",
		"name": "SSWP(SUI)",
		"address": "0x361dd589b98e8fcda9a7ee53b85efabef3569d00416640d2faa516e3801d7ffc::TOKEN::TOKEN",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/SSWP(SUI).png",
		"chain": "SUI",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "0"
	},
	{
		"symbol": "DREP",
		"name": "DREP",
		"address": "0x3ab6ed69ef663bd986ee59205ccad8a20f98b4c2",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/DREP.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "MNG(BSC)",
		"name": "MNG(BSC)",
		"address": "0x5941f87eb62737ec5ebbecab3e373c40fe40566b",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/MNG(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "USDC(BSC)",
		"name": "USDC(BSC)",
		"address": "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/USDC(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "EGC(BSC)",
		"name": "EGC(BSC)",
		"address": "0xC001BBe2B87079294C63EcE98BdD0a88D761434e",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/EGC(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "SWFTC",
		"name": "SWFTC",
		"address": "0x0bb217E40F8a5Cb79Adf04E1aAb60E5abd0dfC1e",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/SWFTC.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "THE(BSC)",
		"name": "THE(BSC)",
		"address": "0xF4C8E32EaDEC4BFe97E0F595AdD0f4450a863a11",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/THE(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "ROCK(BSC)",
		"name": "ROCK(BSC)",
		"address": "0xC3387E4285e9F80A7cFDf02B4ac6cdF2476A528A",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ROCK(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "DX",
		"name": "DX",
		"address": "0x973e52691176d36453868d9d86572788d27041a9",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/DX.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "BNT",
		"name": "BNT",
		"address": "0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/BNT.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "SKM",
		"name": "SKM",
		"address": "0x048fe49be32adfc9ed68c37d32b5ec9df17b3603",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/SKM.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "YFI",
		"name": "YFI",
		"address": "0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/YFI.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "XMX",
		"name": "XMX",
		"address": "0x0f8c45b896784a1e408526b9300519ef8660209c",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/XMX.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "XIBA",
		"name": "XIBA",
		"address": "0x5e3E11b436Aa399cBc55b0Cc125aF2efB5aa3F42",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/XIBA.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "PINA",
		"name": "PINA",
		"address": "0x02814F435dD04e254Be7ae69F61FCa19881a780D",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/PINA.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "ETH(ARB)",
		"name": "ETH(ARB)",
		"address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ETH(ARB).png",
		"chain": "ARBITRUM",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "42161"
	},
	{
		"symbol": "USDC(TRON)",
		"name": "USDC(TRON)",
		"address": "TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDC(TRON).png",
		"chain": "TRON",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "195"
	},
	{
		"symbol": "OPCAT(BSC)",
		"name": "OPCAT(BSC)",
		"address": "0x6a7a68397b47ecaab4bb3acd7c710be8e906e4ce",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/OPCAT(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "GMX(ARB)",
		"name": "GMX(ARB)",
		"address": "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/GMX(ARB).png",
		"chain": "ARBITRUM",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "42161"
	},
	{
		"symbol": "TUSD(TRON)",
		"name": "TUSD(TRON)",
		"address": "TUpMhErZL2fhh4sVNULAbNKLokS4GjC1F4",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/TUSD(TRON).png",
		"chain": "TRON",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "195"
	},
	{
		"symbol": "UNFI(BSC)",
		"name": "UNFI(BSC)",
		"address": "0x728c5bac3c3e370e372fc4671f9ef6916b814d8b",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/UNFI(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "ALICE(BSC)",
		"name": "ALICE(BSC)",
		"address": "0xAC51066d7bEC65Dc4589368da368b212745d63E8",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/ALICE(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "ENS",
		"name": "ENS",
		"address": "0xc18360217d8f7ab5e7c516566761ea12ce7f9d72",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ENS.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "ETH",
		"name": "ETH",
		"address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ETH.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "DF(BSC)",
		"name": "DF(BSC)",
		"address": "0x4A9A2b2b04549C3927dd2c9668A5eF3fCA473623",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/DF(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "BEND",
		"name": "BEND",
		"address": "0x0d02755a5700414b26ff040e1de35d337df56218",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/BEND.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "GMX(C-Chain)",
		"name": "GMX(C-Chain)",
		"address": "0x62edc0692BD897D2295872a9FFCac5425011c661",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/GMX(C-Chain).png",
		"chain": "AVALANCHE",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "43114"
	},
	{
		"symbol": "1INCH",
		"name": "1INCH",
		"address": "0x111111111117dc0aa78b770fa6a738034120c302",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/1INCH.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "MANA",
		"name": "MANA",
		"address": "0x0f5d2fb29fb7d3cfee444a200298f468908cc942",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/MANA.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "CVC",
		"name": "CVC",
		"address": "0x41e5560054824ea6b0732e656e3ad64e20e94e45",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/CVC.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "DAI(BSC)",
		"name": "DAI(BSC)",
		"address": "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/DAI(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "BIT",
		"name": "BIT",
		"address": "0x1A4b46696b2bB4794Eb3D4c26f1c55F9170fa4C5",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/BIT.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "XXX",
		"name": "XXX",
		"address": "0xCB532A7f313CB6E0d92C81F52197CB4adc5424B4",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/XXX.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "ZRX",
		"name": "ZRX",
		"address": "0xe41d2489571d322189246dafa5ebde1f4699f498",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ZRX.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "XAEA51(ERC20)",
		"name": "XAEA51(ERC20)",
		"address": "0x603CCF87893a27e8672934BE877D2B1ea8E3ff5A",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/XAEA51(ERC20).png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "XMS(BSC)",
		"name": "XMS(BSC)",
		"address": "0x7859b01bbf675d67da8cd128a50d155cd881b576",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/XMS(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "PEOPLE",
		"name": "PEOPLE",
		"address": "0x7a58c0be72be218b41c608b7fe7c5bb630736c71",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/PEOPLE.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "ETE(ERC20)",
		"name": "ETE(ERC20)",
		"address": "0x000000e29fa2bd3E5C215fFc71aA66b29c9769A2",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ETE(ERC20).png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "USDC(ARB)",
		"name": "USDC(ARB)",
		"address": "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDC(ARB).png",
		"chain": "ARBITRUM",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "42161"
	},
	{
		"symbol": "GRIMACE(BSC)",
		"name": "GRIMACE(BSC)",
		"address": "0xC6759a4Fc56B3ce9734035a56B36e8637c45b77E",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/GRIMACE(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "JST",
		"name": "JST",
		"address": "TCFLL5dx5ZJdKnWuesXxi1VPwjLVmWZZy9",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/JST.png",
		"chain": "TRON",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "195"
	},
	{
		"symbol": "ERN",
		"name": "ERN",
		"address": "0xbbc2ae13b23d715c30720f079fcd9b4a74093505",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ERN.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "USDBC(BASE)",
		"name": "USDBC(BASE)",
		"address": "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDBC(BASE).png",
		"chain": "BASE",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "8453"
	},
	{
		"symbol": "BAL",
		"name": "BAL",
		"address": "0xba100000625a3754423978a60c9317c58a424e3d",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/BAL.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "QNT",
		"name": "QNT",
		"address": "0x4a220e6096b25eadb88358cb44068a3248254675",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/QNT.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "MBOX(BSC)",
		"name": "MBOX(BSC)",
		"address": "0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/MBOX(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "MIKOTO",
		"name": "MIKOTO",
		"address": "0x921fa0f0FE7e01e861b2cF583a54A8c3e9b40B1E",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/MIKOTO.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "GLM",
		"name": "GLM",
		"address": "0x7dd9c5cba05e151c895fde1cf355c9a1d5da6429",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/GLM.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "T",
		"name": "T",
		"address": "0xcdf7028ceab81fa0c6971208e83fa7872994bee5",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/T.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "PEPE",
		"name": "PEPE",
		"address": "0x6982508145454Ce325dDbE47a25d4ec3d2311933",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/PEPE.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "PRIMO(BSC)",
		"name": "PRIMO(BSC)",
		"address": "0xa7deaDE7Bbba2bf0AF1572A25B47E477749ef383",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/PRIMO(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "JYD",
		"name": "JYD",
		"address": "0x841407d25eead0835c3022a1350f8adedce56a85",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/JYD.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "ETH(SCROLL)",
		"name": "ETH(SCROLL)",
		"address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ETH(SCROLL).png",
		"chain": "SCROLL",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "534352"
	},
	{
		"symbol": "SUI",
		"name": "SUI",
		"address": "0x2::sui::SUI",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/SUI.png",
		"chain": "SUI",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "0"
	},
	{
		"symbol": "DOGE(BSC)",
		"name": "DOGE(BSC)",
		"address": "0xba2ae424d960c26247dd6c32edc70b295c744c43",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/DOGE(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "ID(BSC)",
		"name": "ID(BSC)",
		"address": "0x2dfF88A56767223A5529eA5960Da7A3F5f766406",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ID(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "PRIMO",
		"name": "PRIMO",
		"address": "TPbbHx2ztzYoJS12S4BkyhNjRBH9i7539r",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/PRIMO.png",
		"chain": "TRON",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "195"
	},
	{
		"symbol": "AAVE",
		"name": "AAVE",
		"address": "0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/AAVE.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "MEME(ERC20)",
		"name": "MEME(ERC20)",
		"address": "0xb131f4A55907B10d1F0A50d8ab8FA09EC342cd74",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/MEME(ERC20).png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "USDT(BSC)",
		"name": "USDT(BSC)",
		"address": "0x55d398326f99059ff775485246999027b3197955",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/USDT(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "FBNB(BSC)",
		"name": "FBNB(BSC)",
		"address": "0x4b7bf20baae7f04abea55b49c0ffbc31758a05a4",
		"decimals": 4,
		"logoURI": "https://file.ttpay.io/crypto/FBNB(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "USDT(TRON)",
		"name": "USDT(TRON)",
		"address": "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDT(TRON).png",
		"chain": "TRON",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "195"
	},
	{
		"symbol": "RLC",
		"name": "RLC",
		"address": "0x607F4C5BB672230e8672085532f7e901544a7375",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/RLC.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "KANA(BSC)",
		"name": "KANA(BSC)",
		"address": "0x0328a69b363a16f66810b23cb0b8d32abadb203d",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/KANA(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "GMT(BSC)",
		"name": "GMT(BSC)",
		"address": "0x3019BF2a2eF8040C242C9a4c5c4BD4C81678b2A1",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/GMT(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "KUMAMON(BSC)",
		"name": "KUMAMON(BSC)",
		"address": "0x8ab7Ef0eB25AAd36DfF0661f81Fb81B144AF5b87",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/KUMAMON(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "MDX(HECO)",
		"name": "MDX(HECO)",
		"address": "0x25d2e80cb6b86881fd7e07dd263fb79f4abe033c",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/MDX(HECO).png",
		"chain": "HECO",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "128"
	},
	{
		"symbol": "BSMV(ERC20)",
		"name": "BSMV(ERC20)",
		"address": "0x1B5aCA2F0aA7b6C23b64e80cE88A57b363EdE459",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/BSMV(ERC20).png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "DYP(ERC20)",
		"name": "DYP(ERC20)",
		"address": "0x961C8c0B1aaD0c0b10a51FeF6a867E3091BCef17",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/DYP(ERC20).png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "BAT",
		"name": "BAT",
		"address": "0x0d8775f648430679a709e98d2b0cb6250d2887ef",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/BAT.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "USDJ",
		"name": "USDJ",
		"address": "TMwFHYXLJaRUPeW6421aqXL4ZEzPRFGkGT",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/USDJ.png",
		"chain": "TRON",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "195"
	},
	{
		"symbol": "TRX",
		"name": "TRX",
		"address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/TRX.png",
		"chain": "TRON",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "195"
	},
	{
		"symbol": "ALICE",
		"name": "ALICE",
		"address": "0xac51066d7bec65dc4589368da368b212745d63e8",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/ALICE.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "DBC(ERC20)",
		"name": "DBC(ERC20)",
		"address": "0x1F735f84b07cc20E9aC471C291a87b5A2428d518",
		"decimals": 15,
		"logoURI": "https://file.ttpay.io/crypto/DBC(ERC20).png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "AVAX(C-Chain)",
		"name": "AVAX(C-Chain)",
		"address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/AVAX(C-Chain).png",
		"chain": "AVALANCHE",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "43114"
	},
	{
		"symbol": "ARPA",
		"name": "ARPA",
		"address": "0xBA50933C268F567BDC86E1aC131BE072C6B0b71a",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ARPA.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "TURBO",
		"name": "TURBO",
		"address": "0xA35923162C49cF95e6BF26623385eb431ad920D3",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/TURBO.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "CHG(BSC)",
		"name": "CHG(BSC)",
		"address": "0x7fb56b618463e404a9ca203b135bad468289ea2b",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/CHG(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "ORN",
		"name": "ORN",
		"address": "0x0258F474786DdFd37ABCE6df6BBb1Dd5dfC4434a",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/ORN.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "SQUIDGROW(BSC)",
		"name": "SQUIDGROW(BSC)",
		"address": "0x88479186BAC914E4313389a64881F5ed0153C765",
		"decimals": 19,
		"logoURI": "https://file.ttpay.io/crypto/SQUIDGROW(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "OBS(BSC)",
		"name": "OBS(BSC)",
		"address": "0xc6f509274fcc1f485644167cb911fd0c61545e6c",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/OBS(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "LCR(BSC)",
		"name": "LCR(BSC)",
		"address": "0x988F7c894e4001EEB7B570CDE80dffE21CF7B6B9",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/LCR(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "STPT",
		"name": "STPT",
		"address": "0xde7d85157d9714eadf595045cc12ca4a5f3e2adb",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/STPT.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "DIS(BSC)",
		"name": "DIS(BSC)",
		"address": "0xe2EcC66E14eFa96E9c55945f79564f468882D24C",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/DIS(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "SUSHI",
		"name": "SUSHI",
		"address": "0x6b3595068778dd592e39a122f4f5a5cf09c90fe2",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/SUSHI.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "METH",
		"name": "METH",
		"address": "0x0345173a92742e9dAF55d44Ac65e0D987b22379e",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/METH.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "IQ",
		"name": "IQ",
		"address": "0x579cea1889991f68acc35ff5c3dd0621ff29b0c9",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/IQ.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "WBTC(ERC20)",
		"name": "WBTC(ERC20)",
		"address": "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/WBTC(ERC20).png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "POWR",
		"name": "POWR",
		"address": "0x595832F8FC6BF59c85C527fEC3740A1b7a361269",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/POWR.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "USDT(ARB)",
		"name": "USDT(ARB)",
		"address": "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDT(ARB).png",
		"chain": "ARBITRUM",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "42161"
	},
	{
		"symbol": "BLZ(BSC)",
		"name": "BLZ(BSC)",
		"address": "0x935a544bf5816e3a7c13db2efe3009ffda0acda2",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/BLZ(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "PRESIDENTDOGE(BSC)",
		"name": "PRESIDENTDOGE(BSC)",
		"address": "0x4ae80e0414a188f91debfb9e71c67153bfbe5f9e",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/PRESIDENTDOGE(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "TUSD",
		"name": "TUSD",
		"address": "0x0000000000085d4780B73119b644AE5ecd22b376",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/TUSD.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "SPACEPI(BSC)",
		"name": "SPACEPI(BSC)",
		"address": "0x69b14e8D3CEBfDD8196Bfe530954A0C226E5008E",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/SPACEPI(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "SAND",
		"name": "SAND",
		"address": "0x3845badade8e6dff049820680d1f14bd3903a5d0",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/SAND.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "FREN(BSC)",
		"name": "FREN(BSC)",
		"address": "0xE6A768464B042a6d029394dB1fdeF360Cb60bbEb",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/FREN(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "PEPEM",
		"name": "PEPEM",
		"address": "0x499cC872dAeCa4c62CBfe45f72b49473D5Ccb6Af",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/PEPEM.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "MDS",
		"name": "MDS",
		"address": "0x66186008C1050627F979d464eABb258860563dbE",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/MDS.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "SWFTC(HECO)",
		"name": "SWFTC(HECO)",
		"address": "0x329dda64Cbc4DFD5FA5072b447B3941CE054ebb3",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/SWFTC(HECO).png",
		"chain": "HECO",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "128"
	},
	{
		"symbol": "MX",
		"name": "MX",
		"address": "0x11eeF04c884E24d9B7B4760e7476D06ddF797f36",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/MX.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "PENDLE(ARB)",
		"name": "PENDLE(ARB)",
		"address": "0x0c880f6761F1af8d9Aa9C466984b80DAb9a8c9e8",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/PENDLE(ARB).png",
		"chain": "ARBITRUM",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "42161"
	},
	{
		"symbol": "BNB(opBNB)",
		"name": "BNB(opBNB)",
		"address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/BNB(opBNB).png",
		"chain": "opBNB",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "204"
	},
	{
		"symbol": "UNI(BSC)",
		"name": "UNI(BSC)",
		"address": "0xbf5140a22578168fd562dccf235e5d43a02ce9b1",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/UNI(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "BEL(BSC)",
		"name": "BEL(BSC)",
		"address": "0x8443f091997f06a61670b735ed92734f5628692f",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/BEL(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "LCT(BSC)",
		"name": "LCT(BSC)",
		"address": "0x2b8f0750c7849f7311967f4faa4d48f694195e58",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/LCT(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "QUACK(BSC)",
		"name": "QUACK(BSC)",
		"address": "0xD74b782E05AA25c50e7330Af541d46E18f36661C",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/QUACK(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "AXS",
		"name": "AXS",
		"address": "0xbb0e17ef65f82ab018d8edd776e8dd940327b28b",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/AXS.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "MTRG",
		"name": "MTRG",
		"address": "0xaa8d0e9A26853D51613ca75729CDE2564913BCfb",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/MTRG.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "USDC(HECO)",
		"name": "USDC(HECO)",
		"address": "0x9362bbef4b8313a8aa9f0c9808b80577aa26b73b",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDC(HECO).png",
		"chain": "HECO",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "128"
	},
	{
		"symbol": "ETH(zkEVM)",
		"name": "ETH(zkEVM)",
		"address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ETH(zkEVM).png",
		"chain": "zkEVM",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1101"
	},
	{
		"symbol": "NKN(ERC20)",
		"name": "NKN(ERC20)",
		"address": "0x5cf04716ba20127f1e2297addcf4b5035000c9eb",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/NKN(ERC20).png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "USDC(MATIC)",
		"name": "USDC(MATIC)",
		"address": "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDC(MATIC).png",
		"chain": "POLYGON",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "137"
	},
	{
		"symbol": "MUSE",
		"name": "MUSE",
		"address": "0xb6ca7399b4f9ca56fc27cbff44f4d2e4eef1fc81",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/MUSE.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "USDC.E(Optimism)",
		"name": "USDC.E(Optimism)",
		"address": "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDC.E(Optimism).png",
		"chain": "OPTIMISM",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "10"
	},
	{
		"symbol": "NABOX(BSC)",
		"name": "NABOX(BSC)",
		"address": "0x755f34709e369d37c6fa52808ae84a32007d1155",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/NABOX(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "CAKE(BSC)",
		"name": "CAKE(BSC)",
		"address": "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/CAKE(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "FUN",
		"name": "FUN",
		"address": "0x419d0d8bdd9af5e606ae2232ed285aff190e711b",
		"decimals": 8,
		"logoURI": "https://file.ttpay.io/crypto/FUN.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "UNI",
		"name": "UNI",
		"address": "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/UNI.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "BAND",
		"name": "BAND",
		"address": "0xba11d00c5f74255f56a5e366f4f77f5a186d7f55",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/BAND.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "USDT(Optimism)",
		"name": "USDT(Optimism)",
		"address": "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDT(Optimism).png",
		"chain": "OPTIMISM",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "10"
	},
	{
		"symbol": "ETH(ZKSYNC)",
		"name": "ETH(ZKSYNC)",
		"address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/ETH(ZKSYNC).png",
		"chain": "ZKSYNC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "324"
	},
	{
		"symbol": "BNB(BSC)",
		"name": "BNB(BSC)",
		"address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/BNB(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "LAEEB(BSC)",
		"name": "LAEEB(BSC)",
		"address": "0x9CD7bc7D83F31224d8CD9d8dbc9Bd453Cd64A81e",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/LAEEB(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "PEPEBNB(BSC)",
		"name": "PEPEBNB(BSC)",
		"address": "0x47Fd014706081068448b89Fc6bAca2730977216a",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/PEPEBNB(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "USDT(ERC20)",
		"name": "USDT(ERC20)",
		"address": "0xdac17f958d2ee523a2206206994597c13d831ec7",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDT(ERC20).png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "USDT(MATIC)",
		"name": "USDT(MATIC)",
		"address": "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDT(MATIC).png",
		"chain": "POLYGON",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "137"
	},
	{
		"symbol": "VPND(C-Chain)",
		"name": "VPND(C-Chain)",
		"address": "0x83a283641C6B4DF383BCDDf807193284C84c5342",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/VPND(C-Chain).png",
		"chain": "AVALANCHE",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "43114"
	},
	{
		"symbol": "CHESS(BSC)",
		"name": "CHESS(BSC)",
		"address": "0x20de22029ab63cf9A7Cf5fEB2b737Ca1eE4c82A6",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/CHESS(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "VERSE",
		"name": "VERSE",
		"address": "0x249cA82617eC3DfB2589c4c17ab7EC9765350a18",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/VERSE.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "USDC(Optimism)",
		"name": "USDC(Optimism)",
		"address": "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85",
		"decimals": 6,
		"logoURI": "https://file.ttpay.io/crypto/USDC(Optimism).png",
		"chain": "OPTIMISM",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "10"
	},
	{
		"symbol": "SWFTC(BSC)",
		"name": "SWFTC(BSC)",
		"address": "0xe64e30276c2f826febd3784958d6da7b55dfbad3",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/SWFTC(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "GAL(BSC)",
		"name": "GAL(BSC)",
		"address": "0xe4Cc45Bb5DBDA06dB6183E8bf016569f40497Aa5",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/GAL(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "BNX(BSC)",
		"name": "BNX(BSC)",
		"address": "0x5b1f874d0b0C5ee17a495CbB70AB8bf64107A3BD",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/BNX(BSC).png",
		"chain": "BSC",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "56"
	},
	{
		"symbol": "AWRT",
		"name": "AWRT",
		"address": "0x27ad8e47c48063b209fa5ca14c8c46ece49b82d2",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/AWRT.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "TONE",
		"name": "TONE",
		"address": "0xe5f166c0d8872b68790061317bb6cca04582c912",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/TONE.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "FLOKI",
		"name": "FLOKI",
		"address": "0xcf0C122c6b73ff809C693DB761e7BaeBe62b6a2E",
		"decimals": 9,
		"logoURI": "https://file.ttpay.io/crypto/FLOKI.png",
		"chain": "ETH",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "1"
	},
	{
		"symbol": "USDT(OKExChain)",
		"name": "USDT(OKExChain)",
		"address": "0x382bb369d343125bfb2117af9c149795c6c65c50",
		"decimals": 18,
		"logoURI": "https://file.ttpay.io/crypto/USDT(OKExChain).png",
		"chain": "OKEXCHAIN",
		"isCrossEnable": 0,
		"withdrawGas": 1000,
		"chainId": "66"
	}
]


document.addEventListener('click',()=>{
	Bitget.address = (window as any)?.bitkeep?.ethereum?.selectedAddress ?? ''
	Bitget.isPlugin = !!(window as any)?.bitkeep?.ethereum
	Bitget.isConnect = !!(window as any)?.bitkeep?.ethereum?.selectedAddress
	
	TronLink.address= (window as any)?.tronWeb?.defaultAddress?.base58 ?? ''
	TronLink.isPlugin= !!(window as any)?.tronWeb,
	TronLink.isConnect= !!(window as any)?.tronWeb?.defaultAddress?.base58
	
	WalletConnect.address = ""
	WalletConnect.isPlugin = true
	WalletConnect.isConnect = false
	
	ImToken.address= "",
	ImToken.isPlugin= !!(window as any).imToken,
	ImToken.isConnect= false
})