<script setup lang="ts">
import progress_bg from '@/assets/imgs/swap/progress_bg.png'
import progress_accomplish from '@/assets/imgs/swap/progress_accomplish.png'
import progress_load from '@/assets/imgs/swap/progress_load.png'
import progress_timeout from '@/assets/imgs/swap/timeout.png'
interface Prors {
  who?: string
  status:0|1|2|3|any
}
const $prors = withDefaults(defineProps<Prors>(), {
  who: "",
  status:1
})
const arr = [0, 33.33, 66.66, 100]

const text = reactive<any>({
  0:['待用户存币', '兑换',          '发币',               '完成'],
  1:['充币',      '正在兑换中...',  '发币',               '完成'],
  2:['充币',      '兑换',          '兑换成功，发币中...', '完成'],
  3:['充币',      '兑换',          '发币',               '已完成'],
  4:['超时',      '兑换',          '发币',               '完成'],
  5:['失败',      '兑换',          '发币',               '完成'],
})
const style = computed(() => {
  return {
    width: `${arr[$prors.status]}%`
  }
})
</script>

<template>
  <div class="relative mb-40px">
    <div class="progress">
      <template v-if="status>=0 && status<4">
        <div class="progress-bar progress-bar-striped progress-bar-animated" :style="style"></div>
      </template>
    </div>

    <!-- 圆底图 -->
    <img class="absolute top-50% translate-y--50% translate-x--0% left-0%" :src="progress_bg">
    <img class="absolute top-50% translate-y--50% translate-x--50% left-33.33%" :src="progress_bg">
    <img class="absolute top-50% translate-y--50% translate-x--50% left-66.66%" :src="progress_bg">
    <img class="absolute top-50% translate-y--50% translate-x--100% left-100%" :src="progress_bg">

    <!-- √图片 -->
    <template v-if="status>=0 && status<4">
      <img class="absolute top-50% translate-y--50% translate-x--0% left-0%" :src="progress_accomplish" v-if="status>=0">
      <img class="absolute top-50% translate-y--50% translate-x--50% left-33.33%" :src="progress_accomplish" v-if="status>=1">
      <img class="absolute top-50% translate-y--50% translate-x--50% left-66.66%" :src="progress_accomplish" v-if="status>=2">
      <img class="absolute top-50% translate-y--50% translate-x--100% left-100%" :src="progress_accomplish" v-if="status==3">
    </template>

    <!-- 转圈图 -->
    <template v-if="status>=0 && status<4">
      <img class="absolute top-50% translate-y--50% translate-x--0% left-16px rotating" :src="progress_load" v-if="status===0">
      <img class="absolute top-50% translate-y--50% translate-x--50% left-33.33% rotating" :src="progress_load" v-if="status===1">
      <img class="absolute top-50% translate-y--50% translate-x--50% left-66.66% rotating" :src="progress_load" v-if="status===2">
    </template>

    <!-- 超时 -->
    <img class="absolute top-50% translate-y--50% translate-x--0% left-0px" :src="progress_timeout" v-if="status===4 || status===5">

    <!-- 文案 -->
    <span class="text-[14px] lt-mobilel:text-[12px] leading-15px w-20% absolute top-200% transform-y-100% translate-x--0% left-0% text-left">{{ $t(text[status][0]) }}</span>
    <span class="text-[14px] lt-mobilel:text-[12px] leading-15px w-20% absolute top-200% transform-y-100% translate-x--50% left-33.33% text-center">{{ $t(text[status][1]) }}</span>
    <span class="text-[14px] lt-mobilel:text-[12px] leading-15px w-20% absolute top-200% transform-y-100% translate-x--50% left-66.66% text-center">{{ $t(text[status][2]) }}</span>
    <span class="text-[14px] lt-mobilel:text-[12px] leading-15px w-20% absolute top-200% transform-y-100% translate-x--100% left-100% text-right">{{ $t(text[status][3]) }}</span>
  </div>
</template>

 <style lang="scss" scoped>
img{
  width: 2rem;
  height: 2rem;
}

.progress {
    display: flex;
    overflow: hidden;
    height: 1rem; 
    border-radius: 50rem;
    background-color: #2E3F73;
    font-size: .75rem;
}

.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
}

.progress-bar-striped {
    background-image: linear-gradient(135deg,rgb(255 255 255 / 15%) 25%,transparent 25%,transparent 50%,rgb(255 255 255 / 15%) 50%,rgb(90 196 169 / 15%) 75%,transparent 75%,transparent);
    background-size: 1rem 1rem;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #007bff;
    color: #fff;
    text-align: center;
    transition: width .6s ease;
    white-space: nowrap;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 0 0 
  }

  to {
    background-position: 1rem 0
  }
}


.rotating {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: translate(-50%,-50%) rotate(0deg);
  }

  to {
    transform: translate(-50%,-50%) rotate(360deg);
  }
}
</style> 


